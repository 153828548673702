/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-loop-func */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars*/
/* eslint-disable array-callback-return */

import React, { useEffect, useState } from "react";
import { Row, Col, Popover, message, Radio } from "antd";
import { useDispatch, useSelector } from "react-redux";
import SpinnerLoader from "../../../stateless/common/spinnerLoader";
import { useHistory, useLocation } from "react-router-dom";
import {
  ascIcon,
  asteriskIcon,
  descIcon,
  list,
  viewerIcon,
} from "../../../../images";
import {
  downIcon,
  editIcon,
  ebookLabel,
  audiobookLabel,
  videobookLabel,
  ExportIcon,
} from "../../../../images";
import moment from "moment-timezone";
import "./cartDetails.less";
import {
  addItemtoCart,
  fetchCartById,
  fetchSelectedViwerCart,
  saveSelectedCartIDs,
  saveSelectedCatlogIDs,
  updatedNewCart,
  savedColumns,
  resizeColumns,
  selectedCols,
  checkSelectedCartItems,
  setRefreshStatus,
  fetchBuyerCartFirst,
  setBuyerDetails,
  cartDetailsFilterRemember,
  fetchBuyerCartById,
  pageRememberCart,
  cartListFilter,
} from "../../../../../redux/actions/carts/cartAction";
import { userStateSelector } from "../../../../../redux/reducers/user/userReducer";
import {
  CartStateSelector,
  clearAdded,
  clearBuyerCart,
  clearBuyerCartF,
  clearCancel,
  clearCartDetailFilter,
  clearCheck,
  clearDeleted,
  clearOldBuyerCartItems,
  clearRefreshData,
  clearSelectedCart,
  clearSelectedCartIds,
  clearViewData,
  setMainScrollHeight,
  setTab,
} from "../../../../../redux/reducers/carts/cartsReducer";
import Button from "../../../stateless/common/button";
import { getAPIBaseUrl } from "../../../../../environment/api";
import { PortalModule } from "../../../../../environment/apiEnvironment";
import { Book } from "../../../../models/book/book";
import {
  BookStateSelector,
  clearCartsByBookId,
} from "../../../../../redux/reducers/books/bookListReducer";
import { PurchaseConfirmModal } from "./purchase/purchaseModal";
import { PurchaseSuccessModal } from "./purchase/purchaseSuccessModal";
import { setSideBarItem } from "../../../../../redux/actions/app/appActions";
import RemoveConfirmModel from "./remove/removeModal";
import { AppRoutes } from "../../../../router/appRoutes";
import { Messages } from "../../../../constants/messages";
import { fetchCartsByBookId, pageRememberBooklist } from "../../../../../redux/actions/books/bookActions";
import { ExpirationstateSelector } from "../../../../../redux/reducers/expiration/expirationReducer";
import { validateLendingModelType } from "../../curatedList/validateLendingData";
import MoveToCartModel from "./moveToCart/moveToCartModel";
import Cart from "../../../../models/cart/cart";
import { NewCartModal } from "../../carts/newCartModal";
import SelectTitleModel from "./moveToCart/SelectTitleModel";
import { UseGAEventTracker } from "../../../../../utility/useGAEventTracker";
import { ListApp } from "./cartListPage";
import { Page } from "../../../../constants/constants";
import PaginationTag from "../../../stateless/common/pagination";
import FloatButton from "../../../stateless/common/button/floatButton";
import alert from "antd/lib/alert";
import { SideBarStateSelector, retainPageSize } from "../../../../../redux/reducers/app/sideBarReducer";
import ComponentLoader from "../../../stateless/common/spinnerLoader/componentLoader";

export const CartDetails = (props: any) => {
  const location = useLocation<any>();
  const history = useHistory();
  const dispatch = useDispatch();
  const { cart } = location.state;
  const { appUser } = useSelector(userStateSelector);
  const { retainedPageSize } = useSelector(SideBarStateSelector);
  const [cartDescription, setCartDescription] = useState(cart);
  var currency =
    appUser && appUser.libraryData
      ? appUser.libraryData[0]?.CurrencySymbol
      : "";

  const {
    selectedCart,
    formState,
    imageUrl,
    selectedTab,
    addedItem,
    fetchCartByIdFormState,
    selectedCartIds,
    cartDetalis,
    savedCols,
    resizeCols,
    buyerCart,
    buyerCartF,
    selectedColums,
    moveTitleFormState,
    cartsList,
    cancelItemtoCartFormstate,
    checkItemtoCartFormstate,
    refreshData,
    addItemtoCartFormstate,
    setCartDetailsFilter,
    buyerCartByIdformState
  } = useSelector(CartStateSelector);
  const { expiration } = useSelector(ExpirationstateSelector);
  const [startIndex, setStartIndex] = useState(1);
  const [globalQtyCart, setGlobalQtyCart] = useState<number>(1);
  const [owned, setOwned] = useState(false);
  const [totalQuantiy, setTotalQuantiy] = useState<any>([]);
  const [selectedItemsIds, setSelectedItemsIds] = useState<any>([]);
  const [PaginationOn, setPaginationOn] = useState<boolean>(false);
  const [loadScreen, setLoadScreen] = useState<boolean>(false);
  const [editCellRows, setEditCellRows] = useState<any>([]);
  const [sortOrder, setSortOrder] = useState(
    setCartDetailsFilter.sortOrder !== undefined
      ? setCartDetailsFilter.sortOrder
      : "Default"
  );
  const [tableData, setTableData] = useState<any>([]);
  const [buyAllCart, setBuyAllCart] = useState<boolean>(false);
  const [isBuyerCartStatus, setIsBuyerCartStatus] = useState<boolean>(false);
  const [exportData, setExportData] = useState<any>([]);
  const [tableDataChosen, setTableDataChosen] = useState<any>();
  const [globlalBtnPressed, setGloblalBtnPressed] = useState<boolean>(false);
  const [resetData, setResetData] = useState<boolean>(false);
  const [scrollValue,setScrollValue] = useState(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ready, setReady] = useState(false);
  const [showPurchase, setShowPurchase] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showLoading, setShowLoading] = useState<boolean>(true);
  const [gridView, setGridView] = useState<number>(
    setCartDetailsFilter?.gridView !== undefined
      ? setCartDetailsFilter?.gridView
      : 1
  );
  const [purchased, setPurchased] = useState(false);
  const [maxQuantity, setMaxQuantity] = useState<number>(1);
  const [selectedKeys, setSelectedKeys] = useState<any>();
  const [copies, setCopies] = useState<any>({});
  const [sort, setSort] = useState(
    setCartDetailsFilter.sort !== undefined ? setCartDetailsFilter.sort : "asc"
  );
  const [sortIcon, setSortIcon] = useState(
    setCartDetailsFilter.sortIcon !== undefined
      ? setCartDetailsFilter.sortIcon
      : ascIcon
  );
  const [cartUpdated, setCartUpdated] = useState<boolean>(false);
  const [clickUpdate, setClickUpdate] = useState<Number>(
    selectedCart?.TotalCartAmount
  );
  const [updateCartAmount, setUpdateCartAmount] = useState<number>(
    selectedCart?.TotalCartAmount
  );
  const [updateCartPreSale, setUpdateCartPreSale] = useState<number>(
    selectedCart?.TotalCartPrepubAmount
  );
  const [showRemove, setShowRemove] = useState(false);
  const [isEditClick, setIsEditClick] = useState<boolean>(false);
  const [itemsToDelete, setItemsToDelete] = useState([]);
  const [removeConfirm, setRemoveConfirm] = useState(false);
  const [updateConfirm, setUpdateConfirm] = useState(false);
  const [totalAmount, setTotalAmount] = useState<number>();
  const [notSetMin, setNotSetMin] = useState<boolean>(false);
  const [notSetMax, setNotSetMax] = useState<boolean>(false);
  const [maxQtyA, setMaxQtyA] = useState<number>(1);
  const [itemsToPurchase, setItemsToPurchase] = useState([]);
  const [selectedItems, setSelectedItems] = useState<any>([]);
  const [loading, setLoading] = useState<any>();
  const [isBuyModalOpen, setIsBuyModalOpen] = useState<boolean>(false);
  const [initialRestrictions, setInitialRestrictions] = useState<string>("");
  const [switchRestrictions, setSwitchRestrictions] = useState<string>("");
  const [totalPreSAle, setTotalPreSAle] = useState<number>();
  const [totalQuantity, setTotalQuantity] = useState<any>(
    selectedCart?.NumberofCopies
  );
  const optionList = [10, 20, 50, 100, 250, 500];
  const [prePubQunat, setPrePubQunatity] = useState<any>();
  const [userColumns, setUserColumns] = useState<any>(savedCols);
  const [totalTitles, setTotalTiltles] = useState<any>("");
  const [TotalCopies, setTotalCopies] = useState<any>("");
  const [totalTitlesPreSale, setTotalTitlesPreSale] = useState<any>("");
  const [totalFilteritemAmount, setTotalFilteritemAmount] = useState<any>("");
  const [totalFilterPreitemAmount, setTotalFilterPreitemAmount] = useState<any>("");
  const [totalNumbCopiesPreSale, setTotalNumbCopiesPreSale] = useState<any>("");
  const [buyerItemsIdArray, setBuyerItemsIdArray] = useState<any>([]);
  const [columnsResize, setColumnsResize] = useState<any>([]);
  const [formatValue, setFormatValue] = useState(
    setCartDetailsFilter.formatValue !== undefined
      ? setCartDetailsFilter.formatValue
      : "all"
  );
  const [moveCartModel, setMoveCartModel] = useState<boolean>(false);
  const [keepActive, setKeepActive] = useState<boolean>(true);
  const [destinationCart, setDestinationCart] = useState<any>();
  const [showModal, setShowModal] = useState(false);
  const [titlesModel, setTitlesModel] = useState(false);
  const [sortFirst, setSortFirst] = useState(true);
  const [sortQueue, setSortQueue] = useState(true);
  const [showQuantitybtn, setShowQuantitybtn] = useState<boolean>(false);
  const {cartCurrentPage}=useSelector(CartStateSelector)
  const [startCount, setStartCount] = useState<number>(Page.DEFAULT_START);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState<number>(retainedPageSize ?? 100);
  const [lastCount, setLastCount] = useState(startCount + pageSize - 1);
  const [moveTitleIsContinue, setMoveTitleIsContinue] =
    useState<boolean>(false);

  var lib_Id =
    appUser && appUser.libraryData ? appUser.libraryData[0].LibraryId : 0;
  var user_id = appUser?.email;
  var dateFormat = appUser?.libraryData
    ? appUser.libraryData[0]?.DateFormat.toUpperCase()
    : "";
  const goBack = () => {
    if (selectedTab !== "2" && selectedTab !== "1") dispatch(setTab("1"));
    history.push(AppRoutes.CART);
  };
  const { carts } = useSelector(BookStateSelector);

  const getTableData = () => {
    const value = formatValue
    if (selectedCart?.CartDetails) {
      if(value === "all"){
        let tempData = selectedCart?.CartDetails.map((item: any) => {
          return {
            ...item,
            key: item.CatalogItemId,
            totalPrice: item.Quantity * item.RetailPrice,
          };
        });
        setTableData(tempData);
      }else{
        let tempData = selectedCart?.CartDetails.filter((data: Book) => {
          return data.FileFormatName === value;
        }).map((item: any) => {
          return {
            ...item,
            key: item.CatalogItemId,
            totalPrice: item.Quantity * item.RetailPrice,
          };
        });
        setTableData(tempData);
      }
    }
  };

  useEffect(() => {
    return () => {
      dispatch(clearAdded());
      setGloblalBtnPressed(false)
    };
  }, []);

  useEffect(() => {
    if (fetchCartByIdFormState?.loading == false) {
      setTimeout(()=>{
        setLoading(false);
      },4000)
    } else {
      setLoading(true);
    }
  }, [formState?.loading, fetchCartByIdFormState?.loading]);

  //for retaining pagination

  useEffect(() => {
 
    dispatch(pageRememberCart({currentPage:currentPage,start:startCount}));
  }, [startCount, currentPage]);

  useEffect(() => {
    // setStrinDetails({ ...location?.state?.gaString?.gaData });
    if (cartCurrentPage?.currentPage) {
      setCurrentPage(cartCurrentPage?.currentPage);
      setStartCount(cartCurrentPage?.start);
    }
  }, [location])

  useEffect(() => {
    dispatch(setSideBarItem("Carts"));
    setGloblalBtnPressed(false)
    return () => {
      setItemsToDelete([]);
      setItemsToPurchase([]);
      dispatch(clearSelectedCart());
      dispatch(clearCartsByBookId());
      dispatch(clearSelectedCartIds());
      dispatch(clearBuyerCart());
      dispatch(clearRefreshData());
      dispatch(clearOldBuyerCartItems());
      dispatch(clearDeleted());
      dispatch(clearViewData());
    };
  }, []);

  useEffect(() => {
    setUpdateCartAmount(selectedCart?.TotalCartAmount);
    setUpdateCartPreSale(selectedCart?.TotalCartPrepubAmount);
    setCartDescription(cart)
  }, [selectedCart?.TotalCartAmount,cart]);

  const isUserCart = () => {
    if (
      // selectedCart?.CartOwner === appUser?.email ||
      cartDescription?.IsCommunityCart
    ) {
      return true;
    } else {
      return false;
    }
  };
  const handleOwnedFilter = (value: any) => {
    setStartIndex(1);
    setOwned(value);
  };
  const handleSortFilter = (value: any) => {
    setStartIndex(1);
    setSortOrder(value);
  };
  const onSortClick = () => {
    setStartIndex(1);
    if (sort === "asc") {
      setSort("desc");
      setSortIcon(descIcon);
    } else {
      setSort("asc");
      setSortIcon(ascIcon);
    }
  };

  useEffect(() => {
    selectedCart && getTableData();
    setTotalQuantity(selectedCart?.NumberofCopies);
    setPrePubQunatity(selectedCart?.NumberOfPrepubCopies);
  }, [selectedCart]);

  const handleOk = () => {
    setIsBuyModalOpen(false);
    dispatch(clearOldBuyerCartItems());
  };
  const handleCancel = () => {
    setIsBuyModalOpen(false);
  };

  useEffect(() => {
    var payload = {
      userId: lib_Id,
      recordStart: 1,
      recordCount: 3000,
    };
    if (appUser?.isLibraryBuyer) {
      dispatch(fetchBuyerCartFirst(payload));
    }
  }, [lib_Id]);

  useEffect(() => {
    if (
      buyerCartF?.CartDetail?.length !== 0 &&
      buyerCartF?.CartDetail !== undefined
    ) {
      dispatch(setBuyerDetails(buyerCartF));
      var remobeBuyerCartPayload = {
        IsCommunityCart: selectedCart?.IsCommunityCart,
        LibraryId: lib_Id,
        SaveAction: "S",
        CartDescription: "",
        CartId: buyerCartF?.CartId,
        CartName: buyerCartF?.CartName,
        AssociateShelfId: buyerCartF?.AssociateShelfId,
        CartDetail: [],
        CartDetailBuyerSave:
          buyerCartF?.CartDetail?.length === 0
            ? buyerCartF?.CartDetail?.map((item: any) => {
                return {
                  ShopperCartDetailId: item?.CartDetailId,
                  BuyerCartDetailId: "",
                  Quantity: 0,
                  SaveAction: "D",
                };
              })
            : buyerCartF?.CartDetail?.map((item: any) => {
                return {
                  ShopperCartDetailId: item.CartDetailId,
                  BuyerCartDetailId: "",
                  Quantity: 0,
                  SaveAction: "D",
                };
              }),
      };
      var payload = {
        removeItem: remobeBuyerCartPayload,
        refrehCartData: {
          userId: user_id,
          cartId: cart.CartId,
          count: pageSize,
          start: startCount,
          sortBy: sortOrder,
          libraryOwned: owned,
          sortOrder: sort,
        },
        fetchCartList: {
          libId: lib_Id,
        },
      };
      dispatch(checkSelectedCartItems(payload));
      dispatch(clearBuyerCartF());
    } else {
      dispatch(setRefreshStatus(true));
    }
  }, [buyerCartF, startCount]);

  useEffect(() => {
    const cartDetailfiltersApply = {
      sortOrder,
      sortIcon,
      sort,
      formatValue,
      gridView,
      user_id
    };
    dispatch(cartDetailsFilterRemember(cartDetailfiltersApply));
  }, [sortOrder, formatValue, sort, sortIcon, gridView]);

  useEffect(() => {
    setIsBuyerCartStatus(refreshData);
  }, [refreshData]);

  useEffect(() => {
    if (isBuyerCartStatus) {
      if (sortOrder === "AutobuyReason" && sortFirst) {
        if (
          cart?.CartTypeCode?.toLowerCase() === "autobuyaudiobookcart" ||
          cart?.CartTypeCode?.toLowerCase() === "autobuycart"
        ) {
          setSortFirst(true);
          setSortQueue(true);
          dispatch(
            fetchCartById({
              userId: user_id,
              cartId: cart.CartId,
              count: pageSize,
              start: startCount,
              sortBy: sortOrder,
              libraryOwned: owned,
              sortOrder: sort,
            })
          );
        } else {
          setSortOrder("Sort By");
          setSortFirst(false);
          setSortQueue(false);
          dispatch(
            fetchCartById({
              userId: user_id,
              cartId: cart.CartId,
              count: pageSize,
              start: startCount,
              sortBy: "Sort By",
              libraryOwned: owned,
              sortOrder: sort,
            })
          );
        }
      } else {
        setSortFirst(true);
        setSortQueue(true);
        if (sortQueue) {
          dispatch(
            fetchCartById({
              userId: user_id,
              cartId: cart.CartId,
              count: pageSize,
              start: startCount,
              sortBy: sortOrder,
              libraryOwned: owned,
              sortOrder: sort,
            })
          );
        }
      }
      dispatch(setRefreshStatus(false));
    }
  }, [sortOrder, owned, sort, cart, isBuyerCartStatus]);

  useEffect(() => {
    if (checkItemtoCartFormstate?.isSuccess) {
      dispatch(setRefreshStatus(true));
      dispatch(clearCheck());
    }
  }, [checkItemtoCartFormstate]);

  const showNotification = (content: any, type: any) => {
    type === "loading"
      ? message.loading({
          content: content,
          key: "notificationMessage",
          duration: 4,
        })
      : message.success({
          content: content,
          key: "notificationMessage",
        });
  };

  useEffect(() => {
    if (moveTitleFormState?.loading) {
      showNotification(Messages.MOVE_ITEM, "loading");
    } else if (moveTitleFormState?.isSuccess) {
      showNotification(Messages.MOVE_SUCCESS, "success");
    }
  }, [moveTitleFormState]);

  useEffect(() => {
    if (addedItem) {
      if (removeConfirm) {
        showNotification(Messages.ITEM_REMOVED, "success");
        setTableData([]);
      }
      if (updateConfirm) {
        showNotification(Messages.ITEM_UPDATE, "success");
        setTableData([]);
        setShowPurchase(false);
        setTotalQuantiy([]);
        setEditCellRows([]);
        setUpdateConfirm(false);
        setShowQuantitybtn(false);
      }
      if (keepActive) {
        dispatch(
          fetchCartById({
            userId: user_id,
            cartId: cart.CartId,
            count: pageSize,
            start: startCount,
            sortBy: sortOrder,
            libraryOwned: owned,
            sortOrder: sort,
          })
        );
      }
      dispatch(clearAdded());
      setCartUpdated(true);
      itemsToDelete.length === 0 &&
        !removeConfirm &&
        !updateConfirm &&
        setShowPurchase(true);
      itemsToDelete.length > 0 && setItemsToDelete([]);
      removeConfirm && setRemoveConfirm(false);
    }
  }, [addedItem, keepActive, updateConfirm, totalQuantiy]);

  const onRemove = () => {
    setShowRemove(true);
  };

  useEffect(() => {
    if (addedItem) {
      if (itemsToPurchase) {
        setTableData([]);
      }
    }
  }, [addedItem]);

  const googleAnalyticPurchaseLabel =
    itemsToPurchase?.length === 0
      ? tableData.reduce((total: any, item: any) => {
          return (total = total + item.RetailPrice);
        }, 0)
      : itemsToPurchase.reduce((total: any, item: any) => {
          return (total = total + item.RetailPrice);
        }, 0);

  useEffect(() => {
    let foundItem = totalQuantiy?.find(
      (item: { key: any }) => item?.key === editCellRows?.key
    ); 
    if (foundItem) {
      var res = totalQuantiy?.map(
        (obj: { key: number }) =>
          [editCellRows].find((o: { key: number }) => o.key === obj.key) || obj
      );
      setTotalQuantiy([[],...res]);
    } else {
      setTotalQuantiy([...totalQuantiy, editCellRows]);
    }
    var copies = editCellRows;
    setCopies({ ...copies, [copies?.CatalogItemId]: copies?.Quantity });
  }, [editCellRows]);

  useEffect(() => {
    if(itemsToPurchase){
      const itemsWithQuantityMin = itemsToPurchase?.filter((item:any) => item?.Quantity > 1);
     if(itemsWithQuantityMin?.length > 0){
      setNotSetMin(false)
     }else{
      setNotSetMin(true)
     }

     const itemsWithQuantityMax = itemsToPurchase?.filter((item:any) => item?.Quantity < 999);
     if(itemsWithQuantityMax?.length > 0){
      setNotSetMax(false)
     }else{
      setNotSetMax(true)
     }

      const maxQuantity:any = Math?.max(...itemsToPurchase?.map((obj:any) => obj?.Quantity));
      if(maxQuantity){
        setMaxQtyA(maxQuantity > 0?maxQuantity:1)
      }else{
        setMaxQtyA(1)
      }
    }

    if (totalQuantiy?.length >1 && itemsToPurchase?.length < 1) {
      if (appUser?.isLibraryBuyer === true) {
        setShowQuantitybtn(true);
      } else {
        if (
          selectedCart?.CartOwner === appUser?.email ||
          selectedCart?.IsCommunityCart
        ) {
          setShowQuantitybtn(true);
        } else {
          setShowQuantitybtn(false);
        }
      }
    } else {
      setShowQuantitybtn(false);
    }
  }, [totalQuantiy, itemsToPurchase, selectedCart, appUser]);

  const onQuantyCancel = () => {
    setGloblalBtnPressed(false)
    setShowQuantitybtn(false);
    setTotalQuantiy([]);
    setEditCellRows([]);
    setResetData(true);
    setTimeout(() => {
      setResetData(false);
    }, 100);
  };

  useEffect(() => {
    if (addItemtoCartFormstate?.isSuccess) {
      setShowQuantitybtn(false);
      setTotalQuantiy([])
    }
  }, [addItemtoCartFormstate]);

  const onPurchase = async () => {
    setGloblalBtnPressed(false)
    if (
      formState?.loading == false &&
      fetchCartByIdFormState?.loading == false &&
      addItemtoCartFormstate?.loading == false &&
      buyerCartByIdformState?.loading == false
    ){
    if(itemsToDelete.length < 1){
         return message.error({
          content:'Please select items from cart',
          key: "selectCartNotificationMessage",
        });
    }
    UseGAEventTracker(
      "Cart details category",
      `${
        totalAmount !== googleAnalyticPurchaseLabel
          ? "Selected Purchase click"
          : "Select all Purchase click"
      }`,
      `${totalAmount !== googleAnalyticPurchaseLabel ? "Selected Purchase click": "Select all Purchase click"} total price (${currency}${googleAnalyticPurchaseLabel}) `,
      itemsToPurchase?.length === 0 ? tableData.length : itemsToPurchase.length
    );
    setShowPurchase(true);
    setTotalQuantiy([]);
    setEditCellRows([]);
    setUpdateConfirm(false);
    setShowQuantitybtn(false);
    var priceinputs: any = document.querySelectorAll(
      ".purchaseSelected .ant-input-number-input"
    );
    let checkedPayloads: any = [];
    for (var i: any = 0; i < priceinputs.length; i++) {
      checkedPayloads?.push({
        CartDetailId:  priceinputs[i].id?.split('_')[1],
        CatalogItemId: priceinputs[i].id?.split('_')[0],
        BuyerId: "",
        BuyerCartDetailId: "",
        Quantity: priceinputs[i].value?priceinputs[i].value:1,
        SaveAction: "S",
      });
    }
    if (cartUpdated && checkedPayloads) {
      var tempCart = {
        IsCommunityCart: selectedCart?.IsCommunityCart,
        LibraryId: lib_Id,
        SaveAction: "S",
        CartId: selectedCart?.CartId,
        CartName: selectedCart?.CartName,
        AssociateShelfId: selectedCart?.AssociateShelfId,
        CartDetail:
          itemsToPurchase.length === 0
            ? tableData.map((item: any) => {
                return {
                  CartDetailId: item.CartDetailId,
                  CatalogItemId: item.CatalogItemId,
                  BuyerId: "",
                  BuyerCartDetailId: "",
                  Quantity: copies[item.CatalogItemId]
                    ? copies[item.CatalogItemId]
                    : item.Quantity,
                  SaveAction: "S",
                };
              })
            :checkedPayloads
      };

      var tempForViewer = {
        IsCommunityCart: selectedCart?.IsCommunityCart,
        LibraryId: lib_Id,
        SaveAction: "S",
        CartDescription: "",
        CartId: buyerCart?.CartId,
        CartName: buyerCart?.CartName,
        AssociateShelfId: selectedCart?.AssociateShelfId,
        CartDetail:
          itemsToPurchase.length === 0
            ? tableData.map((item: any) => {
                return {
                  ShopperCartDetailId: item.CartDetailId,
                  BuyerCartDetailId: 0,
                  Quantity: copies[item.CatalogItemId]
                    ? copies[item.CatalogItemId]
                    : item.Quantity,
                  SaveAction: "S",
                };
              })
            : checkedPayloads?.map((item: any) => {
                return {
                  ShopperCartDetailId: item.CartDetailId,
                  BuyerCartDetailId: 0,
                  Quantity: copies[item.CatalogItemId]
                    ? copies[item.CatalogItemId]
                    : item.Quantity,
                  SaveAction: "S",
                };
              }),
        CartDetailBuyerSave:
          itemsToPurchase.length === 0
            ? tableData.map((item: any) => {
                return {
                  ShopperCartDetailId: item.CartDetailId,
                  BuyerCartDetailId: 0,
                  Quantity: copies[item.CatalogItemId]
                    ? copies[item.CatalogItemId]
                    : item.Quantity,
                  SaveAction: "S",
                };
              })
            : checkedPayloads?.map((item: any) => {
                return {
                  ShopperCartDetailId: item.CartDetailId,
                  BuyerCartDetailId: 0,
                  Quantity: copies[item.CatalogItemId]
                    ? copies[item.CatalogItemId]
                    : item.Quantity,
                  SaveAction: "S",
                };
              }),
      };
      var selecteditem = {
        IsCommunityCart: selectedCart?.IsCommunityCart,
        LibraryId: lib_Id,
        SaveAction: "S",
        CartDescription: "",
        CartId: selectedCart?.CartId,
        CartName: selectedCart?.CartName,
        AssociateShelfId: selectedCart?.AssociateShelfId,
        CartDetailBuyerSave:
          itemsToPurchase.length === 0
            ? tableData?.map((item: any) => {
                return {
                  ShopperCartDetailId: item.CartDetailId,
                  BuyerCartDetailId: "",
                  CatalogItemId: item.CatalogItemId,
                  Quantity: copies[item.CatalogItemId]
                    ? copies[item.CatalogItemId]
                    : item.Quantity,
                  SaveAction: "S",
                };
              })
            : checkedPayloads?.map((item: any) => {
                return {
                  ShopperCartDetailId: item.CartDetailId,
                  BuyerCartDetailId: "",
                  CatalogItemId: item.CatalogItemId,
                  Quantity: copies[item.CatalogItemId]
                    ? copies[item.CatalogItemId]
                    : item.Quantity,
                  SaveAction: "S",
                };
              }),
      };
      setSelectedItems(selecteditem);
      if (
        selectedCart?.CartOwner === appUser?.email ||
        selectedCart?.IsCommunityCart
      ) {
        dispatch(addItemtoCart(tempCart));
        setTotalQuantiy([])
      } else {
        var payload = {
          userId: lib_Id,
          recordStart: 1,
          recordCount: tableData?.length,
        };
        dispatch(
          fetchSelectedViwerCart({ payload: tempForViewer, fetchData: payload })
        );
      }
    }
  }
  };

  const onPurchaseCart =(showConfirmModel:boolean)=>{
    setGloblalBtnPressed(false)
    if(
      formState?.loading == false &&
      fetchCartByIdFormState?.loading == false &&
      addItemtoCartFormstate?.loading == false &&
      buyerCartByIdformState?.loading == false
    ){
      setBuyAllCart(showConfirmModel)
      setTimeout(()=>{
       setShowPurchase(true)
      },500)
      dispatch(fetchBuyerCartById({
       libraryId: lib_Id,
       cartId: selectedCart?.CartId,
       quantity:1
      }))
    }
  }
  
  useEffect(() => {
    let saveId: any = [];
    let catalogID: any = [];
    selectedItems?.CartDetailBuyerSave?.map((item: any) => {
      catalogID?.push(item?.CatalogItemId);
      return saveId?.push(item?.ShopperCartDetailId);
    });
    if (selectedItems) {
      dispatch(saveSelectedCartIDs(saveId));
      dispatch(saveSelectedCatlogIDs(catalogID));
    } else {
      dispatch(saveSelectedCatlogIDs(catalogID));
    }
  }, [selectedItems]);

  const handelInvoiceAfterFilter=(filteredData:any)=>{
    let totalTitles:any = [];
    let totalAmount = 0;
    let totalPresaleAmount = 0
    let totalNumberOfCopies = 0;
    let totalRetailPrice = 0;
    let totalCopiesPreSale = 0;
    let totalCopies = 0;
    let totalNumberOfCopiesPreSle = 0;
    let totalTitlesPreSale:any = [] 
    const BookState = "PRE_SALE";
    
    // Iterate through the array and calculate totals
   
    filteredData?.forEach((item:any) => {
      totalTitles?.push(item?.ItemState)
      totalCopies += item.Quantity || 0;
      totalAmount += item.RetailPrice * item.Quantity || 0;
      totalNumberOfCopies += item.NumberOfCopies || 0;
      totalRetailPrice += item.RetailPrice || 0;
      if(item.ItemState === BookState){
        totalTitlesPreSale?.push(item?.ItemState)
        totalCopiesPreSale += item.totalPrice || 0;
        totalPresaleAmount += item.RetailPrice * item.Quantity || 0;
        totalNumberOfCopiesPreSle += item.Quantity || 0;
      }
    });
    setTotalTiltles(totalTitles?.length)
    setTotalCopies(totalCopies)
    setTotalFilteritemAmount(totalAmount)
    setTotalFilterPreitemAmount(totalPresaleAmount)
    setTotalNumbCopiesPreSale(totalNumberOfCopiesPreSle)
    setTotalTitlesPreSale(totalTitlesPreSale?.length)
  }

  const handleFormatFilter = (value: any) => {
    setFormatValue(value);
    if (value === "all") {
      setTableData(
        selectedCart?.CartDetails
          ? selectedCart?.CartDetails.map((item: any) => {
              return {
                ...item,
                key: item.CatalogItemId,
                totalPrice: item.Quantity * item.RetailPrice,
              };
            })
          : []
      );
    } else {
      let filteredData = selectedCart?.CartDetails
        ? selectedCart?.CartDetails.filter((data: Book) => {
            return data.FileFormatName === value;
          }).map((item: any) => {
            return {
              ...item,
              key: item.CatalogItemId,
              totalPrice: item.Quantity * item.RetailPrice,
            };
          })
        : [];
      setTableData(filteredData);
    }
  };

  useEffect(()=>{
   if(formatValue !== 'all'){
    handelInvoiceAfterFilter(tableData)
   }
  },[formatValue,tableData])

  const getNumbrofCopies = () => {
    const numberofCopies: any = selectedCart?.NumberofCopies;
    const numberOfPrepubCopies: any = selectedCart?.NumberOfPrepubCopies;
    const Total = parseInt(numberofCopies) - parseInt(numberOfPrepubCopies);
    return Total;
  };

  const getNumberofTitles = () => {
    const numberOfTitles: any = formatValue === 'all'?selectedCart?.NumberOfTitles:totalTitles;
    const NumberOfPrepubTitles: any = formatValue === 'all'?selectedCart?.NumberOfPrepubTitles:totalTitlesPreSale;
    const Total = parseInt(numberOfTitles) - parseInt(NumberOfPrepubTitles);
    return Total;
  };

  const getTotalAmount = () => {
    const totalAmount: any = selectedCart?.TotalCartAmount;
    const totalPrepubAmount: any = selectedCart?.TotalCartPrepubAmount;
    const Total = parseFloat(totalAmount) - parseFloat(totalPrepubAmount);
    return Total.toFixed(2);
  };

  function simpleArraySum(ar: any) {
    var sum = 0;
    for (var i = 0; i < ar.length; i++) {
      sum += ar[i];
    }
    return sum;
  }
  const handleUpdatedAmount = (value: any) => {
    setClickUpdate(value);
  };

  const quantityCount = async () => {
    var qunatity = tableData?.map((item: any) => {
      return {
        Quantity: copies[item.CatalogItemId]
          ? copies[item.CatalogItemId]
          : item.Quantity,
      };
    });
    const Finalqunatity = qunatity
      ?.map((item: any) => item.Quantity)
      .reduce((prev: any, curr: any) => prev + curr, 0);
    setTotalQuantity(Finalqunatity);
  };

  const prePubQuantity = async () => {
    const prePubQuant = tableData?.map((item: any) => {
      return {
        ItemState: item.ItemState,
        Quantity: copies[item.CatalogItemId]
          ? copies[item.CatalogItemId]
          : item.Quantity,
      };
    });
    const prePubTotalQuantity = prePubQuant?.filter(function (item: any) {
      return item.ItemState === "PRE_SALE";
    });
    const FinalPrePubqunatity = prePubTotalQuantity
      ?.map((item: any) => item.Quantity)
      .reduce((prev: any, curr: any) => prev + curr, 0);
    setPrePubQunatity(FinalPrePubqunatity);
  };

  useEffect(() => {
    let a = [];
    let saleA = [];
    var spans = document?.getElementsByClassName("amountPrice");
    var presaleSpan = document?.getElementsByClassName("preSaleRow");
    for (var i = 0; i < spans?.length; i++) {
      //@ts-ignore
      a?.push(parseFloat(spans[i]?.innerText));
    }
    for (var j = 0; j < presaleSpan?.length; j++) {
      //@ts-ignore
      saleA?.push(parseFloat(presaleSpan[j]?.innerText));
    }
    if (a.length !== 0) {
      setUpdateCartAmount(simpleArraySum(a));
    } else {
      setUpdateCartAmount(selectedCart?.TotalCartAmount);
    }
    //PreSale CAlculations
    if (saleA.length !== 0) {
      setUpdateCartPreSale(simpleArraySum(saleA));
    } else {
      setUpdateCartPreSale(selectedCart?.TotalCartPrepubAmount);
    }
    quantityCount();
    prePubQuantity();
  }, [clickUpdate]);

  useEffect(() => {
    if (
      // eslint-disable-next-line use-isnan
      //@ts-ignore
      isNaN(updateCartAmount) ||
      updateCartAmount == selectedCart?.TotalCartAmount ||
      updateCartAmount == undefined
    ) {
      setTotalAmount(selectedCart?.TotalCartAmount);
    } else {
      setTotalAmount(updateCartAmount);
    }
    if (
      // eslint-disable-next-line use-isnan
      //@ts-ignore
      isNaN(updateCartPreSale)  ||
      updateCartPreSale == selectedCart?.TotalCartPrepubAmount ||
      updateCartPreSale == undefined
      
    ) {
      setTotalPreSAle(selectedCart?.TotalCartPrepubAmount);
    } else {
      setTotalPreSAle(updateCartPreSale);
    }
  }, [
    selectedCart?.TotalCartAmount,
    selectedCart?.TotalCartPrepubAmount,
    updateCartAmount,
    updateCartPreSale,
  ]);

  const roundofNumber = (x: any) => {
    let num = Number.parseFloat(x).toFixed(2);
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  // handle checked item list
  const setCheckedItemList = (value: any) => {
    setItemsToDelete(value);
    setItemsToPurchase(value);
    setCartUpdated(true);
    setSelectedItemsIds(value?.map((item:any)=>{
      return item?.CatalogItemId
    }))
  };
  // handle click on update confirm
  const handleQuantitySave = () => {  
    setGloblalBtnPressed(false)
    let quantityData: any = [];
    let checkedPayloads: any = [];
    var priceinputs: any = document.querySelectorAll(
      ".updateChangeQunatity .ant-input-number-input"
    );
    for (var i: any = 0; i < priceinputs.length; i++) {
      checkedPayloads?.push({
        CartDetailId:  priceinputs[i].id?.split('_')[1],
        CatalogItemId: priceinputs[i].id?.split('_')[0],
        BuyerId: "",
        BuyerCartDetailId: "",
        Quantity: priceinputs[i].value?priceinputs[i].value:1,
        SaveAction: "S",
      });
    }
    quantityData = checkedPayloads
    setUpdateConfirm(true);
    var quantityPayload = {
      IsCommunityCart: selectedCart?.IsCommunityCart,
      LibraryId: lib_Id,
      SaveAction: "S",
      CartDescription: "",
      CartId: selectedCart?.CartId,
      CartName: selectedCart?.CartName,
      AssociateShelfId: selectedCart?.AssociateShelfId,
      CartDetail: quantityData
        ?.map((item: any) => {
          if (item.CartDetailId !== undefined) {
            return {
              CartDetailId: item.CartDetailId,
              CatalogItemId: item.CatalogItemId,
              BuyerId: "",
              BuyerCartDetailId: "",
              Quantity: item.Quantity,
              SaveAction: "S",
            };
          }
        })
        .filter((item: any) => {
          return item !== undefined;
        }),
    };
    dispatch(addItemtoCart(quantityPayload));
    showNotification(Messages.QUANTITY_UPDATED, "loading");
  };

  // handle click on remove confirm
  const handleRemoveConfirm = () => {
    if (itemsToDelete?.length === tableDataChosen?.length) {
      setStartCount(Page.DEFAULT_START);
      setCurrentPage(1);
    }
    setRemoveConfirm(true);
    var tempCart = {
      IsCommunityCart: selectedCart?.IsCommunityCart,
      LibraryId: lib_Id,
      SaveAction: "S",
      CartDescription: "",
      CartId: selectedCart?.CartId,
      CartName: selectedCart?.CartName,
      AssociateShelfId: selectedCart?.AssociateShelfId,
      CartDetail: itemsToDelete.map((item: any) => {
        return {
          CartDetailId: item.CartDetailId,
          CatalogItemId: item.CatalogItemId,
          BuyerId: "",
          BuyerCartDetailId: 0,
          Quantity: 0,
          SaveAction: "S",
        };
      }),
    };
    dispatch(addItemtoCart(tempCart));
    showNotification(Messages.REMOVING_ITEM, "loading");
    setTotalQuantiy([]);
    setEditCellRows([]);
    setUpdateConfirm(false);
    setShowQuantitybtn(false);
  };

  // handle checked items purchase list
  const setSelectedItemList = (value: any) => {
    setItemsToPurchase(value);
  };

  //toggle restrictions
  useEffect(() => {
    if (cartDetalis?.IsCommunityCart !== undefined) {
      if (cartDetalis?.IsCommunityCart == true) {
        setSwitchRestrictions("Unrestrict");
        setInitialRestrictions("Unrestrict");
      } else if (cartDetalis?.IsCommunityCart == false) {
        setSwitchRestrictions("Restrict");
        setInitialRestrictions("Restrict");
      }
    } else {
      if (cart?.IsCommunityCart == true) {
        setSwitchRestrictions("Unrestrict");
        setInitialRestrictions("Unrestrict");
      } else if (cart?.IsCommunityCart == false) {
        setSwitchRestrictions("Restrict");
        setInitialRestrictions("Restrict");
      }
    }
  }, [cart, cartDetalis]);

  const handleRestriction = (switchRestrictions: any) => {
    if (switchRestrictions == "Restrict") {
      setSwitchRestrictions("Unrestrict");
      if (initialRestrictions == switchRestrictions) {
        setLoading(true);
        dispatch(
          updatedNewCart({
            CartName: selectedCart?.CartName,
            AssociateShelfId: selectedCart?.AssociateShelfId,
            IsCommunityCart: true,
            SaveAction: "S",
            CartId: selectedCart?.CartId,
            LibraryId: lib_Id,
          })
        );
      }
    } else if (switchRestrictions == "Unrestrict") {
      setSwitchRestrictions("Restrict");
      if (initialRestrictions == switchRestrictions) {
        setLoading(true);
        dispatch(
          updatedNewCart({
            CartName: selectedCart?.CartName,
            AssociateShelfId: selectedCart?.AssociateShelfId,
            IsCommunityCart: false,
            SaveAction: "S",
            CartId: selectedCart?.CartId,
            LibraryId: lib_Id,
          })
        );
      }
    }
  };

  const purchases = (amount: any, preSale: any) => {
    var data = amount - preSale;
    return data;
  };

  const replaceFunction = (string: any) => {
    const final = string.replace(/[^\d.-]/g, "");
    return final;
  };

  const lendingModelData = (
    id: number,
    checkoutLimit: any,
    timespanlimit: any,
    format: any
  ) => {
    if (format !== "MP3") {
      const dataLendingModel = expiration?.filter((data: any) => {
        return data.ContentProviderId === id;
      });

      let statement = validateLendingModelType(dataLendingModel);
      var data = statement;
      if (data !== "") {
        return data;
      } else {
        return "Perpetual";
      }
    } else {
      if (checkoutLimit === null && timespanlimit === null) {
        return "Perpetual";
      } else {
        let desc = checkoutLimit + " checkouts";
        let year =
          " " + replaceFunction(timespanlimit) + " years after purchase";
        if (checkoutLimit !== null && timespanlimit !== null) {
          return desc + " or" + year;
        } else if (checkoutLimit !== null) {
          return desc;
        } else {
          return year;
        }
      }
    }
  };

  useEffect(() => {
    var chosenState = (itemID: any, selectedId: any) => {
      return selectedId?.includes(JSON.stringify(itemID));
    };
    var filterRow = tableData?.filter((item: any) => {
      return selectedCartIds?.includes(JSON.stringify(item?.CartDetailId));
    });
    setSelectedKeys(filterRow?.map((item: any) => item?.key));
    setTableDataChosen(
      tableData?.map((item: any) => {
        return {
          ...item,
          chosen: chosenState(item?.CartDetailId, selectedCartIds),
          Model: lendingModelData(
            item?.ContentProviderId,
            item?.CheckOutLimit,
            item?.TimeSpanLimit,
            item?.FileFormatName
          ),
          ImageUrl: `https://${appUser?.imageUrl}?type=DOCUMENTIMAGE&documentID=${item?.ImageId}&size=LARGE&token=nobody`,
          Categories: item?.Categories !== null ? item?.Categories[0] : null,
        };
      })
    );
  }, [tableData, selectedCartIds]);

  useEffect(() => {
    if (!showPurchase) {
      setBuyAllCart(false)
      dispatch(clearSelectedCartIds());
    }
  }, [showPurchase]);

  useEffect(() => {
    if (purchased) {
      if (keepActive !== false) {
        dispatch(
          fetchCartById({
            userId: user_id,
            cartId: cart.CartId,
            count: pageSize,
            start: startCount,
            sortBy: sortOrder,
            libraryOwned: owned,
            sortOrder: sort,
          })
        );
      }
    }
  }, [purchased, keepActive]);

  useEffect(() => {
    if (cancelItemtoCartFormstate?.isSuccess) {
      if (keepActive) {
        dispatch(
          fetchCartById({
            userId: user_id,
            cartId: cart.CartId,
            count: pageSize,
            start: startCount,
            sortBy: sortOrder,
            libraryOwned: owned,
            sortOrder: sort,
          })
        );
      }
      dispatch(clearAdded());
      setShowPurchase(false);
      dispatch(clearCancel());
    }
  }, [cancelItemtoCartFormstate, keepActive]);

  const onPageChange = (page: any, size: any) => {
    dispatch(setMainScrollHeight(0)); 
    setFormatValue("all");
    if (!fetchCartByIdFormState.loading) {
      const pageStart = (page - 1) * size + 1;
      setStartCount(pageStart);
      setCurrentPage(page);
      dispatch(
        fetchCartById({
          userId: user_id,
          cartId: cart.CartId,
          count: pageSize,
          start: pageStart,
          sortBy: sortOrder,
          libraryOwned: owned,
          sortOrder: sort,
        })
      );
      setTotalQuantiy([]);
      setEditCellRows([]);
      setUpdateConfirm(false);
      setShowQuantitybtn(false);
    }
  };

  const changePageSize = (size: number) => {
    setFormatValue("all");
    setStartCount(Page.DEFAULT_START);
    setPageSize(size);
    dispatch(retainPageSize(size))
    setCurrentPage(1);
    dispatch(
      fetchCartById({
        userId: user_id,
        cartId: cart?.CartId,
        count: size,
        start: Page.DEFAULT_START,
        sortBy: sortOrder,
        libraryOwned: owned,
        sortOrder: sort,
      })
    );
    setTotalQuantiy([]);
    setEditCellRows([]);
    setUpdateConfirm(false);
    setShowQuantitybtn(false);
  };

  useEffect(() => {
    let totalCount: any = selectedCart?.NumberOfTitles;
    let end = startCount + pageSize - 1;
    if (totalCount < end) {
      setLastCount(totalCount);
    } else {
      setLastCount(end);
    }
  }, [startCount, pageSize, selectedCart]);

  useEffect(() => {
    let totalTitles: any = selectedCart?.NumberOfTitles;
    if (totalTitles > pageSize) {
      setPaginationOn(true);
    } else {
      setPaginationOn(false);
    }
  }, [selectedCart, pageSize]);

  const restrictionOverlay = (
    <>
      {isUserCart() ? (
        <>
          <div className="cart-ownership-label">Cart Ownership</div>
          <div>
            <p className="restriction-label">Owned By:</p>
            <p className="restriction-value">
              {cart?.CartOwnerFirstName + " " + cart?.CartOwnerLastName}
            </p>
          </div>
          <div>
            <p className="restriction-label">Editing Permissions:</p>
            <p className="restriction-value">
              Everyone{" "}
              {/* <span className="restriction-label">
                <b>
                  <u
                    style={{ cursor: "pointer" }}
                    onClick={() => handleRestriction(switchRestrictions)}
                  >
                    {switchRestrictions}
                  </u>
                </b>
              </span> */}
            </p>
          </div>
        </>
      ) : (
        <>
          <div>
            <p className="restriction-label">Owned By:</p>
            <p className="restriction-value">
              {cart?.CartOwnerFirstName + " " + cart?.CartOwnerLastName}
            </p>
          </div>
          <div>
            <p className="restriction-label">Editing Permissions:</p>
            <p className="restriction-value">Private</p>
          </div>
        </>
      )}
      <div className="page-size-padding">
        <Row>
          <Col style={{ width: "20px" }}>
            <img style={{ width: "14px", height: "14px" }} src={list} alt=" " />
          </Col>
          <Col flex="auto" style={{ paddingLeft: "5px" }}>
            Page Size
          </Col>
        </Row>
      </div>
      {optionList.map((data: number, index: number) => {
        return (
          <div
            key={index}
            style={{ cursor: "pointer" }}
            onClick={() => changePageSize(data)}
          >
            <Row className="pagination-option-padding">
              <Col style={{ width: "20px" }}>
                {pageSize === data ? (
                  <img
                    style={{ width: "14px", height: "14px", marginTop: "10px" }}
                    src={asteriskIcon}
                    alt="*"
                  />
                ) : (
                  " "
                )}
              </Col>
              <Col className="cart-page-option" style={{ paddingLeft: "5px" }}>
                {data}
              </Col>
            </Row>
          </div>
        );
      })}
      <div className="cart-export-list">
        <Row>
          <Col style={{ width: "23px" }}>
            <img
              style={{ width: "14px", height: "14px", marginTop: "-3px" }}
              src={ExportIcon}
              alt=" "
            />
          </Col>
          <Col>
            <a
              href={`${getAPIBaseUrl(
                PortalModule.CARTS
              )}/exportCart?user_id=${user_id}&cart_id=${
                cart?.CartId
              }&record_count=${selectedCart?.NumberOfTitles}&record_start=1&sortdirection=${sort}&sortfield=${
                sortOrder && sortOrder !== "Sort By" ? sortOrder : "SortOrder"
              }&filterLibraryOwned=${owned}&filter_Format=${formatValue}&format=csv`}
              className="export-link"
              target="_blank"
              rel="noopener noreferrer"
              download
            >
              Export List
            </a>
          </Col>
        </Row>
      </div>
    </>
  );

  const fetchInfo = (CatalogItemId: any) => {
    if (!carts[CatalogItemId]) {
      var lib_Id =
        appUser && appUser.libraryData ? appUser.libraryData[0].LibraryId : 0;
      dispatch(
        fetchCartsByBookId({
          libraryId: lib_Id,
          bookId: CatalogItemId,
        })
      );
    }
  };
  useEffect(() => {
    var selectedArray = userColumns
      ?.filter((el: any) => {
        return el.check === false;
      })
      .map((item: any) => item.title);
    dispatch(savedColumns(userColumns));
    dispatch(selectedCols(selectedArray));
  }, [userColumns]);

  useEffect(() => {
    if (columnsResize.length > 0) {
      let newArray = columnsResize.map((el: any) => {
        return { title: el.title, width: el.width };
      });
      dispatch(resizeColumns(newArray));
    }
  }, [columnsResize]);

  const dateModify = (date: any) => {
    const myStr = date.substr(0, 10);
    const currentDate = moment(myStr).tz("Etc/GMT").format(dateFormat);
    return currentDate;
  };

  useEffect(() => {
    if (tableData !== undefined) {
      const tableValue = tableData?.map((item: any) => {
        var Title = item?.Title;
        const refineTitle = Title.replaceAll('"', "");
        var Auther = item?.Authors;
        const refineAuther = Auther.replaceAll('"', "");
        const PublicationDate =
          item?.PublicationDate !== null || undefined
            ? dateModify(item?.PublicationDate)
            : "";
        const arr: any[] = [];
        const arr2: any[] = [];
        const str = item?.Categories;
        str?.map((item: any) => {
          const res = item?.split(":");
          arr.push(res[0]);
          arr2.push(res[1]);
          return [];
        });
        return {
          ...item,
          category: arr,
          subCategory: arr2,
          refineTitle: refineTitle && refineTitle,
          refineAuther: refineAuther && refineAuther,
          PublicationDate: PublicationDate,
        };
      });
      setExportData(tableValue);
    }
  }, [tableData]);

  const headers = [
    { label: "Title", key: "refineTitle" },
    { label: "Authors", key: "refineAuther" },
    { label: "Narrator", key: "Narrator" },
    { label: "Series", key: "Series" },
    { label: "Category", key: "category" },
    { label: "Sub Category", key: "subCategory" },
    { label: "Format", key: "FileFormatName" },
    { label: "ISBN", key: "EISBN" },
    { label: "Publisher", key: "Imprint" },
    { label: "Provider", key: "ContentProviderName" },
    { label: "Publication Date", key: "PublicationDate" },
    { label: "Model", key: "Model" },
    { label: "Owned", key: "NumberOfCopies" },
    { label: "Holds", key: "NumberOfHolds" },
    { label: "Loans", key: "NumberOfLoans" },
    { label: "Circ", key: "NumberOfCurrentLoans" },
    { label: "Suggested", key: "NumberOfWish" },
    { label: "Carts", key: "NumberInCarts" },
    { label: "Order", key: "NumberOnOrder" },
    { label: "PPU Price", key: "PPUPrice" },
    { label: "Price", key: "RetailPrice" },
    { label: "Quantity", key: "Quantity" },
    { label: "Total Price", key: "totalPrice" },
    { label: "cloudLink Shareable Copies", key: "GroupSharableCopies" },
    { label: "cloudLink Current Holds", key: "GroupHoldCount" },
  ];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const csvExportList = {
    filename: `carts-report.csv`,
    headers: headers,
    data: exportData,
  };

  const moveToCart = (cart: any) => {
    if (itemsToDelete?.length > 0) {
      setMoveCartModel(true);
      setDestinationCart(cart);
      if (itemsToDelete?.length === tableDataChosen?.length) {
        setStartCount(Page.DEFAULT_START);
        setCurrentPage(1);
      }
    } else if (itemsToDelete?.length == 0) {
      setTitlesModel(true);
    }
  };

  var permissions =
    appUser && appUser.libraryData
      ? appUser.libraryData[0].FeaturePermissions
      : "";

  var filteredCarts = permissions.includes("AudioBooks")
    ? cartsList.filter((data: Cart) => {
        return (
          data.CartOwner === appUser?.email || data.IsCommunityCart === true
        );
      })
    : cartsList.filter((data: Cart) => {
        return (
          (data.CartOwner === appUser?.email ||
            data.IsCommunityCart === true) &&
          !data.CartTypeCode?.includes("Audio")
        );
      });

  const getBookLabel = (format: any) => {
    switch (format) {
      case "EPUB": {
        return ebookLabel;
      }
      case "PDF": {
        return ebookLabel;
      }
      case "MP3": {
        return audiobookLabel;
      }
      case "VideoBook": {
        return videobookLabel;
      }
    }
  };
  const isEditable = (data:Cart) => {
    if(data.CartOwner === appUser?.email || data.IsCommunityCart || appUser?.isLibraryBuyer){
      return true;
  }else{
      return false;
  }
  }
  const isAutoCart = (data:any) => {
    if((data?.CartOwner)?.toLowerCase() === "auto" && data?.CartTypeCode?.toLowerCase().indexOf('autobuy') >= 0){
      return true;
  }else{
      return false;
  }
  }
  
  const onEditClick =(data: Cart)=>{
    if(data.CartOwner === appUser?.email || (appUser?.isLibraryAdmin && data.IsCommunityCart) || appUser?.isLibraryBuyer || appUser?.isLibraryUser){
      setShowModal(true)
      setIsEditClick(true)
  }
  }

useEffect(()=>{
  setCartDescription(cartDetalis)
},[cartDetalis])

  useEffect(() => {
    if (moveTitleIsContinue) {
      setTotalQuantiy([]);
      setEditCellRows([]);
      setUpdateConfirm(false);
      setShowQuantitybtn(false);
      setMoveTitleIsContinue(false);
    }
  }, [moveTitleIsContinue]);

  useEffect(() => {
    if (setCartDetailsFilter?.gridView !== undefined) {
      setGridView(setCartDetailsFilter?.gridView);
    }
  }, [setCartDetailsFilter]);

  const handelBackToTop = () => {
    const element = document.getElementById(`scrollableDiv`);
    element?.scrollTo(0, 0);
    setScrollValue(0);
  };

  const handleScroll = () => {
    const element = document.getElementById(`scrollableDiv`);
    let x = element?.scrollTop;
    x && setScrollValue(x);
  };

  useEffect(()=>{
     if(!showModal && isEditClick){
        setIsEditClick(false)
     }
  },[showModal,isEditClick])

  useEffect(()=>{
    if(globlalBtnPressed){
      if(itemsToPurchase?.length > 0){
        var x = [[],...itemsToPurchase]
        setTotalQuantiy(x)
      }
    }
  },[itemsToPurchase,globlalBtnPressed])

  useEffect(()=>{
    if(globlalBtnPressed){
      if(globalQtyCart > 1 && itemsToPurchase?.length === 0 && totalQuantiy?.length > 1){
        setShowQuantitybtn(true)
      }else if(itemsToPurchase?.length === 0 && totalQuantiy?.length > 1){
        setShowQuantitybtn(true)
      }else{
        setShowQuantitybtn(false)
      }
    }
  },[globalQtyCart,itemsToPurchase,totalQuantiy,globlalBtnPressed])

  useEffect(() => {
    return () => {
      dispatch(clearCartDetailFilter())
    };
  }, []);

  return (
    <div>
      <button className="back-button" onClick={goBack}>
        {selectedTab == "2" ? (
          <>
            &lt; <u>All Carts</u>
          </>
        ) : (
          <>
            &lt; <u>My Carts</u>
          </>
        )}
      </button>
      <ComponentLoader loading={loading}>
        <Row>
          <Col span={24}>
            <Row>
              <Col span={24}>
                <p className="page-title">{loading ? "" : cartDescription?.CartName}{
                isEditable(cart) && !isAutoCart(cart)?
                
            <img src={editIcon} style={{cursor: "pointer"}} alt= "editIcon" className="editIconAlign" 
            onClick={()=>onEditClick(cart)}
            /> 
            : ""
           }</p>
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={24} md={13} lg={13} xl={12}>
                 <Row className="cart_title_padding">
          <Col span={24}>
            <Row>
              <Col span ={6}>
                <p className="footer-label">Total Titles:</p>
              </Col>
              <Col>
                <p className="footer-label">
                  {formatValue === 'all'?selectedCart?.NumberOfTitles:totalTitles} ({getNumberofTitles()}{" "}
                  purchases / {formatValue === 'all'?selectedCart?.NumberOfPrepubTitles:totalTitlesPreSale} presale)
                </p>
              </Col>
            </Row>
            <Row>
              <Col span ={6} >
                <p className="footer-label">Total Copies:</p>
              </Col>
              <Col >
                <p className="footer-label">
                  {formatValue === 'all'?totalQuantity:TotalCopies} ({purchases(formatValue === 'all'?totalQuantity:TotalCopies, formatValue === 'all'?prePubQunat:totalNumbCopiesPreSale)}{" "}
                  purchases / {formatValue === 'all'?prePubQunat:totalTitlesPreSale} presale)
                </p>
              </Col>
            </Row>
            <Row>
              <Col span ={6}>
                <p className="footer-label">
                  <b>Total Amount:</b>
                </p>
              </Col>
              <Col >
                <p className="footer-label">
                  <b>
                    {currency}
                    {roundofNumber(formatValue === 'all'?totalAmount:totalFilteritemAmount)}{" "}
                  </b>
                  <span>
                    ({roundofNumber(purchases(formatValue === 'all'?totalAmount:totalFilteritemAmount, formatValue === 'all'?totalPreSAle:totalFilterPreitemAmount))}{" "}
                    purchases / {roundofNumber(formatValue === 'all'?updateCartPreSale:totalFilterPreitemAmount)} presale)
                  </span>
                </p>
              </Col>
            </Row>
            </Col>
            </Row>


              </Col> 
              
              <Col
                xs={16}
                sm={16}
                md={5}
                lg={7}
                xl={9}
                style={{ textAlign: "right" }}
              >
                <div className="switchViewButtons">
                <Radio.Group
                    value={gridView}
                    onChange={(e) => setGridView(e.target.value)}
                  >
                    <Radio.Button value={1}>Detail View</Radio.Button>
                    <Radio.Button value={0}>List View</Radio.Button>
                  </Radio.Group>
                </div>
              </Col>
              {ready && (
                <Col
                  xs={8}
                  sm={8}
                  md={6}
                  lg={5}
                  xl={4}
                  style={{ paddingLeft: "10px" }}
                >
                  <div className="readyLabel">
                    <span>&#10003; Ready for Purchase</span>
                  </div>
                </Col>
              )}
              <Col
                xs={8}
                sm={8}
                md={6}
                lg={4}
                xl={3}
                style={{ paddingLeft: "10px" }}
              >
                <Popover
                  content={restrictionOverlay}
                  trigger={["click"]}
                  placement="bottomRight"
                >
                  {isUserCart() ? (
                    <button className="button-type editor">
                      <img src={editIcon} className="edit-icon" alt="" /> Editor
                      <img src={downIcon} className="down-icon" alt="" />
                    </button>
                  ) : (
                    <button className="button-type viewer">
                      <img src={viewerIcon} className="edit-icon" alt="" />{" "}
                      Viewer
                      <img src={downIcon} className="down-icon" alt="" />
                    </button>
                  )}
                </Popover>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* <div id={gridView === 1 ?"scrollableDiv" : ""} className={` ${gridView === 1 ? "detailViewHeight scrollOnCartScreen" : ""}`}  onScroll={handleScroll} >
         {scrollValue > 2000 && gridView === 1?<FloatButton onClick={handelBackToTop}>&#8593;</FloatButton>:null} */}
        <ListApp
          cart={cart}
          selectedCart={selectedCart}
          totalTitles={formatValue === 'all'?undefined:totalTitles}
          cartData={tableDataChosen}
          handleFormatChange={handleFormatFilter}
          handleOwnedChange={handleOwnedFilter}
          handleSortChange={handleSortFilter}
          amountUpdate={handleUpdatedAmount}
          ownedValue={owned}
          appliedSorter={sortOrder}
          setStart={setStartIndex}
          copies={copies}
          // setCopies={setCopies}
          sortIcon={sortIcon}
          onSortClick={onSortClick}
          imageUrl={imageUrl}
          currency={currency}
          loading={loading}
          setUpdated={setCartUpdated}
          setCheckedItems={setCheckedItemList}
          setSelectedItems={setSelectedItemList}
          history={history}
          fetchInfo={fetchInfo}
          checkedRows={selectedKeys}
          userColumns={userColumns}
          setUserColumns={setUserColumns}
          setColumnsResize={setColumnsResize}
          resizeCols={resizeCols}
          selectedColums={selectedColums}
          tableData={tableData}
          formatValue={formatValue}
          moveToCart={moveToCart}
          appUser={appUser}
          filteredCarts={filteredCarts}
          setShowModal={setShowModal}
          buyerItemsId={buyerItemsIdArray}
          getBookLabel={getBookLabel}
          getQuantity={setEditCellRows}
          resetData={resetData}
          pageSize={pageSize}
          currentPage={currentPage}
          onPageChange={onPageChange}
          lastCount={lastCount}
          startCount={startCount}
          gridView={setCartDetailsFilter?.gridView}
          showQuantitybtn={showQuantitybtn}
          itemsToDelete={itemsToDelete}
          onRemove={onRemove}
          onPurchase={onPurchase}
          onPurchaseCart={onPurchaseCart}
          handleQuantitySave={handleQuantitySave}
          onQuantyCancel={onQuantyCancel}
          selectedItemsIds={selectedItemsIds}
          maxQty={maxQtyA}
          setGlobalQtyCart={setGlobalQtyCart}
          setGloblalBtnPressed={setGloblalBtnPressed}
          notSetMin={notSetMin}
          notSetMax={notSetMax}
          setLoadScreen={setLoadScreen}
          removeconfirm={removeConfirm}
        />
        <Row className={`detail-footer cartDetailFooter ${(cart?.NumberOfTitles < 2 && gridView === 1) || (cart?.NumberOfTitles < 5 && gridView === 0) ? "hideDetailFooter" : "" }`}>
          <Col className="paginationAlign">
            {tableDataChosen?.length > 0 && cart?.NumberOfTitles > pageSize ? (
              <div className="pagination-container">
              <div className="cart-detail-pagination">
                <PaginationTag
                  total={selectedCart?.NumberOfTitles}
                  current={currentPage}
                  defaultPageSize={pageSize}
                  onChange={onPageChange}
                />
              </div>
              <span className="title-count">
              {formatValue === 'all'?null:totalTitles+' '+'in'+' '}{startCount}-{lastCount} of {selectedCart?.NumberOfTitles}
                Titles
              </span>
            </div>
            ) : null}
            </Col>
             {!showQuantitybtn && itemsToDelete.length > 0 ? (
              <Col  className="removeBtnLink">
                {appUser?.isLibraryBuyer == true ? (
                  <>
                    {/* {selectedCart?.CartOwner === appUser?.email ||
                  selectedCart?.IsCommunityCart ? ( */}
                    <Button
                      type="smallRed mark-ready-button"
                      onClick={() => {
                        onRemove();
                      }}
                    >
                      Remove selected
                    </Button>
                    {/* ) : null} */}
                  </>
                ) : (
                  <>
                    {selectedCart?.CartOwner === appUser?.email ||
                    selectedCart?.IsCommunityCart ? (
                      <Button
                        type="smallRed mark-ready-button"
                        onClick={() => {
                          onRemove();
                        }}
                      >
                        Remove selected
                      </Button>
                    ) : null}
                  </>
                )}
              </Col>
            ) : showQuantitybtn ? null : (
              <Col span ={1}></Col>
            )}
              {!showQuantitybtn ? (
              <Col span={8} className="purchaseBtn">
                {appUser?.isLibraryBuyer == true &&
                (selectedCart?.CartDetails
                  ? selectedCart?.CartDetails.length
                  : null) ? (
                  <>
                    <Button
                  type="primary mark-ready-button"
                  onClick={() => {
                    onPurchaseCart(true);
                  }}
                >
                  Purchase Cart
                </Button>
                <Button
                  type="primary mark-ready-button"
                  onClick={() => {
                    onPurchase();
                  }}
                >
                  Purchase Selected
                </Button>
                  </>
                ) : (
                  <></>
                )}
              </Col>
            ) : null}
             {showQuantitybtn ? (
              <>
                <Col span={4} className="update_quantity cart_update_quantity">
                  <Button
                    onClick={handleQuantitySave}
                    type="primary mark-ready-button"
                  >
                    Update Quantity
                  </Button>
                </Col>
                <Col span={3} className="update_quantity cart_cancel_btn">
                  <Button
                    onClick={onQuantyCancel}
                    type="smallRed mark-ready-button"
                  >
                    Cancel
                  </Button>
                </Col>
              </>
            ) : null}
                    
        </Row>
        {/* </div> */}
      </ComponentLoader>
      <PurchaseConfirmModal
        showPurchase={showPurchase}
        setShowPurchase={setShowPurchase}
        setShowLoading={setShowLoading}
        selectedCart={selectedCart}
        numberofCopies={getNumbrofCopies()}
        numberOfTitles={getNumberofTitles()}
        totalAmount={getTotalAmount()}
        setPurchased={setPurchased}
        libId={lib_Id}
        currency={currency}
        userId={user_id}
        cartId={cart?.CartId}
        start={startIndex}
        sortBy={sortOrder}
        libraryOwned={owned}
        sortOrder={sort}
        selectedItemsData={selectedItems}
        itemsToPurchase={itemsToPurchase}
        cartData={tableDataChosen}
        setKeepActive={setKeepActive}
        isPaginationOn={PaginationOn}
        setStartCount={setStartCount}
        setCurrentPage={setCurrentPage}
        buyAllCart={buyAllCart}
      />
      <PurchaseSuccessModal
        purchased={purchased}
        setPurchased={setPurchased}
        libId={lib_Id}
        formState={loading}
        titles={selectedCart?.NumberOfTitles}
      />
      <RemoveConfirmModel
        showRemove={showRemove}
        setShowRemove={setShowRemove}
        removeConfirm={handleRemoveConfirm}
        heading={"Remove Confirmation"}
        message={
          "Are you sure you want to remove the selected book(s) from the cart?"
        }
      />
      <MoveToCartModel
        selectedCart={selectedCart}
        showModel={moveCartModel}
        setShowModel={setMoveCartModel}
        itemsToMove={itemsToDelete}
        owned={owned}
        cartId={cart.CartId}
        destinationCart={destinationCart}
        startCount={startCount}
        pageSize={pageSize}
        setMoveTitleIsContinue={setMoveTitleIsContinue}
      />
      <SelectTitleModel
        selectedCart={selectedCart}
        showModel={titlesModel}
        setShowModel={setTitlesModel}
      />
      <NewCartModal
        isModalVisible={showModal}
        setModalVisible={setShowModal}
        appUser={appUser}
        selectedCart={cartDescription}
        editable={isEditClick?true:false} 
        isNewCartDetailsNeed={false} 
      />
    </div>
  );
};
