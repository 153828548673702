/* eslint-disable no-useless-concat */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo, useRef } from "react";
import {
  Col,
  Row,
  InputNumber,
  Menu,
  Dropdown,
  Checkbox,
  Popover,
  Divider,
  Tooltip,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment-timezone";
import { addItemtoCart } from "../../../../../redux/actions/carts/cartAction";
import {
  whiteArrow,
  ebookLabel,
  audiobookLabel,
  videobookLabel,
  infoIcon,
  notFound,
  downIcon,
} from "../../../../images";
import Button from "../../../stateless/common/button";
import Cart from "../../../../models/cart/cart";
import { Book } from "../../../../models/book/book";
import { useHistory, useLocation } from "react-router-dom";
import { AppRoutes } from "../../../../router/appRoutes";
import { fetchCartsByBookId, setCheckedValues } from "../../../../../redux/actions/books/bookActions";
import {
  BookStateSelector,
  clearBooklistCurrentPage,
} from "../../../../../redux/reducers/books/bookListReducer";
import {
  CartStateSelector,
  setTab,
} from "../../../../../redux/reducers/carts/cartsReducer";
import { ExpirationstateSelector } from "../../../../../redux/reducers/expiration/expirationReducer";
import { validateLendingModelType } from "../validateLendingData";
import { NewCartModal } from "../../carts/newCartModal";
import { Messages } from "../../../../constants/messages";
import {
  getSearchString,
  onBackFillForm,
} from "../../../../../redux/actions/search/searchActions";
import {
  searchPayloadForAuthor,
  searchPayloadForSeries,
  searchPayloadForNarrator,
} from "../../../../../utility/searchUtil";
import {
  removeExtraCharacter,
  replaceAll,
} from "../../../../../utility/appUtil";
import {
  setSearchScroll,
  setSearchTab,
} from "../../../../../redux/reducers/search/searchReducer";
import { ShelfstateSelector } from "../../../../../redux/reducers/shelves/shelfReducer";
import { addItemtoIndividualShelve } from "../../../../../redux/actions/shelves/shelfAction";
import { UseGAEventTracker } from "../../../../../utility/useGAEventTracker";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

export const BookRow = (props: any) => {
  const {
    book,
    allSelected,
    onCheckboxChange,
    initialChecked,
    filteredCarts,
    appUser,
    showNotification,
    searchStringParam,
    editSelected,
    isCollection,
    scrollValue,
    clickArea,
    addShelves,
    isCart,
    updateAmount,
    onCopie,
    globalCopie,
    globalPrevious,
    selectAllCancel,
    selectAllChkBox,
    globalC,
    selectedItemsIds,
    globalQty,
    pressdArrow,
    notSetMin,
    notSetMax,
    maxQty,
    globlalQtyBtnPressed,
    selectedItems,
    setSelectedItems,
    loading
  } = props;
  const dispatch = useDispatch();
  const { carts, selectedTitles, checkedValues, booklistCurrentPage } =
    useSelector(BookStateSelector);
    const { setCartDetailsFilter,fetchCartByIdFormState,formState } = useSelector(CartStateSelector);
const {scrollHeightMain}=useSelector(CartStateSelector);
  const { addedItem, activeCartArray } = useSelector(CartStateSelector);
  const { shelves, selectedShelve } = useSelector(ShelfstateSelector);
  const { expiration } = useSelector(ExpirationstateSelector);
  const history = useHistory();
  const [copies, setCopies] = useState<any>({});
  const [bookDetail, setBookDetail] = useState<any>([]);
  const [PressdArrowBtn, setPressdArrowBtn] = useState<any>({offset: 1, type: ''});
  const [val, setVal] = useState<number>(book?.Quantity);
  const [globalQuantity, setGlobalQuantity] = useState<number>(1);
  const [cartVal, setCartVal] = useState<number>(book?.Quantity);
  const [itemVal, setItemVal] = useState<number>(cartVal);
  const [value, setValue] = useState<number>(book?.Quantity);
  const [savedArr, setSavedArr] = useState<any>({
    CatalogItemId: book?.CatalogItemId,
    Quantity: 1,
  });
  const currency = appUser?.libraryData[0]?.CurrencySymbol;
  var dateFormat = appUser?.libraryData[0]?.DateFormat.toUpperCase();
  var shelfAdmin = appUser ? appUser?.isShelfAdmin : "";
  const location=useLocation();
  const [showModal, setShowModal] = useState(false);
  const [inCart, setInCart] = useState(book?.NumberInCarts);
  const [bookAdded, setBookAdded] = useState(0);
  const [bookCart, setBookCart] = useState(0);
  const [checked, setChecked] = useState<boolean>(true);
  const [isValChanged, setIsValChanged] = useState<boolean>(false);
  const [check, setCheck] = useState<boolean>(false);
  const [shelvesData, setShelvesData] = useState<any>(shelves);
  const [screenSize, setDimension] = useState<any>({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    setChecked(initialChecked);
  }, [initialChecked]);

  const userCarts = (
    <Menu className="header-dropdown">
      {filteredCarts.map((data: Cart, index: number) => {
        return (
          <Menu.Item
            key={index}
            onClick={() => onAddtoCartDropdown(book, data)}
          >
            <p className="dropDown-title">{data.CartName}</p>
            <span className="dropDown-content">
              {currency}
              {data.TotalCartAmount.toFixed(2)
                ?.toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
              . {data.NumberOfTitles} Titles
            </span>
          </Menu.Item>
        );
      })}
      <Menu.Divider />
      <Menu.Item
        key="All-Carts-Link"
        className="dropDown-link"
        onClick={() => {
          history.push(AppRoutes.CART);
          dispatch(setTab("2"));
        }}
      >
        <p>All Carts</p>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        key="New-cart-Link"
        className="dropDown-link"
        onClick={() => setShowModal(true)}
      >
        <p>New Cart</p>
      </Menu.Item>
    </Menu>
  );
  const InfoCarts = (
    <div>
      {carts ? (
        carts[book.CatalogItemId] && carts[book.CatalogItemId].length ? (
          carts[book.CatalogItemId]?.map((bookCart: any, index: number) => {
            return (
              <div key={`cart${index}`}>
                <p className="book-cart">{bookCart.CartName}</p>
                <p className="copies-text">{bookCart.NumberOfCopies} Copies</p>
                {index < carts[book.CatalogItemId]?.length - 1 && (
                  <Divider className="carts-divider" />
                )}
              </div>
            );
          })
        ) : (
          <div className="book-cart">Loading...</div>
        )
      ) : null}
    </div>
  );

  const getWindowSize = () => {
    setDimension({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    setBookDetail(book)
  }, [])

  useEffect(() => {
    setCartVal(book?.Quantity)
  }, [book?.Quantity])

  useEffect(() => {
    window.addEventListener("resize", getWindowSize);
    return () => {
      window.removeEventListener("resize", getWindowSize);
    };
  }, [screenSize]);

  useEffect(() => {
    if (book.CatalogItemId === bookCart && carts[book.CatalogItemId]) {
      let bookInCart = 0;
      // eslint-disable-next-line array-callback-return
      carts[book.CatalogItemId]?.map((bookCart: any, index: number) => {
        bookInCart += bookCart.NumberOfCopies;
      });
      bookInCart && setInCart(bookInCart);
      setBookCart(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carts]);

  const fetchInfo = () => {
    if (
      !carts[book.CatalogItemId] &&
      book?.NumberInCarts > 0 &&
      bookCart !== book.CatalogItemId
    ) {
      setBookCart(book.CatalogItemId);
      var lib_Id =
        appUser && appUser.libraryData ? appUser.libraryData[0].LibraryId : 0;
      dispatch(
        fetchCartsByBookId({
          libraryId: lib_Id,
          bookId: book.CatalogItemId,
        })
      );
    }
  };

  const onBookSelect = (book: any) => {
    var bookState: any = {
      book: book,
      searchParam: setCartDetailsFilter,
    };
    history.push(AppRoutes.ITEMDETAILS, bookState);
    dispatch(setSearchTab("3"));
    dispatch(setSearchScroll(scrollValue));
  };

  const handleKeyWord = (book: any) => {
    searchPayloadForAuthor(book?.Authors, appUser).then((data: any) => {
      dispatch(getSearchString(data.strinParam));
      dispatch(onBackFillForm(data.searchParam));
      var advanceState: any = {
        searchDetails: data?.state?.searchDetails,
        gaString: {
          gaData: setCartDetailsFilter,
          currentPage: booklistCurrentPage,
        },
      };
      dispatch(clearBooklistCurrentPage());
      history.push(AppRoutes.SEARCHRESULTS, advanceState);
    });
  };

  const handleNarratorKeyWord = (book: any) => {
    searchPayloadForNarrator(book?.Narrator, appUser).then((data: any) => {
      dispatch(getSearchString(data.strinParam));
      dispatch(onBackFillForm(data.searchParam));
      var advanceState: any = {
        searchDetails: data?.state?.searchDetails,
        gaString: {
          gaData: setCartDetailsFilter,
          currentPage: booklistCurrentPage,
        },
      };
      dispatch(clearBooklistCurrentPage());
      history.push(AppRoutes.SEARCHRESULTS, advanceState);
    });
  };

  const MyGroupTable = (
    CloudLinkGroupName: any,
    GroupSharableCopies: any,
    GroupHoldCount: any
  ) => {
    return (
      <div className="my-group-table">
        <table>
          <thead>
            <tr>
              <td colSpan={2}>
                <b>
                  {CloudLinkGroupName}&nbsp;
                  <Popover
                    content="Data is refreshed within 24 hours"
                    trigger={["hover", "click"]}
                    placement="bottomLeft"
                    getPopupContainer={(trigger: any) => trigger.parentNode}
                    overlayClassName="custom-popover"
                  >
                    <img
                      className="cloud-link-info-icon"
                      src={infoIcon}
                      alt="info"
                    />
                  </Popover>
                </b>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr className="subHeading">
              <td>Shareable Copies</td>
              <td>Current Holds</td>
            </tr>
            <tr className="tableValues">
              <td>{GroupSharableCopies}</td>
              <td>{GroupHoldCount}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  const onAddtoCartDropdown = (book: Book, cart: Cart) => {
    var bookCopiesId = book?.CatalogItemId;
    var lib_Id =
      appUser && appUser?.libraryData ? appUser?.libraryData[0]?.LibraryId : 0;
    var payloadData = {
      IsCommunityCart: cart?.IsCommunityCart,
      LibraryId: lib_Id,
      SaveAction: "S",
      CartDescription: cart?.CartDescription,
      CartId: cart?.CartId,
      CartName: cart?.CartName,
      AssociateShelfId: cart?.AssociateShelfId,
      CartDetail: [
        {
          CatalogItemId: book?.CatalogItemId,
          BuyerId: "",
          BuyerCartDetailId: 0,
          Quantity: copies[bookCopiesId] ? copies[bookCopiesId] : 1,
          SaveAction: "S",
        },
      ],
    };
    setBookAdded(book.CatalogItemId);
    dispatch(addItemtoCart(payloadData));
    const msg = replaceAll(
      /\{0\}/gi,
      Messages.ADD_TO_CART,
      `${cart?.CartName}`
    );
    showNotification(msg, "loading");
    UseGAEventTracker(
      "Cart category",
      "user selected individual copies",
      `user add to cart individual item having total copies - ${copies[bookCopiesId] ? copies[bookCopiesId] : 1
      }`,
      NaN
    );
  };
  useEffect(() => {
    if (addedItem && bookAdded === book.CatalogItemId) {
      setBookCart(book.CatalogItemId);
      var lib_Id =
        appUser && appUser.libraryData ? appUser.libraryData[0].LibraryId : 0;
      dispatch(
        fetchCartsByBookId({
          libraryId: lib_Id,
          bookId: book.CatalogItemId,
        })
      );
      setBookAdded(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addedItem]);
  const getBookLabel = (format: string) => {
    switch (format) {
      case "EPUB": {
        return ebookLabel;
      }
      case "PDF": {
        return ebookLabel;
      }
      case "MP3": {
        return audiobookLabel;
      }
      case "VideoBook": {
        return videobookLabel;
      }
    }
  };

  const calcByGlobal = (gVal: number, inpVal: number) => {
    if (gVal !== 0 && gVal !== undefined) {
      var finalCalc = inpVal + gVal;
      var finalVal = finalCalc > 0 ? finalCalc : 1;
      return finalVal;
    } else {
      return inpVal;
    }
  };

  const onClickCheckbox = (e: CheckboxChangeEvent) => {
    const isChecked = e.target.checked;
    onCheckboxChange(book.CatalogItemId, isChecked);
    setChecked(isChecked);
    e.stopPropagation();
  };

  useEffect(() => {
    if (
      selectedTitles?.amtChangCopie !== undefined &&
      selectedTitles?.amtChangCopie?.length > 0
    ) {
      setSavedArr(
        selectedTitles?.amtChangCopie?.find(
          (item: any) => item?.CatalogItemId === book.CatalogItemId
        )
      );
    } else {
      setSavedArr({ CatalogItemId: book?.CatalogItemId, Quantity: 1 });
    }
  }, [selectedTitles]);

  useEffect(() => {
    if (savedArr?.Quantity !== undefined) {
      const quant = savedArr?.Quantity > 0 ? savedArr?.Quantity : 1;
      setValue(quant);
    } else if(checkedValues?.includes(book.CatalogItemId)) {
      setValue(globalC);
    }
  }, [savedArr]);

  useEffect(() => {
    if (checkedValues?.includes(book?.CatalogItemId)) {
      const glovbalVal = globalCopie - globalPrevious;
      setValue(calcByGlobal(glovbalVal, value));
    }
  }, [val, globalCopie]);

  useEffect(() => {
    if (checkedValues?.includes(book?.CatalogItemId)) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [checkedValues]);

  const replaceFunction = (string: any) => {
    const final = string.replace(/[^\d.-]/g, "");
    return final;
  };

  const lendingModelData = (
    id: number,
    checkoutLimit: any,
    timespanlimit: any,
    format: any
  ) => {
    if (format !== "MP3") {
      const dataLendingModel = expiration?.filter((data: any) => {
        return data.ContentProviderId === id;
      });

      let statement = validateLendingModelType(dataLendingModel);
      return statement;
    } else {
      if (checkoutLimit === null && timespanlimit === null) {
        return "";
      } else {
        let desc = checkoutLimit + " checkouts";
        let year =
          " " + replaceFunction(timespanlimit) + " years after purchase";
        if (checkoutLimit !== null && timespanlimit !== null) {
          return desc + " or" + year;
        } else if (checkoutLimit !== null) {
          return desc;
        } else {
          return year;
        }
      }
    }
  };

  const searchBySeries = () => {
    searchPayloadForSeries(book?.Series, appUser).then((data: any) => {
      dispatch(getSearchString(data.strinParam));
      dispatch(onBackFillForm(data.searchParam));
      let advanceState: any = {
        searchDetails: { ...data?.state?.searchDetails, series: [data?.state?.searchDetails?.series] },
        gaString: {
          gaData: setCartDetailsFilter,
          currentPage: booklistCurrentPage,
        },
      };
      dispatch(clearBooklistCurrentPage());
      history.push(AppRoutes.SEARCHRESULTS, advanceState);
    });
  };

  const dateModify = (date: any) => {
    const currentDate = moment(date).tz("Etc/GMT").format(dateFormat);
    return currentDate;
  };

  const dateModifyB = (date: any) => {
    const myStr = date.substr(0, 10);
    const currentDate = moment(myStr)
      .tz("Etc/GMT")
      .format(dateFormat);
    return currentDate;
  };

  const onBookClick = () => {
    if (!isCart) {
      if (allSelected) {
        setCheck(!check);
        clickArea(book?.CatalogItemId);
      } else {
        onBookSelect(book);
      }
    } else {
      return undefined;
    }
  };

  useEffect(() => {
    allSelected ? setCheck(true) : setCheck(false);
  }, [allSelected]);

  useEffect(() => {
    const copyshelve = [...shelves];
    const filteredList = copyshelve?.filter((item: any) => item.IsManual);
    setShelvesData(filteredList);
  }, [shelves]);

  const shelveStatus = (status: any) => {
    if (status) {
      return <span className="shelves-live">LIVE</span>;
    } else {
      return <span className="shelves-draft">DRAFT</span>;
    }
  };

  const addtoShelve = (book: Book, shelve: any) => {
    const payLoadData = {
      ShelfId: shelve?.ShelfId,
      LibraryId: shelve?.LibraryId,
      ShelfName: shelve?.ShelfName,
      Description: shelve?.Description,
      ReaktorShelfId: shelve?.ReaktorShelfId,
      SortOrder: shelve?.SortOrder,
      IsActive: shelve?.IsActive,
      IsManual: shelve?.IsManual,
      Items: [
        {
          CatalogItemId: book?.CatalogItemId,
          Title: book?.Title,
          SubTitle: book?.SubTitle,
          Authors: book?.Authors,
          EISBN: book?.Eisbn,
          Series: book?.Series,
          NumberInSeries: book?.NumberInSeries,
          Narrator: book?.Narrator,
          EditionType: book?.EditionType,
          Imprint: book?.Imprint,
          ContentProviderId: book?.ContentProviderId,
          ContentProviderName: book?.ContentProviderName,
          MediaTypeId: book?.MediaTypeId,
          FileFormatName: book?.FileFormatName,
          PublicationDate: book?.PublicationDate,
          Language: book?.Language,
          StreetDate: book?.StreetDate,
          ImageId: book?.ImageId,
          RetailPrice: book?.RetailPrice,
          AddedToCatalogDate: book?.AddedToCatalogDate,
          ItemState: book?.ItemState,
          NumberOfCopies: book?.NumberOfCopies,
          NumberOfHolds: book?.NumberOfHolds,
          NumberOfLoans: book?.NumberOfLoans,
          NumberOfCurrentLoans: book?.NumberOfCurrentLoans,
          NumberOfWish: book?.NumberOfWish,
          NumberInCarts: book?.NumberInCarts,
          NumberOnOrder: book?.NumberOnOrder,
          NumberOfShelves: book?.NumberOfShelves,
          LastPurchasedDate: book?.LastPurchasedDate,
          Categories: book?.Categories,
          CategoryList: book?.CategoryList,
          AudienceData: book?.AudienceData,
          IsPPU: book?.IsPPU,
          PPUPrice: book?.PPUPrice,
          CheckOutLimit: book?.CheckOutLimit,
          TimeSpanLimit: book?.TimeSpanLimit,
          HoldRatio: book?.HoldRatio,
          LastCheckoutDate: book?.LastCheckoutDate,
          Rank: 0,
          checked: true,
          Quantity: copies[book?.CatalogItemId]
            ? copies[book?.CatalogItemId]
            : 1,
        },
      ],
      SortStrategy: shelve?.SortStrategy,
      CatShelfAllItems: shelve?.CatShelfAllItems,
      IsQuickConnect: shelve?.IsQuickConnect,
      CatShelfCriteria: shelve?.CatShelfCriteria,
      CurrentStatus: shelve?.CurrentStatus,
      ItemsCount: shelve?.ItemsCount,
      CreateDateTime: shelve?.CreateDateTime,
      CreateUserId: shelve?.CreateUserId,
      ChangeDateTime: shelve?.ChangeDateTime,
      ChangeUserId: shelve?.ChangeUserId,
    };
    dispatch(addItemtoIndividualShelve(payLoadData));
    const msg = replaceAll(
      /\{0\}/gi,
      Messages.ADD_TO_CART,
      `${shelve?.ShelfName}`
    );
    showNotification(msg, "loading");
  };

  const shelvesList = React.useMemo(() => (
    <Menu className="header-dropdown">
      {shelvesData.map((data: any, index: number) => (
        <Menu.Item key={index} onClick={() => addtoShelve(book, data)}>
          <Tooltip title={data?.ShelfName} placement="bottom">
            <p className="dropDown-title dropdown-shelves-title shelves-list-ellipse">
              <span>{data.ShelfName}</span>{" "}
              <span className="status-span">
                {shelveStatus(data.IsActive)}
              </span>
            </p>
            <span className="dropDown-content">{data.ItemsCount} titles</span>
          </Tooltip>
        </Menu.Item>
      ))}
    </Menu>
  ), [shelvesData, addtoShelve, book, shelveStatus]);

  const inputElement = document.querySelector(`.cartItemsInput_${bookDetail?.CatalogItemId} .ant-input-number-input`);
  if (inputElement) {
    inputElement.setAttribute("maxLength", "3");
  }


  useEffect(() => {
    if (selectAllCancel) {
      setValue(1);
      setVal(1);
    }
  }, [selectAllCancel]);

  const onValueChange = (bookId: any, value: number) => {  
    if (isCart) {
      var newVal: number = value - globalQuantity + 1;
      // var newVal: number = value - globalQuantity + 1;
      setCartVal(newVal);
      updateAmount({
        ...bookId,
        Quantity: newVal + globalQuantity - 1 > 0 ? newVal + globalQuantity - 1 : 1,
      });
      setBookDetail({ ...bookDetail, Quantity: newVal + globalQuantity - 1 > 0 ? newVal + globalQuantity - 1 : 1 })
      setCopies({
        ...copies,
        [bookId?.CatalogItemId]:
          newVal + globalQuantity - 1 > 0 ? newVal + globalQuantity - 1 : 1,
      });
    } else {
      if (allSelected && selectAllChkBox ) {
        setVal(value - (globalCopie - 1));
      } else {
        setVal(value);
      }
      setCopies({ ...copies, [bookId?.CatalogItemId]: value });
      onCopie({ CatalogItemId: bookId?.CatalogItemId, Quantity: value });
    }
  };

  useEffect(() => {
    if (isCart && selectedItemsIds?.includes(book?.CatalogItemId)) {
      setPressdArrowBtn({ offset: 1, type: '' })
      setChecked(true);
    } else {
      setChecked(false);
      setPressdArrowBtn({ offset: 1, type: '' })
    }
  }, [isCart, selectedItemsIds]);

  useEffect(() => {
    if (pressdArrow?.type) {
      setPressdArrowBtn(pressdArrow)
    }
  }, [pressdArrow])

  useEffect(() => {
    if (checked && isCart) {
      var prevVal = globalQuantity
      var newVal = globalQty
      if (prevVal !== globalQty) {
        if (PressdArrowBtn?.type === 'up') {
          setGlobalQuantity(prevVal + 1)
        }
        if (PressdArrowBtn?.type === 'down') {
          setGlobalQuantity(prevVal - 1)
        }
        if (PressdArrowBtn?.type === '') {
          setGlobalQuantity(prevVal - 0)
        }
      } else {
        setGlobalQuantity(newVal)
      }
    }
  }, [isCart, checked, PressdArrowBtn])

  useEffect(() => {
    if (isCart) {
      var val = cartVal + globalQuantity - 1 > 0
        ? cartVal + globalQuantity - 1
        : 1
      if (val > 0 && val < 1000) {
        setItemVal(val)
      }
    }
  }, [isCart, cartVal, globalQuantity, checked])

  useEffect(() => {
    if (isCart && itemVal && val && itemVal !== val) {
      setIsValChanged(true)
    } else {
      setIsValChanged(false)
    }
  }, [itemVal, val, isCart])

  useEffect(() => {
    if (isCart && notSetMin && checked && PressdArrowBtn?.type && itemVal === 1) {
      setBookDetail({ ...bookDetail, Quantity: 1 })
      setGlobalQuantity(1)
      setCartVal(1)
      updateAmount({
        ...bookDetail,
        Quantity: 1,
      });
    }
  }, [notSetMin, checked, PressdArrowBtn, itemVal, isCart])

  useEffect(() => {
    if (isCart && notSetMax && checked && PressdArrowBtn?.type && itemVal === 999) {
      setBookDetail({ ...bookDetail, Quantity: 999 })
      setGlobalQuantity(1)
      setCartVal(999)
      updateAmount({
        ...bookDetail,
        Quantity: 999,
      });
    }
  }, [notSetMax, checked, PressdArrowBtn, itemVal, isCart])

  useEffect(() => {
   if ( location && formState.loading===false && fetchCartByIdFormState?.loading===false) {
     const element = document.getElementById(`scrollableDiv`);
     element?.scrollTo(0, scrollHeightMain);
   }
   // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [location,formState,fetchCartByIdFormState]);


  return (
    <div className="list" key={book.CatalogItemId}>
      <Row
        gutter={[20, 20]}
        className={`book-details-container ${editSelected ? "selected" : ""}`}
        key={book?.CatalogItemId}
      >
        <Col
          xs={6}
          sm={6}
          md={6}
          lg={4}
          xl={4}
          className="bookHover"
          onClick={onBookClick}
        >
          <div className="book-cover">
            {isCart ? (
              <>
                <div className="bookCoverCheckBox">
                  <Checkbox
                    className={`selected-book checkbox_${book?.CatalogItemId}`}
                    value={book?.CatalogItemId}
                  />
                </div>
              </>
            ) : allSelected ? (
              <>
                <div className="bookCoverCheckBox">
                  <Checkbox
                    className={`selected-book checkbox_${book?.CatalogItemId}`}
                    value={book?.CatalogItemId}
                    onChange={onClickCheckbox}
                  />
                </div>
              </>
            ) : null}

            <img
              src={book.ImageUrl}
              alt=""
              className="cover"
              onError={(e: any) => {
                e.target.src = notFound;
              }}
            />
            <img
              src={getBookLabel(book?.FileFormatName)}
              alt=""
              className="book-label"
            />
          </div>
        </Col>
        <Col xs={18} sm={18} md={18} lg={20} xl={20}>
          <Row className="title-container">
            <Col
              xs={12}
              sm={12}
              md={13}
              lg={18}
              xl={19}
              className="title-cover"
            >
              <span>
                <span className="book-title" onClick={() => onBookSelect(book)}>
                  {book.Title}
                </span>
                {book.ItemState !== "FOR_SALE" ? (
                  <span className="book-state">
                    {book.ItemState === "PRE_SALE"
                      ? "PRE SALE"
                      : book.ItemState}
                  </span>
                ) : null}
              </span>
              {book.SubTitle !== "" ? (
                <div className="book-subTitle">{book.SubTitle}</div>
              ) : null}
              {book?.Series?.length ? (
                <div>
                  <span className="book-details-key">Series: </span>
                  <span
                    className="book-details-value book-series-name"
                    onClick={searchBySeries}
                  >
                    <b>{book?.Series}</b>
                    {book?.NumberInSeries ? (
                      <span className="book-details-value">
                        <b>
                          , #
                          {removeExtraCharacter(
                            typeof book?.NumberInSeries === "string"
                              ? book?.NumberInSeries
                              : JSON.stringify(book?.NumberInSeries)
                          )}
                        </b>
                      </span>
                    ) : null}
                  </span>
                </div>
              ) : null}
            </Col>
            <Col xs={6} sm={6} md={6} lg={4} xl={3}>
              <span className="book-price-final book-price-fix">
                {currency + Number(book.RetailPrice).toFixed(2)}
              </span>
              {book.PPUPrice > 0 ? (
                <div className="book-ppu-price book-price-fix">
                  PPU {currency + Number.parseFloat(book.PPUPrice).toFixed(2)}
                </div>
              ) : null}
            </Col>
            {isCart ? (
              <Col xs={6} sm={6} md={4} lg={2} xl={2}>
                <InputNumber
                  name={book.Title}
                  defaultValue={1}
                  value={
                    isCart
                      ? itemVal
                      : allSelected
                        ? value
                        : val
                  }
                  min={1}
                  max={999}
                  disabled={false}
                  onChange={(value) => onValueChange(bookDetail, value)}
                  id={isCart ? `${book?.CatalogItemId + '_' + book?.CartDetailId}` : book?.CatalogItemId}
                  className={`cartItemsInput_${book?.CatalogItemId} ${isValChanged ? "updateChangeQunatity" : "price-input-notShow"
                    } ${checked
                      ? "purchaseSelected"
                      : ""
                    }`}
                  parser={(value: any) => {
                    // Custom parser to allow only numeric values and limit to three digits
                    const numericValue = value.replace(/[^0-9]/g, '');
                    return parseInt(numericValue.slice(0, 3));
                  }}
                />
              </Col>
            ) :
              <>
                {isCollection ? null : (
                  <Col xs={6} sm={6} md={4} lg={2} xl={2}>
                    <InputNumber
                      name={book.Title}
                      defaultValue={1}
                      maxLength={3}
                      value={
                        isCart
                          ? itemVal
                          : allSelected 
                            ? value
                            : val
                      }
                      min={1}
                      max={999}
                      disabled={false}
                      onChange={(value) => onValueChange(book, value)}
                      id={isCart ? `${book?.CatalogItemId + '_' + book?.CartDetailId}` : book?.CatalogItemId}
                      className={`${checked ? "price-input" : "price-input-notShow"
                        }`}
                    />
                  </Col>
                )}
              </>
            }
          </Row>
          <Row className="title-container 1">
            <Col xs={12} sm={12} md={16} lg={20} xl={20}>
              <span className="book-author-label">
                Author:{" "}
                <b
                  className="book-author book-author-value"
                  onClick={() => handleKeyWord(book)}
                >
                  {book.Authors}
                </b>
              </span>
              {book?.Narrator?.length ? (
                <span className="book-author-label book-narrator">
                  Narrator:{" "}
                  <b
                    className="book-author-value"
                    onClick={() => handleNarratorKeyWord(book)}
                  >
                    {book?.Narrator}
                  </b>
                </span>
              ) : null}
            </Col>
          </Row>
          <Row className="my-collection-title-container ">
            {/* {
            shelfAdmin && addShelves ?  */}
            <Col xs={15} sm={15} md={16} lg={18} xl={19} xxl={20}>
              <Row>
                {book?.Categories?.split(";")?.map(
                  (genreType: any, index: number) => {
                    return (
                      <span className="book-genre" key={index}>
                        {genreType}
                      </span>
                    );
                  }
                )}
              </Row>
            </Col>
            {/* : null } */}

            {shelfAdmin && addShelves ? (
              <Col
                xs={9}
                sm={9}
                md={8}
                lg={6}
                xl={5}
                xxl={4}
                className="add-to-shelf-col-padding"
              >
                <Row className="my-collection-title-container add_toShelves_single_button">
                  {!isCollection && !allSelected && !isCart ? (
                    <div className="add_toCart_button add-toCart-shadow">
                      <Button
                        onClick={() =>
                          onAddtoCartDropdown(book, activeCartArray)
                        }
                        type="add-main-cart-button"
                      >
                        Add to Cart
                      </Button>
                      <Divider
                        type="vertical"
                        className="cart_button_divider"
                      />
                      <Dropdown
                        overlay={userCarts}
                        trigger={["click"]}
                        getPopupContainer={(trigger: any) => trigger.parentNode}
                        placement="bottomRight"
                      >
                        <Button type="cart-tomain-dropdown">
                          <img
                            src={whiteArrow}
                            style={{ paddingLeft: "5px" }}
                            alt=""
                          />
                        </Button>
                      </Dropdown>
                    </div>
                  ) : null}
                  {!allSelected && !isCart ? (
                    <div className="add_toCart_button add_toShelves_button add_shelv_btn">
                      <Button
                        onClick={() => addtoShelve(book, selectedShelve)}
                        type="add-main-cart-button"
                      >
                        Add to Shelf
                      </Button>
                      <Divider
                        type="vertical"
                        className="shelf_button_divider"
                      />
                      <Dropdown
                        overlay={shelvesList}
                        trigger={["click"]}
                        getPopupContainer={(trigger: any) => trigger.parentNode}
                      >
                        <Button type="cart-tomain-dropdown">
                          <img
                            src={downIcon}
                            style={{ paddingLeft: "5px" }}
                            alt=""
                          />
                        </Button>
                      </Dropdown>
                    </div>
                  ) : null}
                </Row>
                <Row></Row>
              </Col>
            ) : (
              <Col xs={9} sm={9} md={8} lg={6} xl={5} xxl={4}>
                <Row>
                  {!isCollection && !allSelected && !isCart ? (
                    <div className="add_toCart_button add-toCart-shadow add_toShelves_single_button">
                      <Button
                        onClick={() =>
                          onAddtoCartDropdown(book, activeCartArray)
                        }
                        type="add-main-cart-button"
                      >
                        Add to Cart
                      </Button>
                      <Divider
                        type="vertical"
                        className="cart_button_divider"
                      />
                      <Dropdown
                        overlay={userCarts}
                        trigger={["click"]}
                        getPopupContainer={(trigger: any) => trigger.parentNode}
                      >
                        <Button type="cart-tomain-dropdown">
                          <img
                            src={whiteArrow}
                            style={{ paddingLeft: "5px" }}
                            alt=""
                          />
                        </Button>
                      </Dropdown>
                    </div>
                  ) : null}
                </Row>
              </Col>
            )}
          </Row>
          <Row>
            <Col
              xs={15}
              sm={15}
              md={24}
              lg={screenSize?.width < 1100 ? 21 : 18}
              xl={19}
              xxl={20}
            >
              <Row>
                <Col xs={12} sm={12} md={5} lg={4} xl={4}>
                  <p className="book-details-key">Language:</p>
                  <p className="book-details-key">Format:</p>
                  <p className="book-details-key">Provider:</p>
                  <p className="book-details-key">Publisher:</p>
                  {book?.EditionType ? (
                    <p className="book-details-key">Edition:</p>
                  ) : null}
                  <p className="book-details-key">Published Date:</p>
                  <p className="book-details-key">Street Date:</p>
                  <p className="book-details-key">Added to CAT:</p>
                  <p className="book-details-key">Model:</p>
                  <p className="book-details-key">ISBN:</p>
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  md={screenSize?.width < 992 ? 5 : 10}
                  lg={
                    screenSize?.width > 1000 && screenSize?.width < 1100 ? 4 : 5
                  }
                  xl={6}
                >
                  <p className="book-details-value">
                    {book?.Language?.toUpperCase()}
                  </p>
                  <p className="book-details-value">{book.FileFormatName}</p>
                  <p className="book-details-value book-ellipsis">
                    <Tooltip placement="topLeft" title={isCart ? book?.ContentProviderName : book.Provider}>
                      {isCart ? book?.ContentProviderName : book.Provider}
                    </Tooltip>
                  </p>
                  <p className="book-details-value book-ellipsis">
                    <Tooltip placement="topLeft" title={isCart ? book?.Imprint : book.Publisher}>
                      {isCart ? book?.Imprint : book.Publisher}
                    </Tooltip>
                  </p>
                  <p className="book-details-value book-ellipsis">
                    {book?.EditionType ? book?.EditionType : null}
                  </p>

                  <p className="book-details-value">
                    {book.PublicationDate
                      ? dateModify(book.PublicationDate)
                      : "N/A"}
                  </p>
                  <p className="book-details-value">
                    {book.StreetDate ? dateModify(book.StreetDate) : "N/A"}
                  </p>
                  <p className="book-details-value">
                    {book.AddedToCatalogDate
                      ? dateModify(book.AddedToCatalogDate)
                      : "N/A"}
                  </p>
                  <p className="book-details-value  book-ellipsis">
                    {lendingModelData(
                      book.ContentProviderId,
                      book.CheckOutLimit,
                      book.TimeSpanLimit,
                      book.FileFormatName
                    )
                      ? lendingModelData(
                        book.ContentProviderId,
                        book.CheckOutLimit,
                        book.TimeSpanLimit,
                        book.FileFormatName
                      )
                      : "Perpetual"}
                  </p>
                  <p className="book-details-value">
                    {isCart ? book?.EISBN : book?.Eisbn}
                  </p>
                </Col>
                <Divider
                  type="vertical"
                  className="books-divider verticalDivider verticalDivider-booklist"
                />
                <Col
                  xs={12}
                  sm={12}
                  md={5}
                  lg={
                    screenSize?.width > 1000 && screenSize?.width < 1199 ? 3 : 4
                  }
                  xl={3}
                >
                  {/* {book?.NumberInSeries ? (
                <span className="book-details-key">Number in series:</span>
              ) : null} */}
                  <p className="book-details-key">Owned:</p>
                  <p className="book-details-key">Holds:</p>
                  <p className="book-details-key">In Circ.:</p>
                  <p className="book-details-key">All Loans:</p>
                  <p className="book-details-key">Suggested:</p>
                  {isCart ? (
                    <>
                      {book?.HoldRatio >= 1 ? (
                        <p className="book-details-key">Holds Ratio:</p>
                      ) : null}
                    </>
                  ) : (
                    <p className="book-details-key">Holds Ratio:</p>
                  )}
                  {appUser?.libraryData?.length > 0
                    ? appUser?.libraryData[0]?.ConsortiumId
                      ? MyGroupTable(
                        book?.CloudLinkGroupName
                          ? book?.CloudLinkGroupName
                          : "",
                        !isNaN(book?.GroupSharableCopies)
                          ? book?.GroupSharableCopies
                          : "",
                        !isNaN(book?.GroupHoldCount)
                          ? book?.GroupHoldCount
                          : ""
                      )
                      : null
                    : null}
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  md={4}
                  lg={
                    screenSize?.width > 1000 && screenSize?.width < 1100 ? 1 : 2
                  }
                  xl={2}
                >
                  <p className="book-details-value">{book.NumberOfCopies}</p>
                  <p className="book-details-value">{book.NumberOfHolds}</p>
                  <p className="book-details-value">
                    {book.NumberOfCurrentLoans}
                  </p>
                  <p className="book-details-value">{book.NumberOfLoans}</p>
                  <p className="book-details-value">{book.NumberOfWish}</p>
                  {isCart ? (
                    <>
                      {book?.HoldRatio >= 1 ? (
                        <p className="book-details-value">{book.HoldRatio}</p>
                      ) : null}
                    </>
                  ) : (
                    <p className="book-details-value">{book.HoldRatio}</p>
                  )}
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  md={5}
                  lg={
                    screenSize?.width > 1000 && screenSize?.width < 1199 ? 4 : 4
                  }
                  xl={4}
                  className="left-margin"
                >
                  <p className="book-details-key">Ordered:</p>
                  <p className="book-details-key">Shelved:</p>
                  <p className="book-details-key">In Cart:</p>
                  <p className="book-details-key">Last Purchased:</p>
                  <p className="book-details-key">Last Circulated:</p>
                  <p className="book-details-key">Audience:</p>
                  {book?.AutobuyReason?.length > 0 && (
                    <p className="book-details-key">Auto Cart:</p>
                  )}
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  md={4}
                  lg={
                    screenSize?.width > 1000 && screenSize?.width < 1100 ? 2 : 3
                  }
                  xl={3}
                  onMouseEnter={fetchInfo}
                >
                  <p className="book-details-value">{book.NumberOnOrder}</p>
                  <p className="book-details-value">{book.NumberOfShelves}</p>
                  <p className="book-details-value">
                    {inCart}
                    {inCart > 0 ? (
                      <Popover
                        content={InfoCarts}
                        trigger={["hover", "click"]}
                        placement="bottomLeft"
                        getPopupContainer={(trigger: any) => trigger.parentNode}
                      >
                        <img className="info-icon" src={infoIcon} alt="info" />
                      </Popover>
                    ) : null}
                  </p>
                  <p className="book-details-value">
                    {book.LastPurchasedDate
                      ? dateModifyB(book.LastPurchasedDate)
                      : "N/A"}
                  </p>
                  <p className="book-details-value">
                    {book.LastCheckoutDate
                      ? dateModify(book.LastCheckoutDate)
                      : "N/A"}
                  </p>
                  <p className="book-details-value book-ellipsis">
                    {book.AudienceData}
                  </p>
                  <p className="book-details-value book-ellipsis">
                    {book?.PublisherAudienceInfo
                      ? "Interest Age" +
                      " " +
                      book?.PublisherAudienceInfo[0]?.MinValue +
                      " " +
                      "-" +
                      " " +
                      book?.PublisherAudienceInfo[0]?.MaxValue
                      : null}
                  </p>
                  <p className="book-details-value no-wrap">
                    {book?.AutobuyReason?.length > 0
                      ? book?.AutobuyReason
                      : null}
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Divider className="books-divider hzDivider" />
      </Row>
      <NewCartModal
        isModalVisible={showModal}
        setModalVisible={setShowModal}
        appUser={appUser}
        isNewCartDetailsNeed={true}
      />
    </div>
  );
};
