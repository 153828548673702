/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// import { userStateSelector } from "../../../../../redux/reducers/user/userReducer";
import { GraphRow } from "./graphRow";
import { insightStateSelector } from "../../../../../redux/reducers/insight/insightsReducer";
import ActivityBar from "./ActivityBar";
// import "../dashboard/insight.less"

export const Dashboard = (props: any) => {
  // const dispatch = useDispatch();
  const { insightData } = useSelector(insightStateSelector);
  // const { appUser } = useSelector(userStateSelector);
  const [collectionData, setCollectionData] = useState<any>([]);
  const [checkoutData, setCheckoutData] = useState<any>([]);
  const [holdData, setHoldData] = useState<any>([]);
  const [actualData, setActualData] = useState<any>([]);
  const [yourPatronHolds, setYourPatronHolds] = useState(0);
  const [cloudLinkPatronHolds, setCloudLinkPatronHolds] = useState(0);
  // var lib_Id =
  //   appUser && appUser.libraryData ? appUser.libraryData[0].LibraryId : 0;
  // var permissions =
  //   appUser && appUser.libraryData
  //     ? appUser.libraryData[0].FeaturePermissions
  //     : "";

  useEffect(() => {
    setActualData(insightData);
  }, [insightData]);

  const getEmptyObject = (itemFormat: string) => {
    return {
      CloudLink: 0,
      ItemFormat: itemFormat,
      Owned: 0,
      PPU: 0
    }
  }

  function findObjectByName(ItemFormat: string) {
    const foundObject = actualData.find((obj: any) => obj?.ItemFormat === ItemFormat);
    return foundObject || getEmptyObject(ItemFormat);
  }

  useEffect(() => {
    setCheckoutData([findObjectByName("EbookCheckouts"), findObjectByName("AudioBookCheckouts")])
    setHoldData([findObjectByName("EbookHolds"), findObjectByName("AudioBookHolds")])
    setCollectionData([findObjectByName("EbookCopiesInCollection"), findObjectByName("AudioBookCopiesInCollection")])
  }, [actualData]);

  const infoMessage = {
    patronHoldsMessage:
      "Number of Holds your patrons have on your ebooks and audiobooks, as well as PPU content if participating in Pay Per Use.",
    cloudLinkHoldsMessage:
      "Number of Holds all CloudLink patrons have on all CloudLink content. This includes your patrons and patrons from other libraries in your CloudLink group. N/A will display if your library is not part of a CloudLink group.",
    yourCollectionCheckouts:
      "Number of checkouts on your owned content by your patrons and patrons from libraries in your CloudLink group.",
    cloudLinkCheckouts:
      "Number of checkouts your patrons have made on content owned by other libraries in the CloudLink group. N/A will display if your library is not part of a CloudLink group.",
    ppuCheckouts:
      "Number of checkouts by your patrons on PPU content. 0 will display if your library does not participate in Pay Per Use.",
    yourCopiesInCollection:
      "Number of copies your library owns in your collection.",
    cloudLinkCopiesInCollection:
      "Number of shareable copies owned by other libraries in your CloudLink group, does not include shareable copies owned by your library. N/A will display if your library is not part of a CloudLink group.",
    ppuCopiesInCollection:
      "Number of Pay Per Use copies currently available in your collection. 0 will display if your library does not participate in Pay Per Use.",
  };

  // useEffect(() => {
  //   setCollectionData(
  //     actualData?.filter((items: any) => {
  //       return (
  //         items?.ItemFormat === "EbookCopiesInCollection" ||
  //         items?.ItemFormat === "AudioBookCopiesInCollection"
  //       );
  //     })
  //   );
  //   setCheckoutData(
  //     actualData?.filter((items: any) => {
  //       return (
  //         items?.ItemFormat === "EbookCheckouts" ||
  //         items?.ItemFormat === "AudioBookCheckouts"
  //       );
  //     })
  //   );
  //   setHoldData(
  //     actualData?.filter((items: any) => {
  //       return (
  //         items?.ItemFormat === "EbookHolds" ||
  //         items?.ItemFormat === "AudioBookHolds"
  //       );
  //     })
  //   );
  // }, [actualData]);

  return (
    <div className="scrollContent scrollStyle graphList graph-row-style">
      <ActivityBar
        yourPatronHolds={yourPatronHolds}
        cloudLinkPatronHolds={cloudLinkPatronHolds}
        infoMessage={infoMessage}
      />
      {/* {checkoutData && checkoutData?.length > 0 ? ( */}
        <GraphRow
          rowData={checkoutData}
          tableType={"Checkout"}
          infoMessage={infoMessage}
        />
      {/* ) : null} */}
      {/* {holdData && holdData?.length > 0 ? ( */}
        <GraphRow
          rowData={holdData}
          tableType={"Hold"}
          setYourPatronHolds={setYourPatronHolds}
          setCloudLinkPatronHolds={setCloudLinkPatronHolds}
          infoMessage={infoMessage}
        />
      {/* ) : null} */}
      {/* {collectionData && collectionData?.length > 0 ? ( */}
        <GraphRow
          rowData={collectionData}
          tableType={"Collection"}
          infoMessage={infoMessage}
        />
      {/* ) : null} */}
    </div>
  );
};
