/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import {
  Row,
  Col,
  Divider,
  Menu,
  Dropdown,
  InputNumber,
  Empty,
  message,
  Checkbox,
} from "antd";
import DropDown from "../../../stateless/common/dropDown";
import Button from "../../../stateless/common/button";
import { useDispatch, useSelector } from "react-redux";
import {
  CartStateSelector,
  clearAdded,
  setTab,
  clearErrorCart,
} from "../../../../../redux/reducers/carts/cartsReducer";
import { userStateSelector } from "../../../../../redux/reducers/user/userReducer";
import { useEffect, useRef, useState } from "react";
import {
  ascIcon,
  descIcon,
  downIcon,
  editIcon,
  dropdownIcon,
  whiteArrow,
  list,
  asteriskIcon,
} from "../../../../images";
import Cart from "../../../../models/cart/cart";
import { useHistory } from "react-router-dom";
import { AppRoutes } from "../../../../router/appRoutes";
import { filterOptions, Page } from "../../../../constants/constants";
import "./savedSearch.less";
import { SavedListModal } from "./savedList";
import {
  fetchSearchList,
  fetchSearchResultsExport,
  fetchSearchResultsNew,
  getSearchString,
  onBackFillForm,
  retainDefailtValue,
  savedSearchFilters,
} from "../../../../../redux/actions/search/searchActions";
import {
  clearResults,
  clearSearchAfterEdit,
  clearState,
  SearchstateSelector,
  setSearch,
  getPreviousPayload,
  setSearchScroll,
  pendingSearchFormState
} from "../../../../../redux/reducers/search/searchReducer";
import SavedSearch from "../../../../models/search/savedSearch";
import SpinnerLoader from "../../../stateless/common/spinnerLoader";
import { BookRow } from "../../curatedList/bookList/bookRowItem";
import { Book } from "../../../../models/book/book";
import { addItemtoCart } from "../../../../../redux/actions/carts/cartAction";
import { NewCartModal } from "../../carts/newCartModal";
import {
  BookStateSelector,
  clearBooklistCurrentPage,
  clearCartsByBookId,
  clearSelectedChkBox,
  emtySelectedChkBox,
} from "../../../../../redux/reducers/books/bookListReducer";
import { Messages } from "../../../../constants/messages";
import { replaceAll } from "../../../../../utility/appUtil";
import PaginationTag from "../../../stateless/common/pagination";
import axios, { CancelTokenSource } from "axios";
import { CSVLink } from "react-csv";
import ExportIcon from "../../../../../assets/dummy/ExportIcon.png";
import moment from "moment-timezone";
import Axios from "axios";
import { pageRememberBooklist,selectAllRemember, setCheckedValues, setSelectAllChkBox ,setSelectedTitles, setGlobalCopy} from "../../../../../redux/actions/books/bookActions";
import { SideBarStateSelector, retainPageSize } from "../../../../../redux/reducers/app/sideBarReducer";
import { checkPayloadAndReform } from "../../../../../utility/searchUtil";
import React from "react";
import { AutoSizer, CellMeasurer, List, CellMeasurerCache } from "react-virtualized";

export const SavedSearchList = (props: any) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { carts, addedItem, activeCartArray, addItemtoCartFormstate, ErrorCart } = useSelector(CartStateSelector);
  const cancelTokenSource = useRef<CancelTokenSource>();
  const {
    savedSearches,
    selectedSearch,
    selectedSearchAfterEdit,
    formState,
    searchFormState,
    searchResults,
    retainValue,
    totalResults,
    savedSelectedFilters,
    scrollHeight,
  } = useSelector(SearchstateSelector);
  const { appUser } = useSelector(userStateSelector);
  const {
    booklistCurrentPage,
    globalSelectAll,
    checkedValues,
    selectAllChkBox,
    bookGlobalValue,
    selectedTitles,
  } = useSelector(BookStateSelector);

  const { showSearchSideBar, collapseSideBar, retainedPageSize } = useSelector(SideBarStateSelector);

  const [checked, setChecked] = useState<any>([]);
  const [checkedPayload, setCheckedPayload] = useState<any>([]);
  const [eptyCheckedArry, setEptyCheckedArry] = useState<boolean>(false);
  const [allSelected, setAllSelected] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [sort, setSort] = useState(
    savedSelectedFilters?.sort !== undefined ? savedSelectedFilters.sort : "asc"
  );
  const [sortIcon, setSortIcon] = useState(
    savedSelectedFilters?.sortIcon !== undefined
      ? savedSelectedFilters.sortIcon
      : ascIcon
  );
  const [sortOrder, setSortOrder] = useState(
    savedSelectedFilters?.sortOrder !== undefined
      ? savedSelectedFilters.sortOrder
      : "Default"
  );
  const [copies, setCopies] = useState<any>(1);
  const [globalCopies, setGlobalCopies] = useState<any>(1);
  const [bookCopies, setBookCopies] = useState<any>(totalResults);
  const [deletDropDefaultVal, setDeletDropDefaultVal] =
    useState<boolean>(false);
  const [ifCancelSearch, setIfCancelSearch] = useState<any>([]);
  const [addToCartNow, setAdToCartNow] = useState<boolean>(false);
  const [cartDescription, setCartDescription] = useState<any>([]);
  const [showModal, setShowModal] = useState(false);
  const [selectSearch, setSelectSearch] = useState<SavedSearch | any>(
    selectedSearch
  );
  const [showNewCartModal, setShowNewCartModal] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [scrollValue, setScrollValue] = useState(0);
  const [unSelectAll, setUnSelectAll] = useState<boolean>(true);
  const csvLinkHit = useRef<any>(null);
  const [downloadingCsv, setDownloadingCsv] = useState<any>(false);
  const [exportIt, setExportIt] = useState<boolean>(false);

  var permissions =
    appUser && appUser.libraryData
      ? appUser.libraryData[0].FeaturePermissions
      : "";

  var filteredCarts = permissions.includes("AudioBooks")
    ? carts.filter((data: Cart) => {
        return (
          data.CartOwner === appUser?.email || data.IsCommunityCart === true
        );
      })
    : carts.filter((data: Cart) => {
        return (
          (data.CartOwner === appUser?.email ||
            data.IsCommunityCart === true) &&
          !data.CartTypeCode?.includes("Audio")
        );
      });

  const [loading, setLoading] = useState(false);
  const [bookList, setBookList] = useState<Book[]>([]);
  const [start, setStart] = useState<number>(
    booklistCurrentPage?.start !== undefined
      ? booklistCurrentPage?.start
      : Page.DEFAULT_START
  );
  const [selectedFormat, setSelectedFormat] = useState(
    savedSelectedFilters?.selectedFormat !== undefined
      ? savedSelectedFilters.selectedFormat
      : "all"
  );
  const node = document.querySelector<HTMLElement>(".saved-results-list");
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [updatingData, setUpdatingData] = useState(false);
  const [noData, setNoData] = useState<boolean>(false);
  const [selectVal, setSelectVal] = useState<any>({
    savedId: '',
  })
  const [currentPage, setCurrentPage] = useState((booklistCurrentPage?.currentPage !== undefined)? booklistCurrentPage?.currentPage : 1)
  const [pageSize, setPageSize] = useState<number>(retainedPageSize ?? 100);
  const [lastCount , setLastCount] = useState(start+pageSize-1)
  const [selectedSearchID, setSelectedSearchID] = useState<any>("")
  const optionList = [10, 20, 50, 100, 250, 500];
  const [paginationHide, setPaginationHide] = useState(false);
  const [cancelAll, setCancelAll] = useState(false);
  const [totalQuantiy, setTotalQuantiy] = useState<any>([]);
  const [globalC, setGlobalC] = useState<number>(1);
  const [csvData, setCsvData] = useState<any>([]);
  const [globalPrevious, setGlobalPrevious] = useState<any>();
  var dateFormat = appUser?.libraryData
    ? appUser.libraryData[0]?.DateFormat.toUpperCase()
    : "";

  const [screenSize, getDimension] = useState<any>({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });

  const cancelToken = axios.CancelToken;
  const source = cancelToken.source();
  cancelTokenSource.current = source;

  const stringifiedArray=(array:any)=>{
    return array?.map((element:any) => JSON?.stringify(element))
  }

  const config: any = {
    key: "downloading",
    type: "loading",
    content: "Exporting CSV...",
    duration: 0,
    className: "custom-ant-message-container",
  };

  const hideLoading = () => message.destroy("downloading");
  const showLoading = () => message.open(config);

  const headers = [
    { label: "Title", key: "refineTitle" },
    { label: "Authors", key: "refineAuther" },
    { label: "Eisbn", key: "Eisbn" },
    { label: "Series", key: "Series" },
    { label: "NumberInSeries", key: "NumberInSeries" },
    { label: "Narrator", key: "Narrator" },
    { label: "Publisher", key: "Publisher" },
    { label: "ContentProviderName", key: "Provider" },
    { label: "FileFormatName", key: "FileFormatName" },
    { label: "PublicationDate", key: "PublicationDate" },
    { label: "Language", key: "Language" },
    { label: "StreetDate", key: "StreetDate" },
    { label: "RetailPrice", key: "RetailPrice" },
    { label: "AddedToCatalogDate", key: "AddedToCatalogDate" },
    { label: "NumberOfCopies", key: "NumberOfCopies" },
    { label: "NumberOfHolds", key: "NumberOfHolds" },
    { label: "NumberOfLoans", key: "NumberOfLoans" },
    { label: "NumberOfCurrentLoans", key: "NumberOfCurrentLoans" },
    { label: "NumberInCarts", key: "NumberInCarts" },
    { label: "NumberOnOrder", key: "NumberOnOrder" },
    { label: "LastPurchasedDate", key: "LastPurchasedDate" },
    { label: "Category", key: "category" },
    { label: "Sub Category", key: "subCategory" },
    { label: "IsPPU", key: "IsPPU" },
    { label: "PPUPrice", key: "PPUPrice" },
    { label: "LastCheckoutDate", key: "LastCheckoutDate" },
    { label: "cloudLink Shareable Copies", key: "GroupSharableCopies" },
    { label: "cloudLink Current Holds", key: "GroupHoldCount" },
  ];

  const csvExportList = {
    filename: `savedSearch.csv`,
    headers: headers,
    data: csvData,
  };

  const containerRef = useRef<any>(null);
  const listRef = useRef<any>(null);
   // Cache to handle dynamic row heights
   const cache = useRef(
    new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 50, // Default height for rows until measured
    })
  ).current;


  const calculateDimensions = () => {
    if (containerRef.current) {
        // Clear cache and recompute row heights when necessary
        cache.clearAll();
        if (listRef.current) {
          listRef.current.recomputeRowHeights();
        }
    }
  };
  useEffect(() => {
    // Calculate dimensions on mount
    calculateDimensions();

    // Add event listener to recalculate on window resize
    window.addEventListener('resize', calculateDimensions);

    return () => {
      // Cleanup the event listener on unmount
      window.removeEventListener('resize', calculateDimensions);
    };
  }, [showSearchSideBar, collapseSideBar, cache, cancelAll]);

  const handleScroll = (event:any) => {
    if (formState.loading === false) {
    if (listRef.current) {
      const scrollTop = event.target.scrollTop;
      listRef.current.scrollToPosition(scrollTop);
    }
  }
  };

  useEffect(() => {
    const syncScroll = () => {
      if (listRef.current && containerRef.current) {
        const listNode = listRef.current.Grid; // Access the internal Grid of List
        listNode.scrollTop = containerRef.current.scrollTop;
      }
    };

    const handleScroll = () => {
      syncScroll();
    };

    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const dateModify = (date: any) => {
    const myStr = date.substr(0, 10);
    const currentDate = moment(myStr).tz("Etc/GMT").format(dateFormat);
    return currentDate;
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);
    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);
  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  const functionForExport = (data: any) => {
    if (data?.error) {
      setDownloadingCsv(false);
      hideLoading();
      setTimeout(() => {
        showNotification(data.error, "error");
      }, 500);
      setExportIt(false);
    } else {
      setCsvData(data);
      setExportIt(true);
    }
  };

  useEffect(() => {
    if (exportIt && csvData) {
      hideLoading();
      setDownloadingCsv(false);
      setTimeout(() => {
        csvLinkHit?.current?.link?.click();
      }, 1000);
      setExportIt(false);
    }
  }, [exportIt, csvLinkHit, csvData]);

  const handleFetchExport = () => {
    let source = Axios.CancelToken.source();
    setDownloadingCsv(true);
    showLoading();

    let payload = {
      ...selectSearch?.searchParams,
      recordCount: 1000,
      sortField: sortOrder,
      sortDirection: sort,
      recordStart: start,
    };
    if (payload?.hasOwnProperty("InCollection")) {
      payload = {
        ...payload,
        InCollection:
          payload?.NotInCollection?.toString() === "true" ? false : null,
      };
    }
    dispatch(
      fetchSearchResultsExport({
        payload: checkPayloadAndReform({ ...payload }),
        signal: source,
        dateModify,
        functionForExport,
      })
    );
  };

  const pageOptions = React.useMemo(() => (
    <Menu className="header-dropdown">
      <Menu.Item key="page-size">
        <Row>
          <Col style={{ width: "20px" }}>
            <img style={{ height: "14px", width: "14px" }} src={list} alt=" " />
          </Col>
          <Col flex="auto" style={{ paddingLeft: "5px" }}>
            Page Size
          </Col>
        </Row>
      </Menu.Item>
      {optionList.map((data: number, index: number) => (
        <Menu.Item key={index} onClick={() => changePageSize(data)}>
          <Row>
            <Col style={{ width: "20px" }}>
              {pageSize === data ? (
                <img
                  style={{ height: "14px", width: "14px" }}
                  src={asteriskIcon}
                  alt="*"
                />
              ) : (
                " "
              )}
            </Col>
            <Col style={{ paddingLeft: "5px" }}>{data}</Col>
          </Row>
        </Menu.Item>
      ))}
      <Menu.Item>
        <Row>
          <Col style={{ width: "20px" }}>
            <img
              style={{ width: "14px", height: "14px", marginTop: "8px" }}
              src={ExportIcon}
              alt=" "
            />
          </Col>
          <Col>
            <div className="exportColListCsv export-list-search">
              <CSVLink
                ref={csvLinkHit}
                className="export-list-link hidden"
                {...csvExportList}
                style={{ visibility: "hidden" }}
              ></CSVLink>
              <button
                className={`search-export-list-link ${
                  downloadingCsv ? "not-allowed" : "cursor-pointer"
                }`}
                onClick={handleFetchExport}
                disabled={downloadingCsv}
              >
                Export List 1000 results as CSV
              </button>
            </div>
          </Col>
        </Row>
      </Menu.Item>
    </Menu>
  ), [
    optionList,
    pageSize,
    // changePageSize,
    csvLinkHit,
    csvExportList,
    downloadingCsv,
    handleFetchExport,
    list,
    asteriskIcon,
    ExportIcon,
  ]);
  

  var currency =
    appUser && appUser.libraryData
      ? appUser.libraryData[0]?.CurrencySymbol
      : "";

  const userCarts = (
    <Menu className="header-dropdown">
      {filteredCarts.map((data: Cart, index: number) => {
        return (
              <Menu.Item key={index} onClick={() => onAddtoCart(data)}>
                <p className="dropDown-title">{data.CartName}</p>
                <span className="dropDown-content">
                  {currency}
                  {data.TotalCartAmount?.toFixed(2)
                    ?.toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                  . {data.NumberOfTitles} Titles
                </span>
              </Menu.Item>
            );
          })}
          <Menu.Divider />
          <Menu.Item
            key="All-Carts-Link"
            className="dropDown-link"
            onClick={() => {
              history.push(AppRoutes.CART);
              dispatch(setTab("2"));
            }}
          >
            <p>All Carts</p>
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item
            key="New-cart-Link"
            className="dropDown-link"
            onClick={() => setShowNewCartModal(true)}
          >
            <p>New Cart</p>
          </Menu.Item>
        </Menu>
      );
      
  useEffect(() => {
    dispatch(clearState());
    dispatch(clearResults());
    if (!(savedSearches && savedSearches.length)) {
      dispatch(fetchSearchList());
    }
    return () => {
      dispatch(clearCartsByBookId());
    };
  }, []);

  const updateAndLoad = () => {
    setDataLoading(true);
    setUpdatingData(true);
  };
  const formatMap: Record<string, number[]> = {
    EPUB: [1],
    PDF: [2],
    MP3: [3],
    all: [],
  };
  const getValue = (selectedFormat: string): number[] => {
    return formatMap[selectedFormat];
  };

  useEffect(() => {
    let source = Axios.CancelToken.source();
    if (selectSearch?.length !== 0 && selectSearch !== null) {
      if (selectedSearchAfterEdit?.SearchSaveId !== undefined) {
        return undefined;
      } else {
        if (
          selectSearch?.searchParams?.LibraryId !== "" &&
          retainValue?.length !== 0
        ) {
          updateAndLoad();
          let payload = {
            ...selectSearch?.searchParams,
            LibraryId:appUser && appUser?.libraryData
            ? appUser?.libraryData[0]?.LibraryId
            : 0,
            recordCount: pageSize,
            sortField: sortOrder,
            sortDirection: sort,
            recordStart: start,
            ItemFormatIdSet: getValue(selectedFormat),
          };
          if (payload?.hasOwnProperty("InCollection")) {
            payload = {
              ...payload,
              InCollection:
                payload?.NotInCollection?.toString() === "true" ? false : null,
            };
          }
          dispatch(
            fetchSearchResultsNew({
              payload: checkPayloadAndReform({
                ...payload,
                ItemFormatIdSet: stringifiedArray(payload?.ItemFormatIdSet),
              }),
              signal: source,
            })
          );
          dispatch(
            getPreviousPayload({
              ...selectSearch?.searchParams,
              recordCount: Page.DEFAULT_COUNT,
              recordStart: start,
            })
          );
        }
      }
    }

    return () => {
      source.cancel();
      setTimeout(() => {
        dispatch(pendingSearchFormState());
      }, 100);
    };
  }, [sortOrder, sort, selectSearch, retainValue, ifCancelSearch]);

  useEffect(() => {
    if (ifCancelSearch?.SearchSaveId !== undefined) {
      updateAndLoad();
      let payload = {
        ...ifCancelSearch?.searchParams,
        LibraryId:appUser && appUser?.libraryData
        ? appUser?.libraryData[0]?.LibraryId
        : 0,
        recordCount: pageSize,
        sortField: sortOrder,
        sortDirection: sort,
        recordStart: start,
      };
      if (payload?.hasOwnProperty("InCollection")) {
        payload = {
          ...payload,
          InCollection:
            payload?.NotInCollection?.toString() === "true" ? false : null,
        };
      }
      dispatch(
        fetchSearchResultsNew({
          payload: checkPayloadAndReform({
            ...payload,
            ItemFormatIdSet: stringifiedArray(payload?.ItemFormatIdSet),
          }),
          signal: source,
        })
      );
      dispatch(
        getPreviousPayload({
          ...ifCancelSearch?.searchParams,
          recordCount: Page.DEFAULT_COUNT,
          recordStart: start,
        })
      );
      setIfCancelSearch([]);
    }
  }, [ifCancelSearch]);

  const getSearchOptions = () => {
    var searchOptions = savedSearches
      ? savedSearches?.map((data: SavedSearch) => {
          return {
            text: data.SearchSaveName,
            value: data.SearchSaveId,
            description: data.SearchSaveDescription,
          };
        })
      : [];
    return searchOptions;
  };

  useEffect(() => {
    let source = Axios.CancelToken.source();
    if (addedItem && !loading) {
      showNotification(Messages.ITEM_ADDED, "success");
      dispatch(setSelectedTitles([]));
      setTotalQuantiy([]);
      setGlobalC(1);
      if (allSelected) {
        updateAndLoad();
        setAllSelected(false);
        if (start === Page.DEFAULT_START) {
          if (selectSearch?.searchParams.sortField !== "") {
            let payload = {
              ...selectSearch?.searchParams,
              LibraryId:appUser && appUser?.libraryData
              ? appUser?.libraryData[0]?.LibraryId
              : 0,
              recordCount: Page.DEFAULT_COUNT,
              recordStart: Page.DEFAULT_START,
            };
            if (payload?.hasOwnProperty("InCollection")) {
              payload = {
                ...payload,
                InCollection:
                  payload?.NotInCollection?.toString() === "true"
                    ? false
                    : null,
              };
            }
            dispatch(
              fetchSearchResultsNew({
                payload: checkPayloadAndReform({
                  ...payload,
                  ItemFormatIdSet: stringifiedArray(payload?.ItemFormatIdSet),
                }),
                signal: source,
              })
            );
            dispatch(
              fetchSearchResultsNew({
                ...payload,
                ItemFormatIdSet: stringifiedArray(payload?.ItemFormatIdSet),
              })
            );
          }
        } else {
          setStart(Page.DEFAULT_START);
        }
      }
      dispatch(clearAdded());
    }
    return () => {
      if (!addedItem) {
        source.cancel();
      }
    };
  }, [addedItem, loading]);

  const onAddtoCart = (cart: Cart) => {
    setAdToCartNow(true);
    setCartDescription(cart);
  };

  useEffect(() => {
    if (addToCartNow && cartDescription && checkedPayload) {
      const booksMap: Map<number, Book> = new Map(
        selectedTitles?.amtChangCopie?.map((b: Book) => [b.CatalogItemId, b])
      );
      let checkedPayloads: any = [];
      if(checkedPayload.length > 0) {
      for (var i: any = 0; i < checkedPayload.length; i++) {
        var inputQuantity = booksMap.get(checkedPayload[i].CatalogItemId);
        checkedPayloads?.push({
          CatalogItemId: checkedPayload[i].CatalogItemId,
          BuyerId: "",
          BuyerCartDetailId: 0,
          Quantity: inputQuantity?.Quantity ?? globalC,
          SaveAction: "S",
        });
      }
    } else if(totalQuantiy.length > 0){
      for (var i: any = 0; i < totalQuantiy.length; i++) {
        var inputQuantity = booksMap.get(totalQuantiy[i].CatalogItemId);
        checkedPayloads?.push({
          CatalogItemId: totalQuantiy[i].CatalogItemId,
          BuyerId: "",
          BuyerCartDetailId: 0,
          Quantity: inputQuantity?.Quantity ?? globalC,
          SaveAction: "S",
        });
      }
    }

      var lib_Id =
        appUser && appUser?.libraryData
          ? appUser?.libraryData[0]?.LibraryId
          : 0;
      var payloadData = {
        IsCommunityCart: cartDescription?.IsCommunityCart,
        LibraryId: lib_Id,
        SaveAction: "S",
        CartDescription: cartDescription?.CartDescription,
        CartId: cartDescription?.CartId,
        CartName: cartDescription?.CartName,
        AssociateShelfId: cartDescription?.AssociateShelfId,
        CartDetail: checkedPayloads,
      };
      setTimeout(() => {
        if (checkedPayloads?.length > 0) {
          dispatch(addItemtoCart(payloadData));
          showNotification(
            `Adding items to ${cartDescription?.CartName}`,
            "loading"
          );
        } else {
          showNotification(`No items are selected`, "error");
        }
      }, 100);
      const msg = replaceAll(
        /\{0\}/gi,
        Messages.ADD_TO_CART,
        `${cartDescription?.CartName}`
      );
      showNotification(msg, "loading");
      setAdToCartNow(false);
      setCartDescription([]);
    }
  }, [checkedPayload, addToCartNow, cartDescription, appUser]);

  useEffect(() => {
    if (ErrorCart !== "" && !loading) {
      dispatch(clearErrorCart());
      dispatch(clearAdded());
    } else {
      return undefined;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ErrorCart, loading]);

  const onConfirm = () => {
    setUnSelectAll(true);
    setCancelAll(false);
    let checkedArray: any = [];
    let checkedPayload: any = [];
    if (checkedValues?.length > 0) {
      checkedValues?.map((item: any) => {
        const index = bookCopies?.find(
          (data: any) => item === data?.CatalogItemId
        );
        checkedArray?.push(item);
        checkedPayload?.push({
          CatalogItemId: item,
          BuyerId: "",
          BuyerCartDetailId: 0,
          Quantity: index?.Quantity
            ? index?.Quantity + (globalCopies - 1)
            : globalCopies,
          SaveAction: "S",
        });
      });
    } else {
      searchResults?.map((item: any) => {
        const index = bookCopies?.find(
          (data: any) => item === data?.CatalogItemId
        );
        checkedArray?.push(item.CatalogItemId);
        checkedPayload?.push({
          CatalogItemId: item.CatalogItemId,
          BuyerId: "",
          BuyerCartDetailId: 0,
          Quantity: index?.Quantity
            ? index?.Quantity + (globalCopies - 1)
            : globalCopies,
          SaveAction: "S",
        });
      });
    }
    setCopies(1);
    setGlobalCopies(1);
    dispatch(setGlobalCopy(1));
    if (eptyCheckedArry) {
      setChecked([]);
      setEptyCheckedArry(false);
    } else {
      setChecked(checkedArray);
    }
    setCheckedPayload(checkedPayload);
    setAllSelected(true);
    dispatch(selectAllRemember(true));
  };

  const handleCheckboxChange = (id: any, isChecked: any) => {
    const newCheckedValues = isChecked == true
    ? [...checkedValues, id]
    : checkedValues.filter((value: any) => value !== id);
    dispatch(setCheckedValues(newCheckedValues));
    setChecked(newCheckedValues);
    setCheckedPayload(
      newCheckedValues?.map((item: any) => {
        const index = bookCopies.find(
          (data: any) => item === data?.CatalogItemId
        );
        return {
          CatalogItemId: item,
          BuyerId: "",
          BuyerCartDetailId: 0,
          Quantity: index?.Quantity
            ? index?.Quantity + (globalCopies - 1)
            : globalCopies,
          SaveAction: "S",
        };
      })
    );
  };

  const onCustomSelect = (checkedValues: any) => {
    // setChecked(checkedValues);
    // setCheckedPayload(
    //   checkedValues?.map((item: any) => {
    //     const index = bookCopies.find(
    //       (data: any) => item === data?.CatalogItemId
    //     );
    //     return {
    //       CatalogItemId: item,
    //       BuyerId: "",
    //       BuyerCartDetailId: 0,
    //       Quantity: index?.Quantity
    //         ? index?.Quantity + (globalCopies - 1)
    //         : globalCopies,
    //       SaveAction: "S",
    //     };
    //   })
    // );
  };

  const onCancel = () => {
    dispatch(clearSelectedChkBox());
    setChecked([]);
    setCheckedPayload([]);
    setCopies(1);
    setGlobalCopies(1);
    dispatch(setGlobalCopy(1));
    setGlobalC(1);
    setAllSelected(false);
    dispatch(selectAllRemember(false));
  };

  const onselectAllCancel = () => {
    onCancel();
    setCancelAll(true);
    dispatch(setSelectedTitles([]));
    setGlobalPrevious(1);
  };

  const onUncheckBox = () => {
    setUnSelectAll(!unSelectAll);
    dispatch(setSelectAllChkBox(!unSelectAll));
  };

  useEffect(() => {
    if (!unSelectAll) {
      setChecked([]);
      setCheckedPayload([]);
    } else {
      let checkedArray: any = [];
      let checkedPayload: any = [];
      if (checkedValues?.length > 0) {
        checkedValues?.map((item: any) => {
          checkedArray?.push(item);
          checkedPayload?.push({
            CatalogItemId: item,
            BuyerId: "",
            BuyerCartDetailId: 0,
            Quantity: copies,
            SaveAction: "S",
          });
        });
      } else {
        searchResults?.map((item: any) => {
          checkedArray?.push(item.CatalogItemId);
          checkedPayload?.push({
            CatalogItemId: item.CatalogItemId,
            BuyerId: "",
            BuyerCartDetailId: 0,
            Quantity: copies,
            SaveAction: "S",
          });
        });
      }
      setChecked(checkedArray);
      setCheckedPayload(checkedPayload);
    }
  }, [unSelectAll]);

  const handleDeselect = (e: any) => {};
  const handleFormatFilter = (value: any) => {
    setStart(Page.DEFAULT_START);
    setCurrentPage(1);
    setDataLoading(true);
    setSelectedFormat(value);
    dispatch(setSearchScroll(0));
    updateAndLoad();
    node?.scroll(0, 0);
  };

  useEffect(() => {
    updateAndLoad();
    let payload = {
      ...selectSearch?.searchParams,
      LibraryId:appUser && appUser?.libraryData
      ? appUser?.libraryData[0]?.LibraryId
      : 0,
      recordCount: pageSize,
      sortField: sortOrder,
      sortDirection: sort,
      recordStart: start,
      ItemFormatIdSet: getValue(selectedFormat),
    };
      dispatch(
        fetchSearchResultsNew({
          payload: checkPayloadAndReform({
            ...payload,
            ItemFormatIdSet: stringifiedArray(payload?.ItemFormatIdSet),
          }),
          signal: source,
        })
    
   
    );
    // setBookList(searchResults)
  }, [selectedFormat]);


  useEffect(() => {
    let end = start + pageSize - 1;
    if (totalResults < end) {
      setLastCount(totalResults);
    } else {
      setLastCount(end);
    }
  }, [start, pageSize, totalResults]);

  const handleSortFilter = (value: any) => {
    dispatch(setSearchScroll(0));
    updateAndLoad();
    setSortOrder(value);
    setSelectSearch((prevState: any) => ({
      searchParams: {
        ...prevState?.searchParams,
        sortField: value ?? "",
        recordStart: start,
      },
    }));
  };
  const showNotification = (content: any, type: any) => {
    if (type === "loading") {
      setLoading(true);
      message
        .loading({
          content: content,
          key: "notificationMessage",
          duration: 4,
        })
        .then(() => {
          setLoading(false);
        });
    } else {
      type === "success"
        ? message.success({
            content: content,
            key: "notificationMessage",
          })
        : message.error({
            content: content,
            key: "error",
          });
    }
  };
  const onSortClick = () => {
    dispatch(setSearchScroll(0));
    updateAndLoad();
    if (sort === "asc") {
      setSort("desc");
      setSortIcon(descIcon);
      setSelectSearch((prevState: any) => ({
        searchParams: {
          ...prevState?.searchParams,
          sortDirection: "desc",
          recordStart: start,
          sortField: sortOrder,
        },
      }));
    } else {
      setSort("asc");
      setSortIcon(ascIcon);
      setSelectSearch((prevState: any) => ({
        searchParams: {
          ...prevState?.searchParams,
          sortDirection: "asc",
          recordStart: start,
          sortField: sortOrder,
        },
      }));
    }
  };
  const onValueChange = (value?: any) => {
    setGlobalPrevious(copies);
    setCopies(value);
    setGlobalC(value);
    if (totalQuantiy?.length || checkedPayload.length) {
      var ttqs = {
        globalCopie: value,
        amtChangCopie: totalQuantiy.length != 0 ? totalQuantiy?.map((item: any) => {
          let ay: any;
          if (checkedValues?.includes(item?.CatalogItemId)) {
            ay = {
              CatalogItemId: item?.CatalogItemId,
              Quantity: item?.Quantity + (value - copies),
            };
          } else {
            ay = {
              CatalogItemId: item?.CatalogItemId,
              Quantity: item?.Quantity,
            };
          }
          return ay;
        }) : checkedPayload?.map((item: any) => {
          let ay: any;
          if (checkedValues?.includes(item?.CatalogItemId)) {
            ay = {
              CatalogItemId: item?.CatalogItemId,
              Quantity: item?.Quantity + (value - copies),
            };
          } else {
            ay = {
              CatalogItemId: item?.CatalogItemId,
              Quantity: item?.Quantity,
            };
          }
          return ay;
        }),
      };
      if (ttqs) {
        dispatch(setSelectedTitles(ttqs));
        setTotalQuantiy(ttqs?.amtChangCopie);
      }
    }
  };
  const handleSearchSelect = (value: any) => {
    dispatch(setSelectAllChkBox(true));
    dispatch(clearSelectedChkBox());
    setGlobalC(1);
    onselectAllCancel()
    setStart(Page.DEFAULT_START);
    setCurrentPage(1);
    dispatch(setSearchScroll(0));
    setDeletDropDefaultVal(false);
    var temp = savedSearches.filter((search: SavedSearch) => {
      return search.SearchSaveId === value;
    });
    updateAndLoad();
    setBookList([]);
    if (selectedSearchAfterEdit?.SearchSaveId !== undefined) {
      if (selectedSearch?.SearchSaveId === undefined) {
        setIfCancelSearch(selectedSearchAfterEdit);
      }
      dispatch(clearSearchAfterEdit());
      setSelectSearch({
        ...temp[0],
        searchParams: {
          ...selectedSearch,
          sortField: sortOrder,
          recordCount: pageSize,
        },
      });
      dispatch(
        setSearch({
          ...temp[0],
          searchParams: {
            ...selectedSearch,
            recordCount: pageSize,
          },
        })
      );
    } else {
      setSelectSearch((prevState: any) => ({
        ...temp[0],
        searchParams: {
          ...temp[0]?.searchParams,
          sortField: sortOrder,
          recordCount: pageSize,
        },
      }));
      dispatch(
        setSearch((prevState: any) => ({
          ...temp[0],
          searchParams: {
            ...temp[0]?.searchParams,
            recordCount: pageSize,
          },
        }))
      );
    }
    setSelectedSearchID(temp[0]?.SearchSaveId);

    setSelectVal({ ...selectVal, savedId: temp[0]?.SearchSaveId });

    dispatch(onBackFillForm({ ...selectVal, savedId: temp[0]?.SearchSaveId }));
    dispatch(getSearchString({ ...selectVal, savedId: temp[0]?.SearchSaveId }));
  };
  useEffect(() => {
    if (formState?.isSuccess) {
      if (selectedSearchAfterEdit?.SearchSaveId !== undefined) {
        handleSearchSelect(selectedSearchAfterEdit?.SearchSaveId);
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  }, [formState, selectedSearchAfterEdit]);

  useEffect(() => {
    if (selectVal?.savedId !== undefined) {
      if (deletDropDefaultVal !== true) {
        dispatch(retainDefailtValue(selectVal?.savedId));
      }
    }
  }, [
    savedSearches,
    selectedSearchAfterEdit,
    selectedSearch,
    selectVal,
    deletDropDefaultVal,
  ]);

  useEffect(() => {
    if (searchFormState.isSuccess) {
      props.setShowLoading(false);
      setUpdatingData(false);
      if (searchResults?.length) {
        setDataLoading(true);
        setNoData(false);
      } else {
        setNoData(true);
        setDataLoading(false);
      }
      dispatch(clearState());
    }
    if (searchFormState.isError) {
      props.setShowLoading(false);
      setNoData(true);
      setUpdatingData(false);
      setDataLoading(false);
      dispatch(clearState());
    }
  }, [searchFormState]);

  useEffect(() => {
    if(selectedFormat){
      setDataLoading(true);
      setBookList(searchResults);
    }
  }, [selectedFormat, searchResults]);

  useEffect(() => {
    !updatingData && setDataLoading(false);
  }, [bookList, updatingData]);

  const changePageSize = (size: number) => {
    dispatch(setSearchScroll(0))
    updateAndLoad()
    setBookList([])
    setPageSize(size)
    dispatch(retainPageSize(size))
    setSelectSearch((prevState: any) => ({
      ...prevState,
      searchParams: {
        ...prevState?.searchParams,
        recordCount: size,
      },
    }));
  };

  useEffect(() => {
    setBookList(searchResults);
    if (allSelected) {
      if (!unSelectAll) {
        if (selectAllChkBox) {
          onConfirm();
        }
      } else {
        onConfirm();
      }
    }
    setBookCopies(searchResults);
  }, [searchResults]);


  const onPageChange = (page: any, pageSize: any) => {
    dispatch(clearSelectedChkBox());
    dispatch(setSearchScroll(0));
    setBookList([]);
    setCopies(1);
    setGlobalCopies(1);
    dispatch(setGlobalCopy(1));
    setGlobalC(1);
    dispatch(setSelectedTitles([]));
    if (!formState.loading) {
      const pageStart = (page - 1) * pageSize + 1;
      setStart(pageStart);
      setSelectSearch((prevState: any) => ({
        ...prevState,
        searchParams: {
          ...prevState?.searchParams,
          recordStart: pageStart,
        },
      }));
      setCurrentPage(page);
    }
    setUnSelectAll(false);
    dispatch(setSelectAllChkBox(false));
    onCancel();
    setCancelAll(true);
    setChecked([]);
    setCheckedPayload([]);
  };

  useEffect(() => {
    const filtersApply = {
      selectedFormat,
      sortOrder,
      pageSize,
      sortIcon,
      sort,
    };
    dispatch(savedSearchFilters(filtersApply));
  }, [pageSize, selectedFormat, sortOrder, sortIcon, sort]);

  useEffect(() => {
    if (dataLoading !== true) {
      const element = document.getElementById("savedScroll");
      element?.scrollTo(0, scrollHeight);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataLoading]);

  // const handleScroll = () => {
  //   if (formState.loading === false) {
  //     const element = document.getElementById("savedScroll");
  //     let x = element?.scrollTop;
  //     if (x !== null && x !== undefined) {
  //       setScrollValue(x);
  //     }
  //   }
  // };

  const clickArea = (id: any) => {};

  useEffect(() => {
    if (bookList?.length < pageSize) {
      setPaginationHide(true);
    } else {
      setPaginationHide(false);
    }
  }, [bookList, pageSize]);

  useEffect(() => {
    const currentPageState = {
      start,
      currentPage,
    };
    dispatch(pageRememberBooklist(currentPageState));
  }, [start, currentPage]);

  useEffect(() => {
    if (allSelected) {
      if (checked?.length === 0) {
        setUnSelectAll(false);
      } else if (checked?.length === pageSize) {
        setUnSelectAll(true);
      }
    }
    dispatch(setCheckedValues(checked));
  }, [checked, allSelected, pageSize]);

  useEffect(() => {
    if (addItemtoCartFormstate?.isError && !loading) {
      dispatch(clearAdded());
    }
    if (addItemtoCartFormstate?.isSuccess && globalSelectAll) {
      setEptyCheckedArry(true);
      dispatch(emtySelectedChkBox());
      onConfirm();
    }
    if (globalSelectAll === true) {
      onConfirm();
    }
  }, [addItemtoCartFormstate, globalSelectAll]);

  useEffect(() => {
    setCopies(bookGlobalValue);
    setGlobalCopies(bookGlobalValue);
  }, [bookGlobalValue]);

  const onItemValChange = (value: any) => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    var newEditCellRows: any = { newEditCellRows };
    let foundItem = totalQuantiy?.find(
      (item: { CatalogItemId: any }) =>
        item?.CatalogItemId === value?.CatalogItemId
    );
    if (foundItem) {
      var res = totalQuantiy.map(
        (obj: { CatalogItemId: number }) =>
          [value].find(
            (o: { CatalogItemId: number }) =>
              o.CatalogItemId === obj.CatalogItemId
          ) || obj
      );
      setTotalQuantiy(res);
    } else {
      var ttq = [...totalQuantiy, value].filter(
        (obj: any) => !(obj && Object.keys(obj)?.length === 0)
      );
      setTotalQuantiy(ttq);
    }

    if (allSelected) {
      if (!checked?.includes(value?.CatalogItemId)) {
        let chkVal: any = [...checked];
        chkVal.push(value?.CatalogItemId);
        setChecked(chkVal);
      }
    }
  };

  useEffect(() => {
    if (totalQuantiy?.length !== 0) {
      var ttqs = {
        globalCopie: copies,
        amtChangCopie: totalQuantiy,
      };
      dispatch(setSelectedTitles(ttqs));
    } else {
      return undefined;
    }
  }, [totalQuantiy]);

  useEffect(() => {
    if (
      selectedTitles?.globalCopie !== undefined &&
      selectedTitles?.globalCopie !== 1
    ) {
      setGlobalC(selectedTitles?.globalCopie);
    }
  }, [selectedTitles]);


useEffect(()=>{
setSelectedFormat(savedSelectedFilters?.selectedFormat !== undefined
  ? savedSelectedFilters.selectedFormat
  : "all")
},[savedSearchFilters,searchResults])

  return (
    <div className="saved-search-list">
      <Row>
        <Col span={8}>
          <DropDown
            className="card-dropdown"
            placeholder="Saved Search"
            bordered={false}
            optionValue={getSearchOptions()}
            value={retainValue}
            defaultValue={retainValue}
            icon={downIcon}
            onChange={handleSearchSelect}
            customDropDown={true}
          />
        </Col>
        <Col span={3} style={{ textAlign: "center" }}>
          <Button type="cancel-search" onClick={() => setShowModal(true)}>
            Manage
          </Button>
        </Col>
        {searchResults && searchResults?.length && bookList?.length ? (
          <Col flex="auto" style={{ marginRight: "40px" }}>
            <div className="editor-Button editorButton-List">
              <Dropdown
                overlay={pageOptions}
                trigger={["click"]}
                getPopupContainer={(trigger: any) => trigger.parentNode}
                disabled={allSelected}
              >
                <button className="button-type editor">
                  <img src={editIcon} className="edit-icon" alt="" /> Editor
                  &nbsp; &nbsp;
                  <img src={downIcon} className="down-icon" alt="" />
                </button>
              </Dropdown>
            </div>
          </Col>
        ) : null}
      </Row>
      <Row
        className="saved-search-header"
        justify="space-between"
        gutter={[20, 20]}
      >
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={allSelected ? 16 : 15}
          xl={allSelected ? 12 : 15}
        >
          <Row>
            <Col className="savedTitleCountStyle">
              {selectedFormat === "all" ? (
                <span className="title-count">
                  {totalResults > 0 ? start : "0"}-{lastCount} of {totalResults}{" "}
                  Titles
                </span>
              ) : (
                <span className="title-count">
                  {bookList?.length} in {totalResults > 0 ? start : "0"}-
                  {lastCount} of {totalResults} Titles
                </span>
              )}
            </Col>
            {/* <Col span={6} className="filters-contatiner">
              <DropDown
                bordered={false}
                className="filters"
                value={owned}
                optionValue={filterOptions.ownership}
                icon={dropdownIcon}
                onChange={handleOwnedFilter}
              />
            </Col> */}
            <Col span={6} className="filters-contatiner">
              <DropDown
                bordered={false}
                value={selectedFormat}
                defaultValue={selectedFormat}
                className="filters"
                optionValue={filterOptions.formats}
                icon={dropdownIcon}
                onChange={handleFormatFilter}
                disabled={searchFormState?.loading==true}
              />
            </Col>
            {/* <Col span={6} className="filters-contatiner">
              <DropDown
                bordered={false}
                className="filters"
                value="Lending Model"
                optionValue={Filters}
                icon={dropdownIcon}
              />
            </Col> */}
            <Col span={6} className="filters-contatiner">
              <DropDown
                defaultValue={
                  savedSelectedFilters?.sortOrder !== undefined
                    ? savedSelectedFilters.sortOrder
                    : "Default"
                }
                value={sortOrder}
                bordered={false}
                className="filters"
                optionValue={filterOptions.sorters}
                icon={dropdownIcon}
                onChange={handleSortFilter}
                showSort={true}
                sortIcon={sortIcon}
                onSortClick={onSortClick}
                childwidth={150}
              />
            </Col>
          </Row>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={allSelected ? 18 : 9}
          xl={allSelected ? 12 : 9}
        >
          {allSelected ? (
            <Row>
              {/* <Col xs={2} sm={1} md={1} lg={1} xl={1}>
                <Checkbox 
                className="unSelectAllCheckbox"
                checked={unSelectAll}
                onChange={onUncheckBox}
                >                
                </Checkbox>
                </Col>
              <Col xs={10} sm={9} md={7} lg={7} xl={8} push={1}>
                <span>Copies : </span>
                <InputNumber
                  defaultValue={1}
                  min={1}
                  onChange={onValueChange}
                />
              </Col> */}
              <Col xs={7} sm={7} md={6} lg={8} xl={8}>
                <div
                  className={`add_toCart_button ${
                    checkedPayload.length > 0 || totalQuantiy.length > 0 ? "" : "disable-on-unselectall"
                  } `}
                >
                  <Button
                    onClick={() => onAddtoCart(activeCartArray)}
                    type={`add-main-cart-button ${
                      checkedPayload.length > 0 || totalQuantiy.length > 0 ? "" : "disable-on-unselectall"
                    }`}
                    disabled={!(checkedPayload.length > 0 || totalQuantiy.length > 0)}
                  >
                    Add to Cart
                  </Button>
                  <Divider type="vertical" className="cart_button_divider" />
                  <Dropdown
                    overlay={userCarts}
                    className={`${
                      checkedPayload.length > 0 || totalQuantiy.length > 0 ? "" : "disable-on-unselectall"
                    }`}
                    disabled={!(checkedPayload.length > 0 || totalQuantiy.length > 0)}
                    trigger={["click"]}
                    getPopupContainer={(trigger: any) => trigger.parentNode}
                    placement={"bottomRight"}
                  >
                    <Button
                      type={`cart-tomain-dropdown ${
                        checkedPayload.length > 0 || totalQuantiy.length > 0
                          ? ""
                          : "disable-on-unselectall"
                      }`}
                    >
                      <img
                        src={whiteArrow}
                        style={{ paddingLeft: "5px" }}
                        alt=""
                      />
                    </Button>
                  </Dropdown>
                </div>
              </Col>
              <Col xs={7} sm={7} md={6} lg={5} xl={7} className="cancel">
                <Button type="secondary" onClick={onselectAllCancel}>
                  Cancel
                </Button>
              </Col>
            </Row>
          ) : (
            <Row justify={!allSelected ? "end" : "start"}>
              <Col
                xs={12}
                sm={12}
                md={8}
                lg={10}
                xl={10}
                className="select-all"
              >
                <Button
                  disabled={!bookList?.length}
                  type="secondary"
                  onClick={onConfirm}
                >
                  Select All
                </Button>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
      <Divider className="books-divider" />
        <SpinnerLoader loading={searchFormState?.loading}>

      
      <div
        id="savedScroll"
        onScroll={handleScroll}
        ref={containerRef}
        className={`scrollContent scrollStyle saved-results-list scrollOnBooksScreen ${
          paginationHide ? "" : ""
        }`}
        style={
          screenSize?.dynamicHeight
            ? { overflowY: 'auto', height: screenSize?.dynamicHeight - 280 }
            : {}
        }
      >
        {totalResults > lastCount - start + 1 &&
        bookList &&
        bookList?.length ? (
          <>
            {allSelected ? (
              <>
                <Row>
                  <Col span={9} className="selectAllColWrapper">
                    <div className="selectAllWrapper">
                      <Checkbox
                        className="unSelectAllCheckbox"
                        checked={selectAllChkBox}
                        onChange={onUncheckBox}
                      ></Checkbox>
                    </div>
                    <div className="selectAllWrapper">
                      {/* <span>Copies : </span> */}
                      <InputNumber
                        defaultValue={1}
                        min={1}
                        max={999}
                        onChange={onValueChange}
                      />
                    </div>
                  </Col>
                  <PaginationTag
                    total={totalResults}
                    current={currentPage}
                    defaultPageSize={pageSize}
                    onChange={onPageChange}
                  />
                  <Divider className="search-divider" />
                </Row>
              </>
            ) : (
              <>
                <PaginationTag
                  total={totalResults}
                  current={currentPage}
                  defaultPageSize={pageSize}
                  onChange={onPageChange}
                />
                <Divider className="search-divider" />
              </>
            )}
          </>
        ) : allSelected ? (
          <>
            <Row>
              <Col span={9} className="selectAllColWrapper">
                <div className="selectAllWrapper">
                  <Checkbox
                    className="unSelectAllCheckbox"
                    checked={selectAllChkBox}
                    onChange={onUncheckBox}
                  ></Checkbox>
                </div>
                <div className="selectAllWrapper">
                  <InputNumber
                    defaultValue={1}
                    min={1}
                    max={999}
                    value={globalC}
                    onChange={onValueChange}
                    disabled={!unSelectAll}
                  />
                </div>
              </Col>
              <Divider className="search-divider savedSearchDivider" />
            </Row>
          </>
        ) : null}
        {/* <SpinnerLoader loading={formState?.loading}> */}
        {bookList && bookList?.length && bookList?.length ? (
          <Checkbox.Group
            style={{ width: "100%" }}
            value={checkedValues}
            onChange={(checkedValues: any) => {
              onCustomSelect(checkedValues);
            }}
          >
            {/* Virtualized List */}
                {/* AutoSizer and List from react-virtualized */}
        <AutoSizer disableHeight>
          {({ width }: any) => (
      <List
        ref={listRef}
        height={containerRef.current ? containerRef.current.clientHeight : 0} // Match the height of the scrollable div
        rowCount={bookList.length} // Total number of rows
        rowHeight={cache.rowHeight} // Dynamic row height based on content
        width={width} // Full width of the container
        deferredMeasurementCache={cache} // Cache for measuring rows
        rowRenderer={({ index, key, parent, style }:any) => (
          <CellMeasurer
            key={key}
            cache={cache}
            parent={parent}
            columnIndex={0}
            rowIndex={index}
          >
            {({ measure }) => (
              <div style={style} onLoad={measure} 
              // onScroll={() => {
              //   cache.clear(index, 0);
              //   measure();
              // }}
              >
                <BookRow
                  book={bookList[index]}
                  isCart={false}
                  allSelected={allSelected}
                  isAddTocartEnable={true}
                  editSelected={false}
                  filteredCarts={filteredCarts}
                  appUser={appUser}
                  showNotification={showNotification}
                  handleCheckbox={handleDeselect}
                  isCollection={false}
                  scrollValue={scrollValue}
                  clickArea={clickArea}
                  onSelectAll={onConfirm}
                  onCopie={onItemValChange}
                  globalCopie={copies}
                  globalPrevious={globalPrevious}
                  selectAllCancel={cancelAll}
                  globalC={globalC}
                  initialChecked={checkedValues.includes(bookList[index].CatalogItemId)}
                  onCheckboxChange={handleCheckboxChange}
                />
              </div>
            )}
          </CellMeasurer>
        )} // Function to render each row
        overscanRowCount={10} // Render extra rows for smoother scrolling
        style={{
          overflow: 'hidden', // Explicitly hide overflow to prevent scrollbars
          // pointerEvents: 'none'
        }}
        autoHeight // Automatically adjusts the height based on the content
        // scrollTop={containerRef.current ? containerRef.current.scrollTop : 0} // Sync with outer div scroll
      />)}
      </AutoSizer>
          </Checkbox.Group>
        ) : (
          !searchFormState?.loading &&
          !props.showLoading &&
          !dataLoading &&
          !bookList?.length &&
          noData && (
            <Empty
              style={{ marginTop: "10%" }}
              description="No results found."
            />
          )
        )}
        {totalResults > lastCount - start + 1 &&
        bookList &&
        bookList?.length ? (
          <>
            <PaginationTag
              total={totalResults}
              current={currentPage}
              defaultPageSize={pageSize}
              onChange={onPageChange}
            />
            <Col>
              {selectedFormat === "all" ? (
                <span className="savedTitleStyle">
                  {totalResults > 0 ? start : "0"}-{lastCount} of {totalResults}{" "}
                  Titles
                </span>
              ) : (
                <span className="savedTitleStyle">
                  {bookList?.length} in {totalResults > 0 ? start : "0"}-
                  {lastCount} of {totalResults} Titles
                </span>
              )}
            </Col>
          </>
        ) : null}
        <div className="spinner-container">
          <SpinnerLoader
            loading={
              searchFormState?.loading || props.showLoading || dataLoading
            }
          />
        </div>
        {/* </SpinnerLoader>     */}
      </div>
</SpinnerLoader>
      <NewCartModal
        isModalVisible={showNewCartModal}
        setModalVisible={setShowNewCartModal}
        appUser={appUser}
        isNewCartDetailsNeed={true}
      />
      <SavedListModal
        isModalVisible={showModal}
        setModalVisible={setShowModal}
        searchList={savedSearches}
        selectedSearchID={selectedSearchID}
        setDropDefaultVal={setDeletDropDefaultVal}
      />
    </div>
  );
};
