import { createSlice } from "@reduxjs/toolkit";
import {
  fulfilledState,
  rejectedState,
  pendingState,
  resetState,
  errorState,
} from "../../../utility/stateUtil";
import {
  addItemtoCart,
  fetchCarts,
  deleteCartbyId,
  createNewCart,
  fetchCartById,
  fetchAutoCarts,
  saveAutoCart,
  addNewRuleAutoCart,
  updatedNewCart,
  markReady,
  activeCart,
  cartPermission,
  saveSelectedCartIDs,
  fetchSelectedCartById,
  savedColumns,
  resizeColumns,
  selectedCols,
  fetchBuyerCart,
  fetchSelectedViwerCart,
  saveSelectedCatlogIDs,
  resetAutoCarts,
  getAutoCArtHistory,
  onApplyingFilters,
  recentTab,
  resizeHistoryCols,
  cartListFilter,
  cartDetailsFilterRemember,
  moveItemtoCart,
  cancelSelectedCartItems,
  checkSelectedCartItems,
  setRefreshStatus,
  fetchBuyerCartFirst,
  setBuyerDetails,
  fetchCartsOnLandingPage,
  onlyCreateNewCart,
  fetchBuyerCartById,
  addFeaturedListToCart,
  pageRememberCart
} from "../../actions/carts/cartAction";
import { CartState } from "../../states/carts/cartState";
import { RootState } from "../../store/rootReducer";

const initialState: CartState = {
  carts: [],
  buyerCart:[],
  oldBuyerCartItems:[],
  buyerImgUrl:"",
  buyerCartF:[],
  buyerImgUrlF:"",
  autoByRuleError:"",
  viewerData:[],
  addedItem: false,
  refreshData:false,
  cartDeleted: false,
  cartCreated: false,
  cartUpdated: false,
  authDenied:false,
  cartDetalis: [],
  ruleSaved: false,
  cartReady: false,
  buyCartDetails: null,
  selectedCart: null,
  formState: resetState(),
  autoCartsData:[],
  selectedTab: 1,
  imageUrl: "",
  activeCartArray:'',
  cartCommunity:'',
  selectedCartIds:[],
  selectedCatlogIds:[],
  savedCols:[],
  resizeCols:[],
  selectedColums:[],
  addItemtoCartFormstate: resetState(),
  cancelItemtoCartFormstate: resetState(),
  checkItemtoCartFormstate: resetState(),
  createCartFormstate: resetState(),
  fetchCartByIdFormState: resetState(),
  saveAutoCartFormState: resetState(),
  fetchAutoCartFormState: resetState(),
  resetformState: resetState(),
  buyerCartByIdformState: resetState(),
  buyerCartById:[],
  ErrorCart:"",
  historyData:[],
  historyCount:0,
  historyFormState: resetState(),
  filters: {},
  defaultTab:"1",
  historyColState:[],
  cartFilters: {},
  scrollHeight:0,
  scrollHeightMain:0,
  audioScrollHeight:0,
  ebookScrollHeight:0,
  moveTitleFormState: resetState(),
  cartsList: [],
  setCartDetailsFilter:{},
  cartCurrentPage:{},
  cartCurrentPageFormState:resetState(),
};

export const CartSlice = createSlice({
  name: "carts",
  initialState: initialState,
  reducers: {
    clearState: (state) => {
      state.formState = resetState();
      state.fetchCartByIdFormState= resetState()
      state.carts = [];
      state.oldBuyerCartItems = [];
      state.autoByRuleError = "";  
      state.refreshData = false;
      state.authDenied = false;
      state.cartsList = [];
      state.viewerData = [];
      return state;
    },
    clearAdded: (state) => {
      state.addedItem = false;
      state.addItemtoCartFormstate = resetState();
    },
    clearSessionChk: (state) => {
      state.authDenied = false;
    },
    clearSesionRemain: (state) => {
      state.authDenied = false;
    },
    clearViewData: (state) => {
      state.viewerData = [];
    },
    clearCancel: (state) => {
      state.cancelItemtoCartFormstate = resetState();
    },
    clearCheck: (state) => {
      state.checkItemtoCartFormstate = resetState();
    },
    clearRefreshData: (state) => {
      state.refreshData = false;
    },
    clearOldBuyerCartItems: (state) => {
      state.oldBuyerCartItems = [];
    },
    clearBuyerCart: (state) => {
      state.buyerCart = [];
      state.buyerImgUrl = "";
    },
    clearBuyerCartF: (state) => {
      state.buyerCartF = [];
      state.buyerImgUrlF = "";
    },
    clearErrorCart: (state) => {
      state.ErrorCart = ""
    },
    clearDeleted: (state) => {
      state.cartDeleted = false;
    },
    clearCreated: (state) => {
      state.cartCreated = false;
      state.cartUpdated = false;
      state.createCartFormstate = resetState();
    },
    clearSaved: (state) => {
      state.ruleSaved = false;
    },
    setTab: (state,{ payload}) => {
      state.selectedTab = payload;
      return state;
    },
    setScroll: (state,{ payload}) => {
      state.scrollHeight = payload;
      return state;
    },
  setMainScrollHeight:(state,{payload})=>{
    state.scrollHeightMain=payload;
    return state;
  },
    setAudioBookScroll: (state,{ payload}) => {
      state.audioScrollHeight = payload;
      return state;
    },
    setEbookScrollScroll: (state,{ payload}) => {
      state.ebookScrollHeight = payload;
      return state;
    },
    clearReady: (state) => {
      state.cartReady = false;
    },
    clearCartDetails: (state) => {
      state.cartDetalis = [];
    },
    clearSelectedCart: (state) => {
      state.selectedCart = null
      return state
    },
    clearAutoCartData: (state) => {
      state.autoCartsData = []
      return state 
    },
    clearSelectedCartIds: (state) => {
      state.selectedCartIds = []
      state.selectedCatlogIds = []
      return state 
    },
    clearColumns: (state) => {
      state.savedCols = []
      return state
    },
    clearResizeColumns: (state) => {
      state.resizeCols = []
      return state
    },
    clearselectedCols: (state) => {
      state.selectedColums = []
      return state
    },
    clearHistory: (state) => {
      state.historyData = []
      state.historyCount = 0
      return state
    },
    clearHistoryFormState: (state) => {
      state.historyFormState = resetState()
      return state
    },
    clearCartFilters: (state) => {
      state.cartFilters = {}
    },
    clearMoveFormState: (state) => {
      state.moveTitleFormState = resetState()
      return state
    },
    clearAutoByRuleError: (state) => {
      state.autoByRuleError = "";
      return state
    },
    setCartReady: (state) => {
      state.cartReady = true;
      return state
    },
    clearCartDetailFilter: (state) => {
      state.setCartDetailsFilter = {}
      return state;
    },
    clearCartCurrentPage:(state)=>{
      state.cartCurrentPage={start:1,currentPage:1}
      return state
    }
  },
  extraReducers: {
    [fetchCartsOnLandingPage.fulfilled.toString()]: (state, { payload }) => {
      if (payload.errorMsg) {
        state.formState = errorState(payload.errorMsg);
      } else {
        state.formState = fulfilledState();
        state.carts = payload;
        state.cartsList = payload;
      }
      return state;
    },
    [fetchCartsOnLandingPage.pending.toString()]: (state) => {
      state.formState = pendingState();
      return state;
    },
    [fetchCartsOnLandingPage.rejected.toString()]: (state, { payload }) => {
      state.authDenied = payload?.authDenied;
      state.formState = rejectedState();
      return state;
    },

    [fetchCarts.fulfilled.toString()]: (state, { payload }) => {
      if (payload.errorMsg) {
        state.formState = errorState(payload.errorMsg);
      } else {
        state.formState = fulfilledState();
        state.carts = payload;
        state.cartsList = payload;
      }
      return state;
    },
    [fetchCarts.pending.toString()]: (state) => {
      state.formState = pendingState();
      return state;
    },
    [fetchCarts.rejected.toString()]: (state, { payload }) => {
      state.authDenied = payload?.authDenied;
      state.formState = rejectedState();
      return state;
    },

    [fetchBuyerCart.fulfilled.toString()]: (state, { payload }) => {
      if (payload.errorMsg) {
        state.formState = errorState(payload.errorMsg);
      } else {
        state.formState = fulfilledState();
        state.buyerCart = payload?.data;
        state.buyerImgUrl = payload?.image_url
      }
      return state;
    },
    [fetchBuyerCart.pending.toString()]: (state) => {
      state.formState = pendingState();
      return state;
    },
    [fetchBuyerCart.rejected.toString()]: (state, { payload }) => {
      state.formState = rejectedState();
      return state;
    },


    [fetchBuyerCartById.fulfilled.toString()]: (state, { payload }) => {
      if (payload.errorMsg) {
        state.buyerCartByIdformState = errorState(payload.errorMsg);
      } else {
        state.buyerCartByIdformState = fulfilledState();
        state.buyerCartById = payload?.data;
      }
      return state;
    },
    [fetchBuyerCartById.pending.toString()]: (state) => {
      state.buyerCartByIdformState = pendingState();
      return state;
    },
    [fetchBuyerCartById.rejected.toString()]: (state, { payload }) => {
      state.buyerCartByIdformState = rejectedState();
      return state;
    },

    [fetchBuyerCartFirst.fulfilled.toString()]: (state, { payload }) => {
      if (payload.errorMsg) {
        state.formState = errorState(payload.errorMsg);
      } else {
        state.formState = fulfilledState();
        state.buyerCartF = payload?.data;
        state.buyerImgUrlF = payload?.image_url
      }
      return state;
    },
    [fetchBuyerCartFirst.pending.toString()]: (state) => {
      state.formState = pendingState();
      return state;
    },
    [fetchBuyerCartFirst.rejected.toString()]: (state, { payload }) => {
      state.formState = rejectedState();
      return state;
    },
    
    [addItemtoCart.fulfilled.toString()]: (state, { payload }) => {
      if (payload.errorMsg) {
        state.addItemtoCartFormstate = errorState(payload.errorMsg);
      } else if (payload.ErrorMessage) {
        state.ErrorCart = (payload.ErrorMessage);
        state.addItemtoCartFormstate = fulfilledState();
      }
      else {
        state.addedItem = true;
        state.ErrorCart = ""
        state.addItemtoCartFormstate = fulfilledState();
      }
      return state;
    },
    [addItemtoCart.pending.toString()]: (state) => {
      state.addItemtoCartFormstate = pendingState();
      return state;
    },
    [addItemtoCart.rejected.toString()]: (state, { payload }) => {
      state.addItemtoCartFormstate = rejectedState();
      return state;
    },

    [fetchSelectedViwerCart.fulfilled.toString()]: (state, { payload }) => {
      if (payload.errorMsg) {
        state.addItemtoCartFormstate = errorState(payload.errorMsg);
      } else {
        state.addedItem = true;
        state.viewerData = payload
        state.addItemtoCartFormstate = fulfilledState();
      }
      return state;
    },
    [fetchSelectedViwerCart.pending.toString()]: (state) => {
      state.addItemtoCartFormstate = pendingState();
      return state;
    },
    [fetchSelectedViwerCart.rejected.toString()]: (state, { payload }) => {
      state.addItemtoCartFormstate = rejectedState();
      return state;
    },

    [cancelSelectedCartItems.fulfilled.toString()]: (state, { payload }) => {
      if (payload.errorMsg) {
        state.cancelItemtoCartFormstate = errorState(payload.errorMsg);
      } else {
        state.cancelItemtoCartFormstate = fulfilledState();
      }
      return state;
    },
    [cancelSelectedCartItems.pending.toString()]: (state) => {
      state.cancelItemtoCartFormstate = pendingState();
      return state;
    },
    [cancelSelectedCartItems.rejected.toString()]: (state, { payload }) => {
      state.cancelItemtoCartFormstate = rejectedState();
      return state;
    },

    [setRefreshStatus.fulfilled.toString()]: (state, { payload }) => {
      state.refreshData = payload
      return state;
    },

    [checkSelectedCartItems.fulfilled.toString()]: (state, { payload }) => {
      if (payload.errorMsg) {
        state.checkItemtoCartFormstate = errorState(payload.errorMsg);
      } else {
        state.checkItemtoCartFormstate = fulfilledState();
      }
      return state;
    },
    [checkSelectedCartItems.pending.toString()]: (state) => {
      state.checkItemtoCartFormstate = pendingState();
      return state;
    },
    [checkSelectedCartItems.rejected.toString()]: (state, { payload }) => {
      state.checkItemtoCartFormstate = rejectedState();
      return state;
    },

    [deleteCartbyId.fulfilled.toString()]: (state, { payload }) => {
      if (payload.errorMsg) {
        state.formState = errorState(payload.errorMsg);
      } else {
        state.cartDeleted = true;
        state.formState = fulfilledState();
      }
      return state;
    },
    [deleteCartbyId.pending.toString()]: (state) => {
      state.formState = pendingState();
      return state;
    },
    [deleteCartbyId.rejected.toString()]: (state, { payload }) => {
      state.formState = rejectedState();
      return state;
    },
    [createNewCart.fulfilled.toString()]: (state, { payload }) => {
      if (payload.errorMsg) {
        state.createCartFormstate = errorState(payload.errorMsg);
      } else {
        state.cartCreated = true;
        state.cartUpdated = false;
        state.cartDetalis = payload
        state.createCartFormstate = fulfilledState();
      }
      return state;
    },
    [createNewCart.pending.toString()]: (state) => {
      state.createCartFormstate = pendingState();
      return state;
    },
    [createNewCart.rejected.toString()]: (state, { payload }) => {
      state.createCartFormstate = errorState(payload?.CartName);
      return state;
    },
    [onlyCreateNewCart.fulfilled.toString()]: (state, { payload }) => {
      if (payload.errorMsg) {
        state.createCartFormstate = errorState(payload.errorMsg);
      } else {
        state.cartCreated = true;
        state.cartUpdated = false;
        state.createCartFormstate = fulfilledState();
      }
      return state;
    },
    [onlyCreateNewCart.pending.toString()]: (state) => {
      state.createCartFormstate = pendingState();
      return state;
    },
    [onlyCreateNewCart.rejected.toString()]: (state, { payload }) => {
      state.createCartFormstate = errorState(payload?.CartName);
      return state;
    },
    [updatedNewCart.fulfilled.toString()]: (state, { payload }) => {
      if (payload.errorMsg) {
        state.createCartFormstate = errorState(payload.errorMsg);
      } else {
        state.cartUpdated = true;
        state.cartCreated = false;
        state.cartDetalis = payload
        state.createCartFormstate = fulfilledState();
      }
      return state;
    },
    [updatedNewCart.pending.toString()]: (state) => {
      state.createCartFormstate = pendingState();
      return state;
    },
    [updatedNewCart.rejected.toString()]: (state, { payload }) => {
      state.createCartFormstate = errorState(payload?.CartName);
      return state;
    },
    [fetchCartById.fulfilled.toString()]: (state, { payload }) => {
      if (payload.errorMsg) {
        state.formState = errorState(payload.errorMsg);
        state.fetchCartByIdFormState = errorState(payload.errorMsg);
      } else {
        state.selectedCart = payload.cartDetails;
        state.imageUrl = payload.imageUrl;
        state.formState = fulfilledState();
        state.fetchCartByIdFormState = fulfilledState();
      }
      return state;
    },
    [fetchCartById.pending.toString()]: (state) => {
      state.formState = pendingState();
      state.fetchCartByIdFormState = pendingState();
      return state;
    },
    [fetchCartById.rejected.toString()]: (state, { payload }) => {
      state.formState = rejectedState();
      state.fetchCartByIdFormState = rejectedState();
      return state;
    },
    [fetchAutoCarts.fulfilled.toString()]: (state, { payload }) => {
      if (payload.errorMsg) {
        state.formState = errorState(payload.errorMsg);
      } else {
        state.fetchAutoCartFormState = fulfilledState();
        state.formState = fulfilledState();
        state.autoCartsData = payload;
      }
      return state;
    },
    [fetchAutoCarts.pending.toString()]: (state) => {
      state.fetchAutoCartFormState = pendingState();
      state.formState = pendingState();
      return state;
    },
    [fetchAutoCarts.rejected.toString()]: (state, { payload }) => {
      state.autoByRuleError = payload;
      state.fetchAutoCartFormState = rejectedState();
      state.formState = rejectedState();
      return state;
    },
    [resetAutoCarts.fulfilled.toString()]: (state, { payload }) => {
      state.autoCartsData = payload;
      state.resetformState = fulfilledState();
      return state;
    },
    [resetAutoCarts.pending.toString()]: (state) => {
      state.resetformState = pendingState();
      return state;
    },
    [resetAutoCarts.rejected.toString()]: (state, { payload }) => {
      state.resetformState = rejectedState();
      return state;
    },
    [addNewRuleAutoCart.fulfilled.toString()]: (state, { payload }) => {
      state.formState = fulfilledState();
      state.autoCartsData = payload;
      return state;
    },
    [addNewRuleAutoCart.pending.toString()]: (state) => {
      state.formState = pendingState();
      return state;
    },
    [addNewRuleAutoCart.rejected.toString()]: (state, { payload }) => {
      state.formState = rejectedState();
      return state;
    },
    [saveAutoCart.fulfilled.toString()]: (state, { payload }) => {
      if (payload.errorMsg) {
        state.formState = errorState(payload.errorMsg);
      } else {
        state.formState = fulfilledState();
        state.saveAutoCartFormState = fulfilledState();
        state.ruleSaved= true;
      }
      return state;
    },
    [saveAutoCart.pending.toString()]: (state) => {
      state.formState = pendingState();
      state.saveAutoCartFormState = pendingState();
      return state;
    },
    [saveAutoCart.rejected.toString()]: (state, { payload }) => {
      state.formState = rejectedState();
      state.saveAutoCartFormState = rejectedState();
      return state;
    },
    [markReady.fulfilled.toString()]: (state, { payload }) => {
      if (payload.errorMsg) {
        state.formState = errorState(payload.errorMsg);
      } else {
        state.cartReady =true;
        state.buyCartDetails = payload;
        state.formState = fulfilledState();
      }
    },
    [markReady.pending.toString()]: (state) => {
      state.formState = pendingState();
      return state;
    },
    [markReady.rejected.toString()]: (state, { payload }) => {
      state.formState = rejectedState();
      return state;
    },
    [fetchSelectedCartById.fulfilled.toString()]: (state, { payload }) => {
      if (payload.errorMsg) {
        state.formState = errorState(payload.errorMsg);
      } else {
        state.cartReady =true;
        state.buyCartDetails = payload;
        state.formState = fulfilledState();
      }
    },
    [fetchSelectedCartById.pending.toString()]: (state) => {
      state.formState = pendingState();
      return state;
    },
    [fetchSelectedCartById.rejected.toString()]: (state, { payload }) => {
      state.formState = rejectedState();
      return state;
    },
    [activeCart.fulfilled.toString()]: (state, { payload }) => {
      state.activeCartArray = payload
      state.formState = fulfilledState();
    },
    [activeCart.pending.toString()]: (state) => {
      state.formState = pendingState();
      return state;
    },
    [activeCart.rejected.toString()]: (state, { payload }) => {
      state.formState = rejectedState();
      return state;
    },
    [cartPermission.fulfilled.toString()]: (state, { payload }) => {
      state.cartCommunity = payload
    },
    [cartPermission.pending.toString()]: (state) => {
      state.formState = pendingState();
      return state;
    },
    [cartPermission.rejected.toString()]: (state, { payload }) => {
      state.formState = rejectedState();
      return state;
    },
    [saveSelectedCartIDs.fulfilled.toString()]: (state, { payload }) => {
      state.selectedCartIds = payload
    },
    [saveSelectedCartIDs.pending.toString()]: (state) => {
      state.formState = pendingState();
      return state;
    },
    [saveSelectedCartIDs.rejected.toString()]: (state, { payload }) => {
      state.formState = rejectedState();
      return state;
    },
    [saveSelectedCatlogIDs.fulfilled.toString()]: (state, { payload }) => {
      state.selectedCatlogIds = payload
    },
    [saveSelectedCatlogIDs.pending.toString()]: (state) => {
      state.formState = pendingState();
      return state;
    },
    [saveSelectedCatlogIDs.rejected.toString()]: (state, { payload }) => {
      state.formState = rejectedState();
      return state;
    },
    [savedColumns.fulfilled.toString()]: (state, { payload }) => {
      state.savedCols = payload
      state.formState = fulfilledState();
    },
    [savedColumns.pending.toString()]: (state) => {
      state.formState = pendingState();
      return state;
    },
    [savedColumns.rejected.toString()]: (state, { payload }) => {
      state.formState = rejectedState();
      return state;
    },
    [resizeColumns.fulfilled.toString()]: (state, { payload }) => {
      state.resizeCols = payload
      state.formState = fulfilledState();
    },
    [resizeColumns.pending.toString()]: (state) => {
      state.formState = pendingState();
      return state;
    },
    [resizeColumns.rejected.toString()]: (state, { payload }) => {
      state.formState = rejectedState();
      return state;
    },
    [selectedCols.fulfilled.toString()]: (state, { payload }) => {
      state.selectedColums = payload
      state.formState = fulfilledState();
    },
    [selectedCols.pending.toString()]: (state) => {
      state.formState = pendingState();
      return state;
    },
    [selectedCols.rejected.toString()]: (state, { payload }) => {
      state.formState = rejectedState();
      return state;
    },
    [getAutoCArtHistory.fulfilled.toString()]: (state, { payload }) => {
      if (payload.errorMsg) {
        state.historyFormState = errorState(payload.errorMsg);
      } else {
        state.historyFormState = fulfilledState();
        state.historyData = payload?.Items;
        state.historyCount = payload?.TotalCount;
      }
      return state;
    },
    [getAutoCArtHistory.pending.toString()]: (state) => {
      state.historyFormState = pendingState();
      return state;
    },
    [getAutoCArtHistory.rejected.toString()]: (state, { payload }) => {
      state.historyFormState = rejectedState();
      return state;
    },
    [onApplyingFilters.fulfilled.toString()]: (state, { payload }) => {
      state.filters = payload
      state.formState = fulfilledState();
      return state;
    },
    [onApplyingFilters.pending.toString()]: (state) => {
      state.formState = pendingState();
      return state;
    },
    [onApplyingFilters.rejected.toString()]: (state, { payload }) => {
      state.formState = rejectedState(payload);
      return state;
    },
    [recentTab.fulfilled.toString()]: (state, { payload }) => {
      state.defaultTab = payload
      state.formState = fulfilledState();
      return state;
    },
    [recentTab.pending.toString()]: (state) => {
      state.formState = pendingState();
      return state;
    },
    [recentTab.rejected.toString()]: (state, { payload }) => {
      state.formState = rejectedState(payload);
      return state;
    },
    [resizeHistoryCols.fulfilled.toString()]: (state, { payload }) => {
      state.historyColState = payload
    },
    [resizeHistoryCols.pending.toString()]: (state) => {
      return state;
    },
    [resizeHistoryCols.rejected.toString()]: (state, { payload }) => {
      return state;
    },
    [cartListFilter.fulfilled.toString()]: (state, { payload }) => {
      state.cartFilters = payload
      state.formState = fulfilledState();
      return state;
    },
    [cartListFilter.pending.toString()]: (state) => {
      state.formState = pendingState();
      return state;
    },
    [cartListFilter.rejected.toString()]: (state, { payload }) => {
      state.formState = rejectedState(payload);
      return state;
    },
    [moveItemtoCart.fulfilled.toString()]: (state, { payload }) => {
      if (payload.ErrorMessage) {
        state.moveTitleFormState = errorState(payload.ErrorDetails);
      } else {
        state.moveTitleFormState = fulfilledState();
      }
      return state;
    },
    [moveItemtoCart.pending.toString()]: (state) => {
      state.moveTitleFormState = pendingState();
      return state;
    },
    [moveItemtoCart.rejected.toString()]: (state, { payload }) => {
      state.moveTitleFormState = rejectedState();
      return state;
    },
    [setBuyerDetails.fulfilled.toString()]: (state, { payload }) => {
      state.oldBuyerCartItems = payload
    },  
    [cartDetailsFilterRemember.fulfilled.toString()]: (state, { payload }) => {
      state.setCartDetailsFilter = payload
      return state;
    },
     
    [addFeaturedListToCart.fulfilled.toString()]: (state, { payload }) => {
      if (payload.errorMsg) {
        state.addItemtoCartFormstate = errorState(payload.errorMsg);
      } else if (payload.ErrorMessage) {
        state.ErrorCart = (payload.ErrorMessage);
        state.addItemtoCartFormstate = fulfilledState();
      }
      else {
        state.addedItem = true;
        state.ErrorCart = ""
        state.addItemtoCartFormstate = fulfilledState();
      }
      return state;
    },
    [addFeaturedListToCart.pending.toString()]: (state) => {
      state.addItemtoCartFormstate = pendingState();
      return state;
    },
    [addFeaturedListToCart.rejected.toString()]: (state, { payload }) => {
      state.addItemtoCartFormstate = rejectedState(payload);
      return state;
    },
    [pageRememberCart.fulfilled.toString()]: (state, { payload }) => {
      state.cartCurrentPage = payload
      state.cartCurrentPageFormState = fulfilledState();
      return state;
    },
    [pageRememberCart.pending.toString()]: (state) => {
      state.cartCurrentPageFormState = pendingState();
      return state;
    },
    [pageRememberCart.rejected.toString()]: (state, { payload }) => {
      state.cartCurrentPageFormState = rejectedState(payload);
      return state;
    },
  },
});

export const { clearState, clearSelectedCart, clearAutoCartData, clearAdded,clearSelectedCartIds, clearDeleted, clearCreated, clearSaved, setTab, clearReady,clearCartDetails,clearColumns,clearResizeColumns, clearselectedCols, clearErrorCart,clearHistory,clearHistoryFormState,clearCartFilters,setScroll,setMainScrollHeight,setEbookScrollScroll,setAudioBookScroll,clearMoveFormState, clearAutoByRuleError,setCartReady,clearCancel,clearBuyerCart,clearCheck,clearRefreshData,clearBuyerCartF,clearOldBuyerCartItems,clearViewData,clearCartDetailFilter,clearSesionRemain,clearSessionChk,clearCartCurrentPage} =
  CartSlice.actions;
export const CartStateSelector = (state: RootState) => state.Carts;
export default CartSlice.reducer;
