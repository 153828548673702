/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from "react";
import {
  Form,
  Row,
  Col,
  Dropdown,
  DatePicker,
  Menu,
} from "antd";
import DropDown from "../../../../stateless/common/dropDown";
import Button from "../../../../stateless/common/button";
import {
  downIcon,
  editIcon,
  list,
  asteriskIcon,
  notFound,
} from "../../../../../images";
import { useDispatch, useSelector } from "react-redux";
import {
  getAutoCArtHistory,
  resizeHistoryCols,
} from "../../../../../../redux/actions/carts/cartAction";
import { filterOptions, Page } from "../../../../../constants/constants";
import { userStateSelector } from "../../../../../../redux/reducers/user/userReducer";
import { useHistory, useLocation } from "react-router-dom";
import "../../cartDetails/cartDetails.less";
import { CartStateSelector } from "../../../../../../redux/reducers/carts/cartsReducer";
import { PurchaseTable } from "../../../purchases/purchaseTable";
import PaginationTag from "../../../../stateless/common/pagination";
import moment from "moment";
import { autoCartHistory } from "../../../purchases/tableColumns";
import { CSVLink } from "react-csv";
import "../../../purchases/purchase.less";
import "./style.less";
import ExportIcon from "../../../../../../assets/dummy/ExportIcon.png";
import { SideBarStateSelector, retainPageSize } from "../../../../../../redux/reducers/app/sideBarReducer";

export const AutoHistory = (props: any) => {
  const { libId } = props;
  const {
    buyerImgUrl,
    filters,
    historyData,
    historyCount,
    historyFormState,
    historyColState,
  } = useSelector(CartStateSelector);
  const { retainedPageSize } = useSelector(SideBarStateSelector);
  const history = useHistory();
  const dispatch = useDispatch();
  const { RangePicker } = DatePicker;
  const [data, setData] = useState(historyData);
  const [dataCount, setDataCount] = useState(historyCount);
  const [completedPurchaseResize, setCompletedPurchaseResize] = useState<any>(
    []
  );
  const { appUser } = useSelector(userStateSelector);
  const [disable,setDisable] = useState<boolean>(false);
  const [showDateRange, setShowDateRange] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState<number>(
    retainedPageSize ?? 100
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [cloudValue, setCloudValue] = useState<any>();
  const [screenSize, getDimension] = useState<any>({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });
  const [paginationHide, setPaginationHide] = useState(false);
  const [cloudHackValue, setCloudHackValue] = useState<any>();
  const [exportData, setExportData] = useState<any>([]);
  const [start, setStart] = useState<number>(Page.DEFAULT_START);
  const [lastCount, setLastCount] = useState(start + pageSize - 1);
  const [fromDate, setFromDate] = useState<any>(
    moment().subtract(1, "days").format("MM/DD/YYYY")
  );
  const optionList = [10, 20, 50, 100, 250, 500];
  const [toDate, setToDate] = useState<any>(moment().format("MM/DD/YYYY"));
  var currency = appUser &&  appUser.libraryData? appUser.libraryData[0]?.CurrencySymbol : "";
  var dateFormat = appUser?.libraryData ? appUser.libraryData[0]?.DateFormat.toUpperCase() : ""
  const headers = [
    { label: "Date", key: "Date" },
    { label: "Purchased", key: "Purchased" },
    { label: "Title", key: "Title" },
    { label: "MediaTypeId", key: "MediaTypeId" },
    { label: "EISBN13", key: "EISBN13" },
    { label: "Quantity", key: "Quantity" },
    { label: "Cost", key: "Cost" },
    { label: "Type", key: "Type" },
    { label: "Note", key: "Note" },
  ];
  const dateModify = (date: any) => {
    const myStr = date.substr(0, 10);
    const currentDate = moment(myStr)
      .tz("Etc/GMT")
      .format(dateFormat);
    return currentDate;
  };
  const csvExportList = {
    filename: `HistoryList.csv`,
    headers: headers,
    data: exportData,
  };

  useEffect(() => {
    if (historyData !== undefined) {
      const tableValue = (historyData?.map((item: any) => {
        const Date = item?.Date !== null || undefined ? dateModify(item?.Date):""
    
      return {
          ...item, Date:Date
        }
      }))
      setExportData(tableValue)
    }
  }, [historyData])
  const pageOptions = React.useMemo(() => (
    <Menu className="header-dropdown">
      <Menu.Item key="page-size">
        <Row>
          <Col style={{ width: "20px" }}>
            <img style={{ width: "14px", height: "14px" }} src={list} alt=" " />
          </Col>
          <Col flex="auto" style={{ paddingLeft: "5px" }}>
            Page Size
          </Col>
        </Row>
      </Menu.Item>
      {optionList?.map((data: number, index: number) => (
        <Menu.Item key={index} onClick={() => changePageSize(data)}>
          <Row>
            <Col style={{ width: "20px" }}>
              {pageSize === data ? (
                <img
                  style={{ width: "14px", height: "14px" }}
                  src={asteriskIcon}
                  alt="*"
                />
              ) : (
                " "
              )}
            </Col>
            <Col style={{ paddingLeft: "5px" }}>{data}</Col>
          </Row>
        </Menu.Item>
      ))}
      <Menu.Item>
        <Row>
          <Col style={{ width: "20px" }}>
            <img style={{ width: "14px", height: "14px", marginTop: "8px" }} src={ExportIcon} alt=" " />
          </Col>
          <Col>
            <div className="exportColListCsv export-list-history">
              <CSVLink className="export-list-link" {...csvExportList}>
                Export List
              </CSVLink>
            </div>
          </Col>
        </Row>
      </Menu.Item>
    </Menu>
  ), [optionList, pageSize, csvExportList]);
  
  useEffect(() => {
    setData(historyData);
    setDataCount(historyCount);
  }, [historyCount, historyData]);

  useEffect(() => {
    let end = start + pageSize - 1;
    if (dataCount < end) {
      setLastCount(dataCount);
    } else {
      setLastCount(end);
    }
  }, [start, pageSize, dataCount]);

  useEffect(() => {
    if (completedPurchaseResize?.length > 0) {
      dispatch(resizeHistoryCols(completedPurchaseResize));
    }
  }, [completedPurchaseResize]);

  useEffect(() => {
    dispatch(
      getAutoCArtHistory({
        libId: `${libId}`,
        endDate: moment().format("MM/DD/YYYY"),
        recordCount: pageSize,
        recordStart: 1,
        sortDirection: "ASC",
        sortField: "",
        startDate: moment().subtract(1, "days").format("MM/DD/YYYY"),
      })
    );
  }, []);

  useEffect(() => {
    window.addEventListener("resize", setDimension);
    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);
  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  const onPageChange = (page: any) => {
    const pageStart = (page - 1) * pageSize + 1;
    setStart(pageStart);
    setCurrentPage(page);
    dispatch(
      getAutoCArtHistory({
        libId: `${libId}`,
        endDate: toDate,
        recordCount: pageSize,
        recordStart: pageStart,
        sortDirection: "ASC",
        sortField: "",
        startDate: fromDate,
      })
    );
  };

  const handleDateRangeSelect = (value: number) => {
    if (value !== -1) {
      setShowDateRange(false);
      setDisable(false)
      setFromDate(moment().subtract(value, "days").format("MM/DD/YYYY"));
      setToDate(moment().format("MM/DD/YYYY"));
      dispatch(
        getAutoCArtHistory({
          libId: `${libId}`,
          endDate: moment().format("MM/DD/YYYY"),
          recordCount: pageSize,
          recordStart: start,
          sortDirection: "ASC",
          sortField: "",
          startDate: moment().subtract(value, "days").format("MM/DD/YYYY"),
        })
      );
    } else {
      setShowDateRange(true);
    }
  };

  const handleDateSelect = (dateString: any) => {
    if(dateString !== null){
      setFromDate(moment(dateString[0]).format("MM/DD/YYYY"));
      setToDate(moment(dateString[1]).format("MM/DD/YYYY"));
      setDisable(true);
    }else{
      setDisable(false)
    }
  };

  const changePageSize = (size: number) => {
    setStart(1)
    setPageSize(size);
    dispatch(retainPageSize(size))
    setCurrentPage(1)
    dispatch(
      getAutoCArtHistory({
        libId: `${libId}`,
        endDate: toDate,
        recordCount: size,
        recordStart: 1,
        sortDirection: "ASC",
        sortField: "",
        startDate: fromDate,
      })
    );
  };

  const handleSearch = () => {
    dispatch(
      getAutoCArtHistory({
        libId: `${libId}`,
        endDate: toDate,
        recordCount: pageSize,
        recordStart: start,
        sortDirection: "ASC",
        sortField: "",
        startDate: fromDate,
      })
    );
  };

  useEffect(() => {
    if (dataCount > lastCount - start + 1 && data && data?.length ) {
      setPaginationHide(false);
    }else{
      setPaginationHide(true);
    }
  }, [dataCount, lastCount,start,data]);

  return (
    <>
      <div>
        <Row className="headerCenter">
          <Col xs={4} sm={4} md={3} lg={4} xl={3} className="dateRangeStyle">
            <Col>
              <span className="title-count">
                {historyCount > 0 ? start : "0"}-{lastCount} of {historyCount} Titles
              </span>
            </Col>
          </Col>
          <Col xs={11} sm={11} md={11} lg={12} xl={13}>
            <Row className="filters-contatiner-history">
              {}

              <DropDown
                className="filters-history"
                bordered={false}
                optionValue={filterOptions.dateRange}
                icon={downIcon}
                onChange={handleDateRangeSelect}
                defaultValue={1}
              />
              {showDateRange ? (
                <>
                  <RangePicker
                    placeholder={["Custom CloudLibrary Date Range", ""]}
                    getPopupContainer={(trigger: any) => trigger.parentNode}
                    value={cloudHackValue || cloudValue}
                    onChange={handleDateSelect}
                    className="history-datepicker"
                    suffixIcon={<img src={downIcon} alt="" />}
                    separator={<img src="" alt="" />}
                  />
                  <div>
                    {disable ?
                      <Button type= "primary historySearch" onClick={handleSearch}>
                        Search
                      </Button>
                      :
                      <Button type= "primary historySearch historybtn">
                        Search
                      </Button>}
                  </div>
                </>
              ) : null}
            </Row>
          </Col>
          <Col xs={4} sm={4} md={4} lg={3} xl={4}>
            {/* <div className="exportColListCsv">
              <CSVLink className="export-list-link" {...csvExportList}>
                Export List
              </CSVLink>
            </div> */}
          </Col>
          <Col xs={4} sm={4} md={4} lg={4} xl={3}>
            <div className="history-editor-Button">
              <Dropdown
                overlay={pageOptions}
                trigger={["click"]}
                getPopupContainer={(trigger: any) => trigger.parentNode}
              >
                <button className="button-type editor">
                  <img src={editIcon} className="edit-icon" alt="" /> Editor
                  &nbsp; &nbsp;
                  <img src={downIcon} className="down-icon" alt="" />
                </button>
              </Dropdown>
            </div>
          </Col>
        </Row>
      </div>
      <div style={
            screenSize?.dynamicHeight
              ? { height: paginationHide?screenSize?.dynamicHeight - 280:screenSize?.dynamicHeight - 240 }
              : {}
          } 
          className="purchaseBody scrollStyle scrollOnBooksScreen h-50">
        <PurchaseTable
          data={data}
          editAble={false}
          needRowData={false}
          columns={autoCartHistory(buyerImgUrl, notFound, history,currency)}
          formState={historyFormState}
          setColumnResize={setCompletedPurchaseResize}
          resizeCol={historyColState}
          showPagination={true}
          classN="historyTable left-sorter"
          rowKey={"AutoBuyHistoryId"}
        />
        <div className="purchaseFooter pb-20">
          {dataCount > lastCount - start + 1 && data && data?.length ? (
            <>
              {
                historyFormState.isSuccess ?
                  <PaginationTag
                    total={dataCount}
                    current={currentPage}
                    defaultPageSize={pageSize}
                    onChange={onPageChange}
                  />
                  : null
              }
              <Col>
                <span className="searchTitleStyle mb-20">
                  {dataCount > 0 ? start : "0"}-{lastCount} of {dataCount} Titles
                </span>
              </Col>
            </>
          ) : null}
        </div>
      </div>
    </>
  );
};
