/* eslint-disable @typescript-eslint/no-redeclare */
/* eslint-disable no-loop-func */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useEffect, useRef, useState } from "react";
import "antd/dist/antd.css";
import {
  Table,
  Dropdown,
  Menu,
  Checkbox,
  Divider,
  Row,
  Col,
  Tooltip,
  Empty,
  Form,
  InputNumber,
  Input,
  List,
} from "antd";
import DropDown from "../../../stateless/common/dropDown";
import { filterOptions } from "../../../../constants/constants";
import { dropdownIcon, notFound } from "../../../../images";
import { CartPopup } from "./cartPopup";
import { Resizable } from "react-resizable";
import "./cartListPage.less";
import { AppRoutes } from "../../../../router/appRoutes";
import PublicationDate from "./publicationDate";
import PaginationTag from "../../../stateless/common/pagination";
import { BookRow } from "../../curatedList/bookList/bookRowItem";
import { useSelector } from "react-redux";
import { CartStateSelector } from "../../../../../redux/reducers/carts/cartsReducer";
import Button from "../../../stateless/common/button";

const EditableContext = React.createContext({});
const cartScreen: boolean = true

const EditableRow = ({ ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = (props: any) => {
  const {
    title,
    editable,
    children,
    dataIndex,
    inputType,
    record,
    handleSave,
    handleQanSave,
    globalQty,
    selectedItemsIds,
    pressdArrow,
    notSetMin,
    notSetMax,
    ...restProps
  } = props;
  const [editing, setEditing] = useState<boolean>(false);
  const inputRef = useRef<any>(null);
  const inputQuanRef = useRef<any>(null);
  const [cartVal, setCartVal] = useState<number>(record?.Quantity);
  const form = useContext<any>(EditableContext);
  const isCart: boolean = true
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    if (cartScreen && title === "Quantity") {
      setEditing(!editing);
      form.setFieldsValue({
        [dataIndex]: record[dataIndex],
      });
    }
  };

  const inputElement = document.querySelector(`.cartItemsInput_${record?.CatalogItemId} .ant-input-number-input`);
  if (inputElement) {
    inputElement.setAttribute("maxLength", "3");
  }

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };
  const [itemVal, setItemVal] = useState<number>(cartVal);
  const [val, setVal] = useState<number>(record?.Quantity);
  const [isValChanged, setIsValChanged] = useState<boolean>(false);
  const [bookDetail, setBookDetail] = useState<any>(record);
  const [globalQuantity, setGlobalQuantity] = useState<number>(1);
  const [PressdArrowBtn, setPressdArrowBtn] = useState<any>({ offset: 1, type: '' });
  const [checked, setChecked] = useState<boolean>(true);

  useEffect(() => {
    setBookDetail(record)
  }, [])

  const saveQuantity = async (bookId: any, value: any) => {
    var newVal: number = value - globalQuantity + 1;
    var newVal: number = value - globalQuantity + 1;
    setCartVal(newVal);
    handleQanSave({
      ...bookId,
      Quantity: newVal + globalQuantity - 1 > 0 ? newVal + globalQuantity - 1 : 1,
    });
    setBookDetail({ ...bookDetail, Quantity: newVal + globalQuantity - 1 > 0 ? newVal + globalQuantity - 1 : 1 })
    try {
      inputQuanRef.current.focus();
      form.setFieldsValue({
        [dataIndex]: record[dataIndex],
      });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  useEffect(() => {
    if (isCart && selectedItemsIds?.includes(record?.CatalogItemId)) {
      setPressdArrowBtn({ offset: 1, type: '' })
      setChecked(true);
    } else {
      setChecked(false);
      setPressdArrowBtn({ offset: 1, type: '' })
    }
  }, [isCart, selectedItemsIds]);

  useEffect(() => {
    if (pressdArrow?.type) {
      setPressdArrowBtn(pressdArrow)
    }
  }, [pressdArrow])

  useEffect(() => {
    if (checked && isCart) {
      var prevVal = globalQuantity
      var newVal = globalQty
      if (prevVal !== globalQty) {
        if (PressdArrowBtn?.type === 'up') {
          setGlobalQuantity(prevVal + 1)
        }
        if (PressdArrowBtn?.type === 'down') {
          setGlobalQuantity(prevVal - 1)
        }
        if (PressdArrowBtn?.type === '') {
          setGlobalQuantity(prevVal - 0)
        }
      } else {
        setGlobalQuantity(newVal)
      }
    }
  }, [isCart, checked, PressdArrowBtn])

  useEffect(() => {
    if (isCart) {
      var val = cartVal + globalQuantity - 1 > 0
        ? cartVal + globalQuantity - 1
        : 1
      if (val > 0 && val < 1000) {
        setItemVal(val)
      }
    }
  }, [isCart, cartVal, globalQuantity, checked])

  useEffect(() => {
    if (isCart && itemVal && val && itemVal !== val) {
      setIsValChanged(true)
    } else {
      setIsValChanged(false)
    }
  }, [itemVal, val, isCart])

  useEffect(() => {
    if (isCart && notSetMin && checked && PressdArrowBtn?.type && itemVal === 1) {
      setBookDetail({ ...bookDetail, Quantity: 1 })
      setGlobalQuantity(1)
      setCartVal(1)
      handleQanSave({
        ...bookDetail,
        Quantity: 1,
      });
    }
  }, [notSetMin, checked, PressdArrowBtn, itemVal, isCart])

  useEffect(() => {
    if (isCart && notSetMax && checked && PressdArrowBtn?.type && itemVal === 999) {
      setBookDetail({ ...bookDetail, Quantity: 999 })
      // setGlobalQuantity(1)
      // setCartVal(999)
      handleQanSave({
        ...bookDetail,
        Quantity: 999,
      });
    }
  }, [notSetMax, checked, PressdArrowBtn, itemVal, isCart])

  let childNode = children;
  let isInput = inputType === "number";
  if (true) {
    if (isInput) {
      childNode = (
        <>
          <InputNumber
            name={bookDetail.Title}
            defaultValue={1}
            ref={inputQuanRef}
            value={itemVal}
            min={1}
            max={999}
            disabled={false}
            onChange={(value) => saveQuantity(bookDetail, value)}
            id={`${record?.CatalogItemId + '_' + record?.CartDetailId}`}
            className={`cartItemsInput_${record?.CatalogItemId} ${isValChanged ? "updateChangeQunatity" : "price-input-notShow"
              } ${checked
                ? "purchaseSelected"
                : ""
              }`}
          />
        </>
      );
    } else {
      childNode = editing ? (
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `${title} is required.`,
            },
          ]}
        >
          <Input ref={inputRef} onPressEnter={save} onBlur={save} />
        </Form.Item>
      ) : record?.ItemState !== "NOT_FOR_SALE" ? (
        <div
          className="editable-cell-value-wrap"
          onDoubleClick={toggleEdit}
        >
          {children[1]}
        </div>
      ) : (
        <div
          className="not-editable-cell-value-wrap"
          style={{
            paddingRight: 24,
            cursor: "not-allowed",
          }}
        >
          {children[1]}
        </div>
      );
    }
  }

  return <td {...restProps}>{childNode}</td>;
};

const ResizeableTitle = (props: any) => {
  const { onResize, width, ...restProps } = props;
  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={parseInt(width)}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

export const ListApp = (props: any) => {
  const {
    cart,
    selectedCart,
    getQuantity,
    resetData,
    cartData,
    handleFormatChange,
    handleSortChange,
    appliedSorter,
    formatValue,
    // setCopies,
    copies,
    sortIcon,
    onSortClick,
    imageUrl,
    currency,
    setCheckedItems,
    setSelectedItems,
    loading,
    history,
    fetchInfo,
    checkedRows,
    userColumns,
    setUserColumns,
    setColumnsResize,
    resizeCols,
    selectedColums,
    moveToCart,
    appUser,
    filteredCarts,
    setShowModal,
    getBookLabel,
    currentPage,
    pageSize,
    onPageChange,
    lastCount,
    startCount,
    gridView,
    showQuantitybtn,
    itemsToDelete,
    onRemove,
    onPurchase,
    onPurchaseCart,
    handleQuantitySave,
    onQuantyCancel,
    selectedItemsIds,
    maxQty,
    setGlobalQtyCart,
    setGloblalBtnPressed,
    notSetMin,
    notSetMax,
    totalTitles,
    removeconfirm  } = props;
  const [screenSize, setScreenSize] = useState<boolean>(false);
  const [screenSizeY, setScreenSizeY] = useState<any>("400px");
  const [visibleMenuSettings, setVisibleMenuSettings] =
    useState<boolean>(false);
  const [windowSize, setWindowSize] = useState<any>({
    dynamicWidth: window.innerWidth,
  });
  const [dataSource, setDataSource] = useState<any>(cartData);
  const [globalQty, setGlobalQty] = useState<number>(1);
  const [pressdArrow, setPressdArrow] = useState<any>([]);
  const [allChecked, setAllChecked] = useState<any>([]);
  const [selectedItemsAfterG, setSelectedItemsAfterG] = useState<any>([]);
  const [selectedRowKey, setSelectedRowKey] = useState<any>([]);
  const [checkedColumns, setCheckedColumns] = useState<any>([]);
  const [selectedItems, setSelectedItem] = useState<any>([]);
  const [initialColumns, setInitialColumns] = useState<any>([]);
  const [allSelected, setAllSelected] = useState<boolean>(false);
  const [globlalQtyBtnPressed, setGloblalQtyBtnPressed] = useState<boolean>(false);
  const [indeterminate, setIndeterminate] = useState(true);
  const [view, setView] = useState<number>();
  const { setCartDetailsFilter } = useSelector(CartStateSelector);
  const [columns, setColumns] = useState<any>([
    {
      width: 50,
      title: () => (
        <span style={{ visibility: "hidden" }}>

        </span>
      ),
      dataIndex: "customColumn",
      ellipsis: true,
      className: "customColumn",
      check: true,
    },
    {
      width: 150,
      title: "Cover",
      dataIndex: ["ImageId", "FileFormatName"],
      ellipsis: true,
      check: true,
      render: (text: any, row: any) => {
        return (
          <div className="CartCoverColm">
            <img
              className="item-cover"
              src={`https://${imageUrl}?type=DOCUMENTIMAGE&documentID=${row["ImageId"]}&size=MEDIUM&token=nobody`}
              alt=""
              onError={(e: any) => {
                e.target.src = notFound;
              }}
            />
            <img
              src={getBookLabel(row["FileFormatName"])}
              alt=""
              className="cart-book-label"
            />
          </div>
        );
      },
    },
    {
      width: 150,
      title: "Title",
      dataIndex: ["ItemState", "Title"],
      ellipsis: true,
      check: true,
      render: (text: any, row: any) => {
        return (
          <>
            {row["ItemState"] === "PRE_SALE" ? (
              <span className="book-state-carts">PRE SALE</span>
            ) : null}
            <Tooltip title={row["Title"]}>
              <p
                className="column bold-titles"
                onClick={() =>
                  history.push(AppRoutes.ITEMDETAILS, {
                    book: row,
                  })
                }
              >
                {row["Title"]}
              </p>
              {/* {row["Series"] !== "" ?
              <>
              <br></br>
              <span className="column">{row["Series"]}</span>
              {row["NumberInSeries"] !== "" ?
              <span className="column"> / {row["NumberInSeries"]}</span>                
              : null} 
              </>
              : null} */}
            </Tooltip>
          </>
        );
      },
    },
    {
      width: 50,
      title: "Author",
      dataIndex: "Authors",
      ellipsis: true,
      className: "column",
      check: true,
    },
    {
      width: 50,
      title: "Narrator",
      dataIndex: "Narrator",
      ellipsis: true,
      className: "column",
      check: true,
    },
    {
      width: 50,
      title: "Series",
      dataIndex: ["Series", "NumberInSeries"],
      ellipsis: true,
      className: "column",
      check: true,
      render: (text: any, row: any) => {
        return (
          <>
            <p>{row["Series"]}</p>
            {row["NumberInSeries"] !== "" ? (
              <p> #{row["NumberInSeries"]} </p>
            ) : null}
          </>
        );
      },
    },
    {
      width: 50,
      title: "Categories",
      dataIndex: "Categories",
      className: "column",
      ellipsis: true,
      check: true,
      render: (Categories: any) => {
        let cate = Categories?.split(":");
        return <span>{cate ? String(cate[0]) : ""}</span>;
      },
    },
    {
      width: 50,
      title: "Sub-Categories",
      dataIndex: "Categories",
      className: "column",
      ellipsis: true,
      check: true,
      render: (Categories: any) => {
        let subCate = Categories?.split(":");
        return <span>{subCate ? String(subCate[1]) : ""}</span>;
      },
    },
    {
      width: 50,
      title: "Format",
      dataIndex: "FileFormatName",
      className: "column",
      ellipsis: true,
      check: true,
    },
    {
      width: 50,
      title: "ISBN",
      dataIndex: "EISBN",
      className: "column",
      ellipsis: true,
      check: true,
    },
    {
      width: 50,
      title: "Publisher",
      dataIndex: "Imprint",
      ellipsis: true,
      className: "column",
      check: true,
    },
    {
      width: 50,
      title: "Provider",
      dataIndex: "ContentProviderName",
      ellipsis: true,
      className: "column",
      check: true,
    },
    {
      width: 50,
      title: "Publication Date",
      dataIndex: "PublicationDate",
      ellipsis: true,
      className: "column",
      check: true,
      render: (PDate: Text) => {
        return <PublicationDate date={PDate} />;
      },
    },
    {
      width: 50,
      title: "Model",
      dataIndex: "Model",
      className: "column",
      ellipsis: true,
      check: true,
    },
    {
      width: 50,
      title: "Last Purchased Date",
      dataIndex: "LastPurchasedDate",
      ellipsis: true,
      className: "column",
      check: true,
      render: (PDate: Text) => {
        return <PublicationDate date={PDate} />;
      },
    },
    {
      title: "Owned",
      dataIndex: "NumberOfCopies",
      className: "column",
      ellipsis: true,
      width: 50,
      check: true,
    },
    {
      title: "Holds",
      dataIndex: "NumberOfHolds",
      className: "column",
      ellipsis: true,
      width: 50,
      check: true,
    },
    {
      title: "Loans",
      dataIndex: "NumberOfLoans",
      className: "column",
      ellipsis: true,
      width: 50,
      check: true,
    },
    {
      title: "Hold Ratio",
      dataIndex: "HoldRatio",
      className: "column",
      ellipsis: true,
      width: 50,
      check: true,
      render: (text: any) => {
        return (
          <span>
            {text >= 1 ? text : ''}
          </span>
        );
      },
    },
    {
      title: "In Circ.",
      dataIndex: "NumberOfCurrentLoans",
      className: "column",
      ellipsis: true,
      width: 50,
      check: true,
    },
    {
      title: "Suggested",
      dataIndex: "NumberOfWish",
      className: "column",
      ellipsis: true,
      width: 50,
      check: true,
    },
    {
      width: 50,
      title: "Carts",
      dataIndex: ["NumberInCarts", "CatalogItemId"],
      className: "column",
      ellipsis: true,
      check: true,
      render: (text: any, row: any) => {
        return (
          <p
            className="book-details-value col-font-size"
            onMouseEnter={() => fetchInfo(row["CatalogItemId"])}
          >
            {!loading && (
              <CartPopup
                cartId={row["NumberInCarts"]}
                CatalogItemId={row["CatalogItemId"]}
              />
            )}
          </p>
        );
      },
    },
    {
      width: 50,
      title: "Order",
      dataIndex: "NumberOnOrder",
      className: "column",
      ellipsis: true,
      check: true,
    },
    {
      width: 50,
      title: "PPU Price",
      dataIndex: "PPUPrice",
      className: "column",
      ellipsis: true,
      check: true,
      render: (price: number) => {
        return (
          <span>
            {currency}
            {price.toFixed(2)}
          </span>
        );
      },
    },
    {
      width: 50,
      title: "Price",
      dataIndex: "RetailPrice",
      className: "column",
      ellipsis: true,
      check: true,
      render: (price: number) => {
        return (
          <span>
            {currency}
            {price.toFixed(2)}
          </span>
        );
      },
    },
    {
      title: "Quantity",
      dataIndex: "Quantity",
      ellipsis: true,
      check: true,
      className: "column",
      width: 85,
    },
    {
      width: 50,
      title: "Total Price",
      dataIndex: "totalPrice",
      className: "column",
      ellipsis: true,
      check: true,
      render: (price: number, row: any) => {
        // eslint-disable-next-line eqeqeq
        const amountData =
          copies[row["CatalogItemId"]] === undefined
            ? price
            : row["RetailPrice"] * copies[row["CatalogItemId"]];
        return (
          <span className="priceListContainer">
            <b>
              {currency}
              {amountData
                ?.toFixed(2)
                ?.toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </b>
            <b
              style={{ display: "none" }}
              className={`amountPrice ${row["ItemState"] !== "FOR_SALE" ? "preSaleRow" : ""
                }`}
            >
              {amountData?.toFixed(2)}
            </b>
          </span>
        );
      },
    },
  ]);

  const handleResize = (index: any) => (e: any, size: any) => {
    const nextColumns = [...columns];
    if (index === 0 && size?.size?.width < 43) {
      nextColumns[index] = {
        ...nextColumns[index],
        width: size?.size?.width < 43 ? 43 : size?.size?.width,
      };
    } else {
      nextColumns[index] = {
        ...nextColumns[index],
        width: size?.size?.width,
      };
    }
    setColumns(nextColumns);
    setColumnsResize(nextColumns);
  };

  const columnS = columns?.map((col: any, index: any) => ({
    ...col,
    onHeaderCell: (column: { width: number }) => ({
      width: column.width,
      onResize: handleResize(index),
    }),
    onCell: (record: any) => ({
      record,
      inputType: col.dataIndex === "Quantity" ? "number" : "text",
      editable: col.editable,
      dataIndex: col.dataIndex,
      title: col.title,
      handleSave,
      handleQanSave,
      globalQty,
      selectedItemsIds,
      pressdArrow,
      notSetMin,
      notSetMax
    }),
  }));

  const handleSave = (row: any) => {
    // getpoRef(row);
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });
    setDataSource(newData);
  };
  const handleQanSave = (row: any) => {
    getQuantity(row);
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });
    setDataSource(newData);
  };

  const setDimension = () => {
    setWindowSize({ dynamicWidth: window.innerWidth });
  };

  const components = {
    header: {
      cell: ResizeableTitle,
    },
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const onSelectChange = (selectedRowKeys: any, selectedRows: any) => {
    setSelectedRowKey(selectedRowKeys);
    setSelectedItems(selectedRows);
    setSelectedItem(selectedRows);
    setCheckedItems(selectedRows);
    setAllChecked(selectedRowKeys);
  };

  let selectedRowKeys = checkedRows?.length > 0 ? checkedRows : selectedRowKey;
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleVisibleChange = (flag: any) => {
    setVisibleMenuSettings(flag);
  };

  const onChange = (e: any) => {
    var checkColumnsState: any =
      userColumns?.length > 0 ? userColumns : initialColumns;
    var checkColumns = [...checkColumnsState];
    for (var j = 0; j < checkColumns.length; j++) {
      if (e.target.id === checkColumns[j].title) {
        let temp_col = { ...checkColumns[j] };
        temp_col.check = e.target.checked;
        checkColumns[j] = temp_col;
        setUserColumns(checkColumns);
      }
    }
    var checkedColumn: any[] = checkedColumns;
    if (e.target.checked) {
      checkedColumn = checkedColumn.filter((id) => {
        return id !== e.target.id;
      });
    } else if (!e.target.checked) {
      checkedColumn.push(e.target.id);
    }

    var filtered: any[] = initialColumns;
    for (var i = 0; i < checkedColumn.length; i++)
      filtered = filtered.filter((el) => {
        return el.title !== checkedColumn[i];
      });
    setColumnsResize(filtered);
    setColumns(filtered);
    setCheckedColumns(checkedColumn);
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);
    setInitialColumns(columns);
    var windowWidth = window.innerWidth;
    if (windowWidth < 1000) {
      setScreenSize(true);
    } else {
      setScreenSize(false);
    }
    if (selectedCart?.NumberOfTitles > pageSize) {
      if (windowWidth > 1000 && windowWidth < 1400) {
        setScreenSizeY("430px");
      }
      if (windowWidth > 1400) {
        setScreenSizeY("400px");
      }
      if (windowWidth > 1700) {
        setScreenSizeY("430px");
      }
    } else {
      if (windowWidth > 1000 && windowWidth < 1400) {
        setScreenSizeY("400px");
      }
      if (windowWidth > 1400) {
        setScreenSizeY("370px");
      }
      if (windowWidth > 1700) {
        setScreenSizeY("400px");
      }
    }
    // setCopies({});
    var reCol: any[] = columns;
    var reColWidth = [...reCol];
    for (let m = 0; m < reColWidth.length; m++) {
      for (let n = 0; n < resizeCols?.length; n++) {
        if (reColWidth[m].title === resizeCols[n].title) {
          reColWidth[m].width = resizeCols[n].width;
        }
      }
    }
    setColumns(reColWidth);
    if (selectedColums?.length > 0) {
      var removeCol: any[] = columns;
      for (var k = 0; k < selectedColums?.length; k++) {
        removeCol = removeCol?.filter((item) => {
          return item.title !== selectedColums[k];
        });
      }
      setColumns(removeCol);
      setCheckedColumns(selectedColums);
    }
  }, []);

  useEffect(() => {
    setDataSource([]);
    setTimeout(() => {
      setDataSource(cartData);
    }, 50);
  }, [cartData]);

  useEffect(() => {
    setCheckedItems(selectedItems);
  }, [selectedItems]);

  useEffect(() => {
    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [selectedItems]);

  useEffect(() => {
    if (resetData) {
      setDataSource([]);
      setTimeout(() => {
        setDataSource(cartData);
      }, 50);
    }
  }, [resetData]);

  useEffect(() => {
    setView(gridView);
  }, [gridView]);

  const menu = (
    <Menu className="header-dropdown">
      <Menu.ItemGroup title="Columns">
        {initialColumns?.map((column: any, index: number) => {
          // if(column?.dataIndex !=="customColumn"){
          return (
            <Menu.Item key={column.title} className={`${column?.dataIndex === "customColumn" ? "notShowMenu" : ""}`}>
              <Checkbox
                id={column.title}
                onChange={onChange}
                checked={column.check}
              >
                {column.title}
              </Checkbox>
            </Menu.Item>
          );
          // }
        })}
      </Menu.ItemGroup>
    </Menu>
  );

  const menuR = (
    <Menu className="header-dropdown">
      <Menu.ItemGroup title="Columns">
        {userColumns?.map((column: any, index: number) => {
          //  if(column?.dataIndex !=="customColumn"){
          return (
            <Menu.Item key={column.title} className={`${column?.dataIndex === "customColumn" ? "notShowMenu" : ""}`}>
              <Checkbox
                id={column.title}
                onChange={onChange}
                checked={column.check}
              >
                {column.title}
              </Checkbox>
            </Menu.Item>
          );
          //  }
        })}
      </Menu.ItemGroup>
    </Menu>
  );

  var curency = appUser?.libraryData[0]?.CurrencySymbol;

  const userCarts = (
    <Menu className="header-dropdown">
      {filteredCarts
        ?.filter((d: any) => d.CartId !== selectedCart?.CartId)
        ?.map((data: any, index: number) => {
          return (
            <Menu.Item key={index} onClick={() => moveToCart(data)}>
              <p className="dropDown-title">{data.CartName}</p>
              <span className="dropDown-content">
                {curency} {data.TotalCartAmount} . {data.NumberOfTitles} Titles
              </span>
            </Menu.Item>
          );
        })}
      <Menu.Divider />
      <Menu.Item
        key="New-cart-Link"
        className="dropDown-link"
        onClick={() => setShowModal(true)}
      >
        <p>New Cart</p>
      </Menu.Item>
    </Menu>
  );


  const onUncheckBox = (e: any) => {
    if (e.target.checked) {
      setAllSelected(e.target.checked);
        setAllChecked(
        dataSource?.map((items: any) => {
          return items?.CatalogItemId;
        })
      );
    } else {
      setAllSelected(false)
      setAllChecked([]);
    }
    };

  const onCustomSelect = (checkedValues: any) => {
    setAllChecked(checkedValues);
  };

  const clickArea = (id: any) => { };

  useEffect(() => {
    var selectedRows = dataSource?.filter((items: any) => {
      return allChecked?.includes(items?.CatalogItemId);
    });
    if (selectedRows) {
      setSelectedRowKey(allChecked);
      setSelectedItems(selectedRows);
      setSelectedItem(selectedRows);
      setCheckedItems(selectedRows);
    }
    if (allChecked?.length !== dataSource?.length) {
      setAllSelected(false);
      if (allChecked?.length === 0) {
        setIndeterminate(false);
      } else {
        setIndeterminate(true);
      }
    } else {
      setAllSelected(true);
      setIndeterminate(false);
    }
  }, [allChecked]);

  const onValueChange = (value?: any) => {
    setGlobalQty(value);
    if (selectedItems?.length) {
      var ttqs = selectedItems?.map((object: any) => ({
        ...object,
        Quantity: object?.Quantity + value - 1 > 0 ? object?.Quantity + value - 1 : 1,
      }));
      if (ttqs) {
        setSelectedItems(ttqs)
        setSelectedItemsAfterG(ttqs)
        setGloblalBtnPressed(true)
        setGloblalQtyBtnPressed(true)
      }
    }
  };

  useEffect(() => {
    setGlobalQtyCart(globalQty)
  }, [globalQty])

  const handelUpDown = (value: number, info: any) => {
    if (info?.type === 'up') {
      setPressdArrow(info)
    }
    if (info?.type === 'down') {
      setPressdArrow(info)
    }
  }

  useEffect(() => {
    if (notSetMin || notSetMax) {
      setGlobalQty(1)
    }
  }, [notSetMin, notSetMax])

  useEffect(()=>{
    if(removeconfirm){
      setAllSelected(false)
      setAllChecked([]);
    }
  },[removeconfirm])
 
  return (
    <div>
      <Divider className="carts-divider-top" />
      <Row justify="space-between" gutter={[0, 20]}>
        <Col xs={24} sm={24} md={16} lg={12} xl={9} xxl={8} className="title-align">
          <Row>
            <Col span={8} className="title-align">
              <span className="title-count">
                {totalTitles ? totalTitles + ' ' + 'in' + ' ' : null}{startCount}-{lastCount} of {selectedCart?.NumberOfTitles}{" "}
                Titles
              </span>
            </Col>
            <Col span={8} className="filters-contatiner">
              <DropDown
                bordered={false}
                defaultValue={
                  setCartDetailsFilter.formatValue !== undefined
                    ? setCartDetailsFilter.formatValue
                    : "Format"
                }
                value={formatValue}
                className="filters"
                optionValue={filterOptions.formats}
                icon={dropdownIcon}
                onChange={handleFormatChange}
              />
            </Col>
            <Col span={8} className="filters-contatiner">
              <DropDown
                defaultValue={
                  setCartDetailsFilter.sortOrder !== undefined
                    ? setCartDetailsFilter.sortOrder
                    : "Default"
                }
                value={appliedSorter}
                bordered={false}
                className="filters"
                optionValue={
                  selectedCart?.CartTypeCode?.toLowerCase() ===
                    "autobuyaudiobookcart" ||
                    selectedCart?.CartTypeCode?.toLowerCase() === "autobuycart"
                    ? filterOptions.Autosorters
                    : filterOptions.sorters
                }
                icon={dropdownIcon}
                onChange={handleSortChange}
                showSort={true}
                sortIcon={sortIcon}
                onSortClick={onSortClick}
              />
            </Col>
          </Row>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={20}
          xl={15}
          xxl={12}
          className="column-filter-container"
        >
          {!showQuantitybtn && itemsToDelete?.length > 0 ? (
            <Col span={6} className="removeBtnLink">
              {appUser?.isLibraryBuyer === true ? (
                <>
                  {/* {selectedCart?.CartOwner === appUser?.email ||
                  selectedCart?.IsCommunityCart ? ( */}
                  <Button
                    type="smallRed mark-ready-button"
                    onClick={() => {
                      onRemove();
                    }}
                  >
                    Remove selected
                  </Button>
                  {/* ) : null} */}
                </>
              ) : (
                <>
                  {selectedCart?.CartOwner === appUser?.email ||
                    selectedCart?.IsCommunityCart ? (
                    <Button
                      type="smallRed mark-ready-button"
                      onClick={() => {
                        onRemove();
                      }}
                    >
                      Remove selected
                    </Button>
                  ) : null}
                </>
              )}
            </Col>
          ) : null}
          {appUser?.isLibraryBuyer ? (
            <Col span={6} className="moveToCartButton">
              <Dropdown
                overlay={userCarts}
                trigger={["click"]}
                getPopupContainer={(trigger: any) => trigger.parentNode}
              >
                <span className="customize-link">Move Between Carts</span>
              </Dropdown>
            </Col>
          ) : appUser?.isLibraryUser &&
            (selectedCart?.CartOwner === appUser?.email ||
              selectedCart?.IsCommunityCart) ? (
            <Col span={6} className="moveToCartButton">
              <Dropdown
                overlay={userCarts}
                trigger={["click"]}
                getPopupContainer={(trigger: any) => trigger.parentNode}
              >
                <span className="customize-link">Move Between Carts</span>
              </Dropdown>
            </Col>
          ) : null}
          {view === 0 ? (
            <Dropdown
              trigger={["click"]}
              className="cart-column-filter"
              getPopupContainer={(trigger: any) => trigger.parentNode}
              overlay={userColumns?.length > 0 ? menuR : menu}
              onVisibleChange={handleVisibleChange}
              visible={visibleMenuSettings}
            >
              <span className="customize-link">Customize Columns</span>
            </Dropdown>
          ) : null}


          {!showQuantitybtn ? (
            <Col span={10} className="purchaseBtn">
              {appUser?.isLibraryBuyer === true &&
                (selectedCart?.CartDetails
                  ? selectedCart?.CartDetails.length
                  : null) ? (
                <>
                  <Button
                    type="primary mark-ready-button"
                    onClick={() => {
                      onPurchaseCart(true);
                    }}
                  >
                    Purchase Cart
                  </Button>
                  <Button
                    type="primary mark-ready-button"
                    onClick={() => {
                      onPurchase();
                    }}
                  >
                    Purchase Selected
                  </Button>
                </>
              ) : (
                <></>
              )}
            </Col>
          ) : null}
          {showQuantitybtn ? (
            <>
              <Col span={6} className="update_quantity cart_update_quantity">
                <Button
                  onClick={handleQuantitySave}
                  type="primary mark-ready-button update_quan_margin"
                >
                  Update Quantity
                </Button>
              </Col>
              <Col span={5} className="update_quantity cart_cancel_btn update_quan_margin">
                <Button
                  onClick={() => {
                    onQuantyCancel();
                    setGlobalQty(1);
                  }}
                  type="smallRed mark-ready-button"
                >
                  Cancel
                </Button>
              </Col>
            </>
          ) : null}

        </Col>
        <Divider className="carts-divider-bottom" />
      </Row>
      {gridView === 1 && dataSource?.length !== 0? (
        <Row align="bottom">
          <Col xs={4} sm={5} md={6} lg={7} xl={8} xxl={9}>
            {dataSource?.length !== 0 && view !== 0 ? (
              <>
                <div className="selectAllColWrapper detailViewCheckbox">
                  <Checkbox
                    className="unSelectAllCheckbox"
                    checked={allSelected}
                    onChange={onUncheckBox}
                    indeterminate={indeterminate}
                  ></Checkbox>
                  <span className="CartglobalQtyInput">
                    {
                      selectedItemsIds?.length > 0 ?
                        <InputNumber
                          defaultValue={1}
                          min={notSetMin ? globalQty : -999}
                          max={notSetMax ? 0 : 1000}
                          onChange={onValueChange}
                          value={globalQty}
                          onStep={handelUpDown}
                        /> : null
                    }
                  </span>
                </div>
              </>
            ) : null}
          </Col>
          <Col className="pagination-top-align">
            {cart?.NumberOfTitles > pageSize ? (
              <div className="pagination-container">
                <div className="cart-detail-pagination">
                  <PaginationTag
                    total={selectedCart?.NumberOfTitles}
                    current={currentPage}
                    defaultPageSize={pageSize}
                    onChange={onPageChange}
                  />
                </div>
              </div>
            ) : null}
          </Col>
          {selectedCart?.NumberOfTitles > pageSize ||
            dataSource?.length !== 0 ? (
            <Divider className="books-divider" />
          ) : null}
        </Row>
      ) : null}
      {view === 0 ? (
        <>
          <span className="CartglobalQtyInputTable showCartglobalQtyInputTable">
            {
              selectedItemsIds?.length > 0 ?
                <InputNumber
                  defaultValue={1}
                  min={notSetMin ? globalQty : -999}
                  max={notSetMax ? 0 : 1000}
                  onChange={onValueChange}
                  value={globalQty}
                  onStep={handelUpDown}
                /> : null
            }
          </span>
          <Table
            className={`cart-table checkboxClickableAreaWiden cartScreenTable hide_table_scroll ${selectedCart?.NumberOfTitles > pageSize ? "carts-pagination" : ""
              }`}
            locale={{ emptyText: <Empty description="No results found." /> }}
            size="small"
            columns={columnS ? columnS : columns}
            components={components}
            rowSelection={rowSelection}
            dataSource={dataSource}
            pagination={false}
            rowKey={"key"}
            //@ts-ignore
            scroll={{ x: screenSize }}
          // scroll={{ y: `calc(100vh - ${screenSizeY} )`, x: screenSize }}
          // scroll={{ y:`${windowSize?.dynamicWidth >= 1219? 'calc(100vh - 380px)':'calc(89vh - 380px)'}`,x:screenSize}}
          // rowClassName={(record:any, index:any) => this?.props?.buyerItemsId?.includes(parseInt(record?.EISBN))?'activeYellowRow':''}
          />
        </>
      ) : (
        <div>
          <Checkbox.Group
            style={{ width: "100%" }}
            value={allChecked}
            onChange={(checkedValues: any) => {
              onCustomSelect(checkedValues);
            }}
          >
            <List
              dataSource={dataSource}
              renderItem={(item: any) => (
                <>
                  <BookRow
                    book={item}
                    isCart={true}
                    allSelected={allSelected}
                    isAddTocartEnable={true}
                    editSelected={false}
                    filteredCarts={[]}
                    appUser={appUser}
                    isCollection={false}
                    updateAmount={handleQanSave}
                    // showNotification={showNotification}
                    clickArea={clickArea}
                    // scrollValue={scrollValue}
                    globalQty={globalQty}
                    selectedItemsIds={selectedItemsIds}
                    selectedItems={selectedItemsAfterG}
                    setSelectedItems={setSelectedItem}
                    maxQty={maxQty}
                    globlalQtyBtnPressed={globlalQtyBtnPressed}
                    pressdArrow={pressdArrow}
                    notSetMin={notSetMin}
                    notSetMax={notSetMax}
                  />
                </>
              )}
            />
          </Checkbox.Group>
        </div>
      )}
    </div>
  );
};
