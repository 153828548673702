import React from 'react'
import { Layout, Row, Col} from 'antd'
import { cloudLibrary, newCloudLibraryIcon } from '../../../images'
import './authLayout.less'
import { useEffect } from 'react'
import { useRef } from 'react'
import DropDown from '../common/dropDown'

const { Header, Content } = Layout
let offset: string = '0px'
let customStyle: any = {}

const AuthLayout = (props: any) => {
  const ref = useRef(null)

  useEffect(() => {
    const windowOnLoad = (e: any) => {
      if (ref?.current) {
        let currentRef: any = ref.current
        offset = currentRef.clientHeight + 'px'
        customStyle.minHeight = `calc(100vh - ${offset})`
      }
    }

    const windowOnResize = (e: any) => {
      if (ref?.current) {
        let currentRef: any = ref.current
        offset = currentRef.clientHeight + 'px'
        customStyle.minHeight = `calc(100vh - ${offset})`
      }
    }

    window.addEventListener('load', windowOnLoad)
    window.addEventListener('resize', windowOnResize)

    return () => {
      window.removeEventListener('load', windowOnLoad)
      window.removeEventListener('resize', windowOnResize)
    }
  }, [])

  const language = [{ text: 'English', value: 0 }]

  return (
    <Layout className="scrollStyle scrollAuth">
      <div ref={ref}>
        <Header>
          <Row justify='space-between' className="login-header">
            <Col>
              <div>
                <img src={newCloudLibraryIcon}  alt=""/>
              </div>
            </Col>
            <Col>
              <DropDown
                className='dropdownFix'
                defaultValue='English'
                optionValue={language}
                childwidth={true}
                optionClass="loginDropdown"
              />
            </Col>
          </Row>
        </Header>
      </div>
      <Content>
        <Row justify='space-around'>
          <Col span={8}>{props.children}</Col>
        </Row>
      </Content>
    </Layout>
  )
}

export default AuthLayout
