export enum PortalType {
  NONE = 0,
  CLIENT_PORTAL = 1,
}

export enum PortalTypeKey {
  CLIENT_PORTAL = "pt",
}

export enum HierarchyTypeCode {
  Organisation = "ORG",
  Location = "LOC",
}

export enum UnsavedChangesWarnModal {
  TITLE = "You’ve got unsaved changes",
  CONTENT = "Would you like to apply them?",
  DISCARD_BTN_TEXT = "Discard",
  CONFIRM_BTN_TEXT = "Apply Changes",
  CANCEL_BTN_TEXT = "Discard",
}

export enum BlockUserWarnModal {
  TITLE = "Block User",
  CONTENT = "This will block the user. Are you sure to continue?",
  CANCEL_BTN_TEXT = "Cancel",
  CONFIRM_BTN_TEXT = "Confirm",
}

// UnBlock user
export enum UnBlockUserWarnModal {
  TITLE = "Unblock User",
  CONTENT = "This will unblock the user. Are you sure to continue?",
  CANCEL_BTN_TEXT = "Cancel",
  CONFIRM_BTN_TEXT = "Confirm",
}

export enum ResetPwdWarnModal {
  TITLE = "Reset Password",
  CONTENT = "This will reset user's password. Are you sure to continue?",
  CANCEL_BTN_TEXT = "Cancel",
  CONFIRM_BTN_TEXT = "Reset Password",
  ADMIN_CONTENT = "An email with a reset link will be sent to the user. Would you like to proceed?",
  USER_CONTENT = "We’ll send you an email with a link to reset your password. Would you like to proceed?"
}

export enum RemoveUserWarnModal {
  TITLE = "Remove User",
  CONTENT = "This will remove the user. Are you sure to continue?",
  CANCEL_BTN_TEXT = "Cancel",
  CONFIRM_BTN_TEXT = "Confirm",
}

export enum UserActionType {
  REMOVE_USER = 0,
  BLOCK_USER = 1,
  CHANGE_PWD = 2,
  UNBLOCK_USER = 3
}

export const SUCCESS_ID = 99999;

export enum DeleteLocationWarnModal {
  TITLE = "Delete Location",
  CONTENT = "Are you sure you want to delete?",
  CANCEL_BTN_TEXT = "Cancel",
  CONFIRM_BTN_TEXT = "Confirm",
}

export enum DeleteRoleWarnModal {
  TITLE = "Delete Role",
  CONTENT = "Are you sure you want to delete this role?",
  CANCEL_BTN_TEXT = "Cancel",
  CONFIRM_BTN_TEXT = "Confirm",
}

export const AvatarColorList = [
  "#FF5757",
  "#82D1A2",
  "#55C1F6",
  "#4D4D4D",
  "blue",
  "pink",
  "green",
];

export const ProgressMesaage = "Work in Progress";

export const Role = {
  BIB_PLATFORM_ADMIN: "Bibliotheca Platform Admin",
};

export enum PermissionTypes {
  CREATE = 'create',
  EDIT = 'edit',
  DELETE = 'delete',
  VIEW = 'view',
  INDEX = 'index'
};

export enum RoleType {
  BIBLIOTHECA='BIBLIOTHECA',
  LIBRARY='LIBRARY',
  CUSTOM='CUSTOM'
};

export enum KeyboardKeyCharCode {
  ENTER_KEY=13,
  LEFT_KEY=37,
  RIGHt_KEY=39
}

export enum Page {
    DEFAULT_COUNT=100,
    DEFAULT_START=1,
    CHUNK_COUNT=100,
    CHUNK_START=0
}

export const filterOptions = {
  formats :[
    {
      text: "All",
      value: "all"
    },
    {
      text: "EPUB",
      value: "EPUB"
    },
    {
      text: "PDF",
      value: "PDF"
    },
    {
      text: "MP3",
      value: "MP3"
    }
  ],
  sorters : [
    {
      text: "Title",
      value: "Title"
    },
    {
      text:"Author",
      value: "Authors"
    },
    {
      text: "Publisher",
      value: "imprint"
    },
    {
      text: "Publication Date",
      value: "PublicationDate"
    },
    {
      text: "ISBN",
      value: "EISBN13"
    },
    {
      text:"Audience",
      value: "AudienceData"
    },
    {
      text: "Price",
      value: "RetailPrice"
    },
    {
      text: "Format",
      value: "FileFormatName"
    },
    {
      text: "Holds",
      value: "NumberOfHolds"
    },
    {
      text: "Hold Ratio",
      value: "holdRatio"
    },
    {
      text: "All Loans",
      value: "NumberOfLoans"
    },
    {
      text: "In Cart",
      value: "NumberInCarts"
    },
    {
      text:"Owned",
      value: "NumberOfCopies"
    }
  ],

  sortersCollect : [
    {
      text: "Title",
      value: "Title"
    },
    {
      text:"Author",
      value: "Authors"
    },
    {
      text: "Publisher",
      value: "imprint"
    },
    {
      text: "Publication Date",
      value: "PublicationDate"
    },
    {
      text: "ISBN",
      value: "EISBN13"
    },
    {
      text:"Audience",
      value: "AudienceData"
    },
    {
      text: "Price",
      value: "RetailPrice"
    },
    {
      text: "Format",
      value: "FileFormatName"
    },
    {
      text: "Holds",
      value: "NumberOfHolds"
    },
    {
      text: "Hold Ratio",
      value: "holdRatio"
    },
    {
      text: "All Loans",
      value: "NumberOfLoans"
    },
    {
      text: "In Cart",
      value: "NumberInCarts"
    },
    {
      text:"Owned",
      value: "NumberOfCopies"
    },
    {
      text:"OnOrder",
      value: "NumberOnOrder"
    },
    {
      text:"Purchased Date",
      value: "PurchasedDate"
    }
  ],

  Autosorters : [
    {
      text: "Title",
      value: "Title"
    },
    {
      text:"Author",
      value: "Authors"
    },
    {
      text: "Publisher",
      value: "imprint"
    },
    {
      text: "Publication Date",
      value: "PublicationDate"
    },
    {
      text: "ISBN",
      value: "EISBN13"
    },
    {
      text:"Audience",
      value: "AudienceData"
    },
    {
      text: "Price",
      value: "RetailPrice"
    },
    {
      text: "Format",
      value: "FileFormatName"
    },
    {
      text: "Holds",
      value: "NumberOfHolds"
    },
    {
      text: "Hold Ratio",
      value: "holdRatio"
    },
    {
      text: "All Loans",
      value: "NumberOfLoans"
    },
    {
      text: "In Cart",
      value: "NumberInCarts"
    },
    {
      text:"Owned",
      value: "NumberOfCopies"
    },
    {
      text:"Auto Cart Rule",
      value: "AutobuyReason"
    },
  ],

  ownership : [
    {
      text: "All",
      value: false
    },
    {
      text: "Unowned",
      value: true
    }
  ],
  preSale : [
    {
    text: "Include Pre-Sale Titles",
    value: 0
    },
    {
      text: "Exclude Pre-Sale Titles",
      value: -1
    },
    {
      text: "Only Include Pre-Sale Titles",
      value: -2
    },
  ],
  ppuTitles : [
    {
    text: "Exclude PPU Titles",
    value: 0
    },
    {
      text: "Include PPU Titles",
      value: -1
    },
    {
      text: "Search Only PPU titles",
      value: -2
    },
  ],
  PPUCriteria : [
    {
    text: "Exclude PPU Titles",
    value: "0"
    },
    {
      text: "Include PPU Titles",
      value: "1"
    },
    {
      text: "Search Only PPU titles",
      value: "2"
    },
  ],
  duration : [
    {
    text: "7 days",
    value: 7
    },
    {
      text: "30 days",
      value: 30
    },
    {
      text: "90 days",
      value: 90
    },
    {
      text: "1 Year",
      value: 365
    },
  ],
  dateDuration : [
    {
    text: "Published within",
    value: -2,
    key: -2
    },
    {
    text: "Published within 7 days",
    value: 7,
    key:7
    },
    {
      text: "Published within 30 days",
      value: 30,
      key:30
    },
    {
      text: "Published within 60 days",
      value: 60,
      key:60
    },
    {
      text: "Published within 90 days",
      value: 90,
      key:90
    },
    {
      text: "Published within 6 months",
      value: 180,
      key:180
    },
    {
      text: "Published within 1 Year",
      value: 365,
      key:365
    },
    {
      text: "Custom published date range",
      value: -1,
      key:-1
    },
  ],
  CloudDateDuration : [
    {
    text: "Date Added to CloudLibrary",
    value: -2,
    key: -2
    },
    {
    text: "Added within 7 days",
    value: 7,
    key: 7
    },
    {
      text: "Added within 30 days",
      value: 30,
      key:30
    },
    {
      text: "Added within 60 days",
      value: 60,
      key:60
    },
    {
      text: "Added within 90 days",
      value: 90,
      key:90
    },
    {
      text: "Added within 6 months",
      value: 180,
      key:180
    },
    {
      text: "Added within 1 Year",
      value: 365,
      key:365
    },
    {
      text: "Custom added date range",
      value: -1,
      key:-1
    },
  ],
  cartStructure : [
    {
    text: "Create one cart for all autocart rules",
    value: "LibraryLevel"
    },
    {
      text: "Create multiple carts per one rule",
      value: "RuleLevel"
    },
  ],
  purchaseDate : [
    {
    text: "Within 30 days",
    value: 30
    },
    {
      text: "Within 60 days",
      value: 60
    },
    {
      text: "Within 90 days",
      value: 90
    },
    {
      text: "Within 120 days",
      value: 120
    },
    {
      text: "Within 1 Year",
      value: 365
    },
  ],
  audienceChkBox :[
    {
      label: 'Adult',
      value: 1,
    },
    {
      label: 'YoungAdult',
      value: 2,
    },
    {
      label: 'Juvenile',
      value: 3,
    },
    {
      label: 'Children',
      value: 4,
    },
  ],
  datePurchased : [
    {
      text:"Date Purchased",
      value: 1,
      key: 1
    },
    {
      text: "Custom Date Range",
      value: -1,
      key:-1
    },
  ],
  dateRange : [
    {
      text: "yesterday",
      value: 1,
      key:1
    },
    {
    text: "7 days ago",
    value: 7,
    key:7
    },
    {
      text: "30 days ago",
      value: 30,
      key:30
    },
    {
      text: "60 days ago",
      value: 60,
      key:60
    },
    {
      text: "90 days ago",
      value: 90,
      key:90
    },
    {
      text: "Custom date range",
      value: -1,
      key:-1
    },
  ],
  messagesDateRange : [
    {
    text: "7 days ago",
    value: 7,
    key:7
    },
    {
      text: "30 days ago",
      value: 30,
      key:30
    },
    {
      text: "60 days ago",
      value: 60,
      key:60
    },
    {
      text: "90 days ago",
      value: 90,
      key:90
    },
    {
      text: "Custom date range",
      value: -1,
      key:-1
    },
  ],
  allCarts : [
    {
      text: "Sort By",
      value: null,
      key: 1
    },
    {
      text: "Cart Name",
      value: "CartName",
      key : 2
    },
    {
      text:"Cart Owner",
      value: "CartOwner",
      key : 3
    },
  ],
  libraryUsers : [
    {
      text: "Active Users",
      value: "1",
      key: 1
    },
    {
      text: "Inactive Users",
      value: "2",
      key: 2
    },
    {
      text: "All Users",
      value: "0",
      key: 3
    }
  ],
  libraryRoles : [
    {
      text: "All Roles",
      value: "ScreenName",
      key: 1
    },
    {
      text: "Buyer",
      value: "IsLibraryBuyer",
      key: 2
    },
    {
      text: "Library Manager",
      value: "IsLibraryAdmin",
      key: 3
    },
    {
      text: "Patron Communicator",
      value: "IsPatronCommunicator",
      key: 4
    },
    {
      text: "Patron Manager",
      value: "IsPatronManager",
      key: 5
    },
    {
      text: "Shelf Manager",
      value: "IsShelfAdmin",
      key: 6
    },
    {
      text: "Shopper",
      value: "IsLibraryUser",
      key: 7
    },
  ],
  shelfFilters : [
    {
      text: "Title",
      value: "Title",
      key: 0
    },
    {
      text: "Author, Title",
      value: "AuthorTitle",
      key: 1
    },
    {
      text: "Date Added to Shelf",
      value: "DateInShelf",
      key: 2
    },
    {
      text: "Date Published",
      value: "PublishedDate",
      key: 3
    },
  ],

  createShelfDate : [
    {
    text: "Published within",
    value: 0,
    key: 0
    },
    {
    text: "Published within 7 days",
    value: 7,
    key:7
    },
    {
      text: "Published within 30 days",
      value: 30,
      key:30
    },
    {
      text: "Published within 60 days",
      value: 60,
      key:60
    },
    {
      text: "Published within 90 days",
      value: 90,
      key:90
    },
    {
      text: "Published within 6 months",
      value: 180,
      key:180
    },
    {
      text: "Published within 1 Year",
      value: 365,
      key:365
    },
  ],

  createShelfDateB : [
    {
    text: "Purchased within",
    value: 0,
    key: 0
    },
    {
    text: "Purchased within 7 days",
    value: 7,
    key:7
    },
    {
      text: "Purchased within 30 days",
      value: 30,
      key:30
    },
    {
      text: "Purchased within 60 days",
      value: 60,
      key:60
    },
    {
      text: "Purchased within 90 days",
      value: 90,
      key:90
    },
    {
      text: "Purchased within 6 months",
      value: 180,
      key:180
    },
    {
      text: "Purchased within 1 Year",
      value: 365,
      key:365
    },
  ],


  PopularLibrary : [
    {
      text: "Most Popular in Library",
      value: 0
      },
    {
    text: "in last 7 days",
    value: 7
    },
    {
      text: "in last 30 days",
      value: 30
    },
  ],
  }

  export const Backgrounds = [
    "linear-gradient(180deg, rgba(135,159,175,1) 5%, rgba(138,159,176,1) 14%, rgba(255,185,68,1) 99%)",
    "linear-gradient(180deg, rgba(255,32,104,1) 3%, rgba(255,30,106,1) 6%, rgba(116,9,190,1) 85%, rgba(61,7,200,1) 97%)",
    "linear-gradient(180deg, rgba(32,46,105,1) 8%, rgba(164,141,191,1) 97%)",
    "linear-gradient(55deg, rgba(0,0,0,1) 0%, rgba(97,107,218,1) 22%, rgba(39,41,45,1) 97%, rgba(0,0,0,1) 99%)",
    "linear-gradient(180deg, rgba(32,46,105,1) 8%, rgba(164,141,191,1) 97%)",
    "linear-gradient(180deg, rgba(251,179,63,1) 26%, rgba(70,252,152,1) 90%)",
    "linear-gradient(90deg, rgba(89,0,6,1) 0%, rgba(216,0,11,1) 68%, rgba(206,1,6,1) 100%)",
    "linear-gradient(90deg, rgba(253,85,10,1) 0%, rgba(255,4,1,1) 100%)",
    " linear-gradient(90deg, rgba(245,92,32,1) 0%, rgba(245,169,15,1) 100%)",
    "linear-gradient(139deg, rgba(221,101,110,1) 0%, rgba(150,117,170,1) 100%)",
    "linear-gradient(130deg, rgba(237,98,95,1) 0%, rgba(249,25,25,1) 100%)",
    "linear-gradient(61deg, rgba(0,163,220,1) 0%, rgba(174,201,88,1) 71%, rgba(193,196,53,1) 100%)"
  ]

  
  export const WebinarLinks = [
    {
      label: "Session 1: Nov 8, 2022 09:00 AM Central Time (US and Canada)",
      url: "https://bibliotheca.zoom.us/j/97720890610?pwd=QXBKZGk0VWMxaDVZN2xqdTFicFRZdz09",
      time: "Nov 8, 2022 09:00 AM",
      dateFormat: "2022/11/8",
      timeFormat: "09:00 AM",
      timeZone: "Central Time (US and Canada)"
    },
    {
      label: "Session 2: Nov 17, 2022 03:00 PM Central Time (US and Canada)",
      url: "https://bibliotheca.zoom.us/j/96151383568?pwd=OXdHRWNMU1ZnMFVQYktDVXYwZmZmUT09",
      time: "Nov 17, 2022 03:00 PM",
      dateFormat: "2022/11/17",
      timeFormat: "03:00 PM",
      timeZone: "Central Time (US and Canada)"
    },
    {
      label: "Session 3: Nov 22, 2022 09:00 AM Central Time (US and Canada)",
      url: "https://bibliotheca.zoom.us/j/97273648344?pwd=OUxreG5HenNjcDVxdGFLcVlQSDF5UT09",
      time: "Nov 22, 2022 09:00 AM",
      dateFormat: "2022/11/22",
      timeFormat: "09:00 AM",
      timeZone: "Central Time (US and Canada)"
    },
    {
      label: "Session 4: Dec 1, 2022 03:00 PM Central Time (US and Canada)",
      url: "https://bibliotheca.zoom.us/j/92064754353?pwd=b3ZoWkxJVm9ERlNKMmNPT1o5YWtiQT09",
      time: "Dec 1, 2022 03:00 PM",
      dateFormat: "2022/12/1",
      timeFormat: "03:00 PM",
      timeZone: "Central Time (US and Canada)"
    },
    {
      label: "Session 5: Dec 6, 2022 09:00 AM Central Time (US and Canada)",
      url: "https://bibliotheca.zoom.us/j/98427373483?pwd=UWthdS9NeUJQeW5yUGFZMjZVdDV0UT09",
      time: "Dec 6, 2022 09:00 AM",
      dateFormat: "2022/12/6",
      timeFormat: "09:00 AM",
      timeZone: "Central Time (US and Canada)"
    },
    {
      label: "Session 6: Dec 15, 2022 03:00 PM Central Time (US and Canada)",
      url: "https://bibliotheca.zoom.us/j/97376719650?pwd=M2oyWUtCYnVHUlUrL1g5cVVwclFUQT09",
      time: "Dec 15, 2022 03:00 PM",
      dateFormat: "2022/12/15",
      timeFormat: "03:00 PM",
      timeZone: "Central Time (US and Canada)"
    },
    {
      label: "Session Australia/New Zealand session: December 7th, 2022 09:00AM (AWST)",
      url: "https://bibliotheca.zoom.us/j/96734435362?pwd=eE1nOFZDWUo1OHFhWG5kY3NWTFNsUT09",
      time: "December 7th, 2022 09:00 AM",
      dateFormat: "2022/12/7",
      timeFormat: "09:00 AM",
      timeZone: "(AWST)"
    }
  ]