/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { Row, Col } from "antd";
import { Chart as ChartJs, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { TooltipComponent } from "../../../stateless/common/tooltip";
import { userStateSelector } from "../../../../../redux/reducers/user/userReducer";
import { useSelector } from "react-redux";
ChartJs.register(ArcElement, Tooltip, Legend);

export const GraphRow = (props: any) => {
  const {
    rowData,
    tableType,
    setYourPatronHolds,
    setCloudLinkPatronHolds,
    infoMessage,
  } = props;

  const {
    yourCollectionCheckouts,
    cloudLinkCheckouts,
    ppuCheckouts,
    yourCopiesInCollection,
    cloudLinkCopiesInCollection,
    ppuCopiesInCollection,
  } = infoMessage;

  const { appUser }: any = useSelector(userStateSelector);

  const hasAudioBooks =
    appUser?.libraryData[0].FeaturePermissions.includes("AudioBooks");
  const hasCloudLink = appUser?.libraryData[0].ConsortiumId;

  const [totalEbook, setTotalEbook] = useState<any>([]);
  const [totalAbook, setTotalAbook] = useState<any>([]);
  const [totalCopies, setTotalCopies] = useState<any>([]);
  const [totalOwn, setTotalOwn] = useState<any>([]);
  const [totalCloud, setTotalCloud] = useState<any>([]);
  const [totalPpu, setTotalPpu] = useState<any>([]);

  const alwaysShowTooltip = {
    id: "tooltip",
    afterDraw(chart: any) {
      const { ctx } = chart;
      function roundedRect(
        ctx: any,
        x: any,
        y: any,
        width: any,
        height: any,
        borderRadius: any
      ) {
        ctx.beginPath();
        ctx.moveTo(x + borderRadius, y);
        ctx.arcTo(x + width, y, x + width, y + height, borderRadius);
        ctx.arcTo(x + width, y + height, x, y + height, borderRadius);
        ctx.arcTo(x, y + height, x, y, borderRadius);
        ctx.arcTo(x, y, x + width, y, borderRadius);
        ctx.closePath();
      }
      ctx.save();
      chart.data.datasets.forEach((dataset: any, i: number) => {
        chart
          .getDatasetMeta(i)
          .data.forEach((datapoint: any, index: number) => {
            const { x, y } = datapoint.tooltipPosition();

            const text = chart.data.datasets[i].data[index].toLocaleString();
            if (text === '0') {
              return;
            }
            const textWidth = ctx.measureText(text).width;
            ctx.save();

            ctx.fillStyle = "white";
            roundedRect(
              ctx,
              textWidth < 17
                ? x - (textWidth + 20) / 2
                : x - (textWidth + 20) / 2 + 8,
              y - 16,
              textWidth < 17 ? textWidth + 20 : textWidth + 4,
              17,
              5
            );

            ctx.fill();

            ctx.strokeStyle = "grey";
            ctx.lineWidth = 0.5;

            ctx.stroke();
            ctx.font = "10px Arial";
            ctx.fillStyle = "black";
            ctx.fillText(text, x - textWidth / 2, y - 3);
            ctx.restore();
            ctx.beginPath();
            ctx.moveTo(x, y + 7);
            ctx.lineTo(x - 6, y);
            ctx.lineTo(x + 6, y);
            ctx.closePath();
            ctx.fillStyle = "white";

            ctx.fill();
            ctx.strokeStyle = "white";
            ctx.lineWidth = 0.5;
            ctx.stroke();
          });
      });
      ctx.restore();
    },
  };

  const arrowPos = useRef<any>({
    arrowX: 0,
    arrowY: 0,
    totalArrowWidth: 0,
  });

  const getHeading = () => {
    if (tableType === "Checkout") {
      return "Total Checkouts";
    }
    if (tableType === "Collection") {
      return "Total Copies";
    }
    if (tableType === "Hold") {
      return "Total Holds";
    }
  };

  const textCenter = {
    id: "textCenter",
    beforeDatasetDraw(chart: any) {
      const { ctx, data } = chart;
      let xCor = chart.getDatasetMeta(0).data[0].x;
      let yCor = chart.getDatasetMeta(0).data[0].y;
      let sum = 0;
      data.datasets[0].data.forEach((item: number) => (sum = sum + item));
      ctx.save();
      let heading = getHeading();
      const deviceWidth = window.innerWidth;

      // Set font sizes based on the device width
      let headingFontSize = deviceWidth < 992 ? 15 : 18;
      let sumFontSize = deviceWidth < 992 ? 24 : 30;
      let arrowFontSize = deviceWidth < 992 ? 14 : 16;

      ctx.font = `${headingFontSize}px Arial`;
      ctx.fillStyle = "grey";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillText(heading, xCor, yCor);

      ctx.font = `bolder ${sumFontSize}px IBM Plex Sans`;
      ctx.fillStyle = "black";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillText(sum.toLocaleString(), xCor, yCor + 30);

      ctx.font = `${arrowFontSize}px Arial`;
      ctx.fillStyle = "green";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";

      const arrow = "\u2191";
      const percentageText = "8.23%";
      const totalWidth = ctx.measureText(arrow + percentageText).width;

      arrowPos.current.totalArrowWidth = totalWidth;
      arrowPos.current.arrowX = xCor;
      arrowPos.current.arrowY = yCor + 25;
      // Uncomment next line to show percentage
      // ctx.fillText(arrow + percentageText, xCor, yCor + 35, totalWidth);
      // ctx.fillRect(arrowPos.current.arrowX - (totalWidth + 20) / 2, arrowPos.current.arrowY , totalWidth + 20, 20);
      ctx.restore();
    },
  };

  const getLabels = () => {
    if (tableType === "Checkout") {
      return ["collection checkouts", "CloudLink checkouts", "PPU checkouts"];
    } else {
      return ["Your copies", "CloudLink copies", "PPU copies"];
    }
  };

  const donoutData = {
    labels: getLabels(),
    datasets: [
      {
        data: [totalOwn, totalCloud, totalPpu],
        backgroundColor: ["#9effbe", "#ff8d95", "#4c92dd"],
        hoverBackgroundColor: ["#9effbe", "#ff8d95", "#4c92dd"],
      },
    ],
  };

  const tool: any = Tooltip;
  tool.positioners.myCustomPositioner = function () {
    return {
      x: 23,
      y: 95,
    };
  };

  const donoutConfig: any = {
    id: "yourCanvasId",
    type: "doughnut",
    data: donoutData,
    options: {
      cutout: "73%",
      legend: {
        display: false,
      },
      tooltips: {
        enabled: false,
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          backgroundColor: "black",
          position: "myCustomPositioner",
          usePointStyle: true,
          caretSize: 0,
        },
      },
    },
    plugins: [alwaysShowTooltip, textCenter],
  };

  const removeComma = (str: any) => str.replace(/,/g, "");

  useEffect(() => {
    var eBookTotal: any = document.querySelectorAll(
      `.graphTable${tableType} .celEBook`
    );
    var audioBookTotal: any = document.querySelectorAll(
      `.graphTable${tableType} .celAudioBook`
    );
    var ppuTotal: any = document.querySelectorAll(
      `.graphTable${tableType} .CelPPU`
    );
    var ownedTotal: any = document.querySelectorAll(
      `.graphTable${tableType} .CelOwned`
    );
    var cloudTotal: any = document.querySelectorAll(
      `.graphTable${tableType} .CelCloudLink`
    );

    let eBookTotalArray: any = [];
    let audioBookTotalArray: any = [];
    let ppuTotalArray: any = [];
    let ownedTotalArray: any = [];
    let cloudTotalArray: any = [];
    for (var a: any = 0; a < eBookTotal.length; a++) {
      let value = removeComma(eBookTotal[a].innerHTML);
      eBookTotalArray?.push(parseInt(value === "N/A" ? "0" : value));
    }
    for (var b: any = 0; b < audioBookTotal.length; b++) {
      let value = removeComma(audioBookTotal[b].innerHTML);
      audioBookTotalArray?.push(parseInt(value === "N/A" ? "0" : value));
    }
    for (var c: any = 0; c < ppuTotal.length; c++) {
      let value = removeComma(ppuTotal[c].innerHTML);
      ppuTotalArray?.push(parseInt(value === "N/A" ? "0" : value));
    }
    for (var d: any = 0; d < ownedTotal.length; d++) {
      let value = removeComma(ownedTotal[d].innerHTML);
      ownedTotalArray?.push(parseInt(value === "N/A" ? "0" : value));
    }
    for (var e: any = 0; e < cloudTotal.length; e++) {
      let value = removeComma(cloudTotal[e].innerHTML);
      cloudTotalArray?.push(parseInt(value === "N/A" ? "0" : value));
    }

    setTotalEbook(eBookTotalArray.reduce((a: any, b: any) => a + b, 0));
    setTotalAbook(audioBookTotalArray.reduce((a: any, b: any) => a + b, 0));
    setTotalCopies(
      eBookTotalArray.reduce((a: any, b: any) => a + b, 0) +
        audioBookTotalArray.reduce((a: any, b: any) => a + b, 0)
    );
    setTotalOwn(ownedTotalArray.reduce((a: any, b: any) => a + b, 0));
    setTotalCloud(cloudTotalArray.reduce((a: any, b: any) => a + b, 0));
    setTotalPpu(ppuTotalArray.reduce((a: any, b: any) => a + b, 0));
    if (tableType === "Hold") {
      setYourPatronHolds(ownedTotalArray.reduce((a: any, b: any) => a + b, 0));
      setCloudLinkPatronHolds(
        cloudTotalArray.reduce((a: any, b: any) => a + b, 0)
      );
    }
  }, [rowData, tableType]);

  // Canvas Click Fuction
  // const canvas: any = document.getElementById("yourCanvasId");
  // canvas?.addEventListener("click", handleClick);

  // function handleClick(event: any) {

  //   const canvas = event.target;
  //   const rect = canvas.getBoundingClientRect();
  //   const x = event.clientX - rect.left;
  //   const y = event.clientY - rect.top;

  //   const arrowWidth = arrowPos.current.totalArrowWidth;

  //   const rectX = arrowPos.current.arrowX - (arrowWidth + 20) / 2;
  //   const rectY = arrowPos.current.arrowY;
  //   const rectWidth = arrowWidth + 20;
  //   const rectHeight = 20;

  //   if (x >= rectX && x <= rectX + rectWidth && y >= rectY && y <= rectY + rectHeight) {
  //     console.log("Arrow clicked!");
  //   }
  //   else {
  //     console.log("clicked Out")
  //   }
  // }

  const NoDataUI = () => {
    let heading = getHeading();
    return (
      <div className="no-data-ui">
        <p className="heading">{heading}</p>
        <p className="total-count"> 0 </p>
      </div>
    );
  };
  return (
    <Row
      className="graphRow"
      style={{ display: tableType === "Hold" ? "none" : "block" }}
    >
      <Col span={24} className="graphRowDetails">
        {/* <p className="graphTitle">Circulation Summary</p> */}
        <Row>
          <Col xl={6} lg={8} md={9}>
            <div className="graphBox">
              <div className="donout-chart">
                {totalCopies ? (
                  <Doughnut {...donoutConfig} />
                ) : (
                  <div className="center-div">
                    <NoDataUI />
                  </div>
                )}
              </div>
            </div>
          </Col>
          <Col xl={18} lg={16} md={15}>
            <div className="graphContent">
              {/* <div className="bannerDays">Passed 30 Days</div> */}
              {tableType === "Checkout" ? (
                <div className="graph-heading">
                  <p className="graphContentTitle">Total Checkouts</p>
                  <p className="time-stamp-heading">Past 30 Days</p>
                </div>
              ) : null}
              {tableType === "Collection" ? (
                <div className="graph-heading">
                  <p className="graphContentTitle">
                    Total Copies in Collections
                  </p>
                  <p className="time-stamp-heading">Today</p>
                </div>
              ) : null}
              {tableType === "Hold" ? (
                <div className="graph-heading">
                  <p className="graphContentTitle">Total Holds</p>
                  <p className="time-stamp-heading">Past 30 Days</p>
                </div>
              ) : null}
              <div className="table">
                <table className={`graphTable${tableType}`}>
                  <tr>
                    <th></th>
                    <th>
                      <span className="colorCircle">
                        <div style={{ background: "#9effbe" }}></div>
                      </span>
                      {tableType === "Checkout" ? (
                        <span className="pr-15 ">
                          Your Collection # of&nbsp;
                          <span className="no-wrap">
                            Checkouts
                            <TooltipComponent
                              message={
                                tableType === "Checkout"
                                  ? yourCollectionCheckouts
                                  : yourCopiesInCollection
                              }
                            />
                          </span>
                        </span>
                      ) : (
                        <span className="pr-15 ">
                          Your&nbsp;
                          <span className="no-wrap">
                            Copies
                            <TooltipComponent
                              message={
                                tableType === "Checkout"
                                  ? yourCollectionCheckouts
                                  : yourCopiesInCollection
                              }
                              width="350px"
                            />
                          </span>
                        </span>
                      )}
                    </th>
                    <th>
                      <span className="colorCircle">
                        <div style={{ background: "#e76d6d" }}></div>
                      </span>
                      {tableType === "Checkout" ? (
                        <span className="pr-15 ">
                          Your patrons CloudLink&nbsp;
                          <span className="no-wrap">
                            Checkouts
                            <TooltipComponent
                              message={
                                tableType === "Checkout"
                                  ? cloudLinkCheckouts
                                  : cloudLinkCopiesInCollection
                              }
                            />
                          </span>
                        </span>
                      ) : (
                        <span className="pr-15">
                          CloudLink&nbsp;
                          <span className="no-wrap">
                            Copies
                            <TooltipComponent
                              message={
                                tableType === "Checkout"
                                  ? cloudLinkCheckouts
                                  : cloudLinkCopiesInCollection
                              }
                            />
                          </span>
                        </span>
                      )}
                    </th>
                    <th>
                      <span className="colorCircle">
                        <div style={{ background: "#4c92dd" }}></div>
                      </span>

                      {tableType === "Checkout" ? (
                        <span className="pr-15 ">
                          PPU&nbsp;
                          <span className="no-wrap">
                            Checkouts
                            <TooltipComponent
                              message={
                                tableType === "Checkout"
                                  ? ppuCheckouts
                                  : ppuCopiesInCollection
                              }
                            />
                          </span>
                        </span>
                      ) : (
                        <span className="pr-15 ">
                          PPU&nbsp;
                          <span className="no-wrap">
                            Copies
                            <TooltipComponent
                              message={
                                tableType === "Checkout"
                                  ? ppuCheckouts
                                  : ppuCopiesInCollection
                              }
                            />
                          </span>
                        </span>
                      )}
                    </th>
                    <th>
                      <span>
                        {tableType === "Checkout"
                          ? "Total Checkouts"
                          : "Total Copies"}
                      </span>
                    </th>
                  </tr>
                  <tbody>
                    {rowData && rowData?.length > 0
                      ? rowData?.map((items: any) => {
                          return (
                            <tr
                              className={`tableRow${
                                items?.ItemFormat?.includes("Ebook")
                                  ? "EBook"
                                  : "AudioBook"
                              }`}
                            >
                              <td>
                                {items?.ItemFormat?.includes("Ebook")
                                  ? "eBook"
                                  : "audioBook"}
                              </td>
                              <td
                                className={`CelOwned cel${
                                  items?.ItemFormat?.includes("Ebook")
                                    ? "EBook"
                                    : "AudioBook"
                                }`}
                              >
                                {/* {items?.Owned.toLocaleString() == 0 } */}
                                {items?.ItemFormat?.includes("Ebook")
                                  ? items?.Owned.toLocaleString()
                                  : items?.Owned === 0
                                  ? hasAudioBooks
                                    ? 0
                                    : "N/A"
                                  : items?.Owned.toLocaleString()}
                              </td>
                              <td
                                className={`CelCloudLink cel${
                                  items?.ItemFormat?.includes("Ebook")
                                    ? "EBook"
                                    : "AudioBook"
                                }`}
                              >
                                {items?.ItemFormat?.includes("Ebook")
                                  ? items?.CloudLink === 0
                                    ? hasCloudLink
                                      ? 0
                                      : "N/A"
                                    : items?.CloudLink.toLocaleString()
                                  : items?.CloudLink === 0
                                  ? !hasCloudLink || !hasAudioBooks
                                    ? "N/A"
                                    : "0"
                                  : items?.CloudLink.toLocaleString()}
                              </td>
                              <td
                                className={`CelPPU cel${
                                  items?.ItemFormat?.includes("Ebook")
                                    ? "EBook"
                                    : "AudioBook"
                                }`}
                              >
                                {/* {items?.PPU.toLocaleString()} */}
                                {items?.ItemFormat?.includes("Ebook")
                                  ? items?.PPU.toLocaleString()
                                  : items?.PPU === 0
                                  ? hasAudioBooks
                                    ? 0
                                    : "N/A"
                                  : items?.PPU.toLocaleString()}
                              </td>
                              <td>
                                {items?.ItemFormat?.includes("Ebook")
                                  ? totalEbook.toLocaleString()
                                  : totalAbook.toLocaleString()}
                              </td>
                            </tr>
                          );
                        })
                      : null}
                    <tr>
                      <td>Total</td>
                      <td>{totalOwn.toLocaleString()}</td>
                      <td>
                        {totalCloud === 0
                          ? hasCloudLink
                            ? "0"
                            : "N/A"
                          : totalCloud.toLocaleString()}
                      </td>
                      <td>{totalPpu.toLocaleString()}</td>
                      <td>{totalCopies ? totalCopies.toLocaleString() : 0}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
